import React from "react";
import "../styles/Dashboard.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useState, useEffect, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpdashboard from "../js/http-lms_dashboard"
import NoRecFound from "../components/NORecFound";
import Dropdown from 'react-bootstrap/Dropdown';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Modal } from 'react-bootstrap';
import httpuserprofile from "../js/http-lms_userprofile"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import LeftToRightModal from "../components/LeftToRightModal";


const BatchListTable = (props) => {


  const table = useRef(null);
  const [loading, setLoading] = useState(0)

  const [batchList, setBatchList] = useState([])

  const [allBatchList, setAllBatchList] = useState([])

  const[error, setError] = useState()
  const[path, setPath] = useState()

  const [show, setShow] = useState(false);
    const [program, setProgram] = useState()
    const [programId, setProgramId] = useState()
    const [programName, setProgramName] = useState()
    const [batchStatusName, setBatchStatusName] = useState('')
    // const [programId, setProgramId] = useState([])
    const[batchName, setBatchName] = useState()
    const [selectedOption, setSelectedOption] = useState(null);

    const [selectedStatusOption, setSelectedStatusOption] = useState(null)

    const [disable, setDisable] = useState(true)

    const [batchStatus, setBatchStatus] = useState()

    const [batchStartDate, setBatchStartDate] = useState(new Date());

    const [batchEndDate, setBatchEndDate] = useState(new Date());
    
    const [selectedTrainer, setSelectedTrainer] = useState(null);

    const[trainerDetails, setTrainerDetails] = useState(null)

    const [description, setDescription] =useState()

    const[trainerId, setTrainerId] = useState()

    const[batchStatusCode, setBatchStatusCode] = useState()

    const [batchDetails, setBatchDetails] = useState()

    const[batchId, setBatchId] = useState()

    const [creationDate, setCreationDate] = useState()

    const[trainerName, setTrainerName] = useState()

    const [pop, setPop] = useState(false);
    
    const [statusName, setStatusName] = useState('')

    const [updatedTrainerId, setUpdatedTrainerId] = useState("")

    const [searchText, setSearchText] = useState('');

    const[filterStartDate, setfilterStartDate] = useState(null)

    const[filterEndDate, setfilterEndDate] = useState(null)

    useEffect(() => {
      if(filterStartDate!==null && filterEndDate!==null) {
        setBatchList(allBatchList.filter(x => {
          return new Date(x.startDate.substring(0,10))<=filterEndDate && new Date(x.startDate.substring(0,10))>=filterStartDate
        }))
      }
    }, [filterStartDate, filterEndDate])
   
useEffect(() => {
  console.log(batchStatusName, 'vvvvvvvvvvv')
}, [batchStatusName])

useEffect(() => {
  console.log(batchStartDate < new Date(), 'bbbbbsssssssss')
}, [batchStartDate])


    const handleClose = () => {
    
      setShow(false);
      setProgramName("")
      setBatchName("")
      setDescription("")
      setSelectedTrainer("")
      setBatchStartDate("")
      setBatchEndDate("")
      setSelectedStatusOption("")
      setProgramId("")
      setTrainerName("")
      setProgramId("")
      setTrainerDetails("")
        
      
    }
  
    const handleShow = (data) => { 
      setShow(true);
      getBatchInfo(data)
    }



    
    function getBatchInfo(data){
      httpuserprofile
    .get(`userprofileLMS/getBatchInfoByBatchName?batchName=${data.batchName}`)
    .then((res) => {
        console.log(res.data,"hi")
        setBatchName(res.data.batches.batchName)
        setProgramName(res.data.programNameByTrainerVO.programName)
        setDescription(res.data.batches.batchDescription)
        generateTrainer(res.data.programNameByTrainerVO.programId)
        setBatchStartDate(moment(res.data.batches.batchStartDate).toDate())
        // setProgramId(res.data.programNameByTrainerVO.programId)
        // console.log(res.data.programNameByTrainerVO.programId,"Prog Id")
        setBatchEndDate(Date.parse(res.data.batches.batchEndDate))
        setBatchId(res.data.batches.batchId)
        setBatchStatusCode(res.data.batches.batchStatus)
        setTrainerName(res.data.lmsTrainer.trainerName)
        setTrainerId(res.data.lmsTrainer.lmsTrainerId)
        setCreationDate(new Date())
        let status = batchStatus.filter(data => {
          return  res.data.batches.batchStatus===data.indexCode       })
          console.log(status, 'bbbbbbbbbbbbbbbbbb')
        setBatchStatusName(status[0].indexDescription)
        console.log(res.data.batches.batchDescription,"ioioiio")
    })
    .catch((err)=>{
      console.log(err)
    })

    }
  
  
    useEffect(() => {
      if((programName && batchName && trainerName && batchStartDate && batchEndDate && batchStatusName )) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }, [programName, batchName, trainerName, batchStartDate, batchEndDate, batchStatusName ])
  
  useEffect(()=>{
  
      httpuserprofile
      .get("/userprofileLMS/getProgramListByTrainer")
      .then((res) => {
          console.log(res.data)
        setProgram(res.data)
      })
  
  
  
      httpuserprofile
        .get("/userprofileLMS/getStaticValuesListById?indexCode=7")
        .then((res) => {
          console.log("Batch Status", res)
          setBatchStatus(res.data)
        })
  
  },[]
  )


  
  function generateTrainer(data){
  
    httpuserprofile
      .get(`/userprofileLMS/getTrainerNameByProgramId?programId=${data}`)
      .then((res) => {
          console.log("Trainer Details:",res.data)
        setTrainerDetails(res.data)
      })
  
  
  }
  
  function generateBatchName(id){
  
      httpuserprofile
      .get(`/userprofileLMS/generateBatchName?programId=${id}`)
      .then((res) => {
          console.log(res.data)
          setBatchName(res.data.batchName)
      })
  
  }
  
  const colourStyles = {
      control: (styles) => ({ 
          ...styles, 
       }),
      option: (provided, state) => ({
        ...provided,
        fontWeight: state.isSelected ? "bold" : "normal",
        color: state.isFocused || state.isSelected ? 'white' : 'black',
        backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
        fontSize: "14px",
        paddingTop: "4px",
        paddingBottom: "4px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: state.data.color,
        fontSize: state.selectProps.myFontSize
      })
    };
  
  
  function programChange(e){
  
      console.log("changed")
      console.log(e, 'bbbbbbbbbbbbbbb')
      let programs = program.filter((data)=>{
        return data.programName === e.programName;
      })
      console.log('check1');
      console.log("Selected prog val:", programs)
        console.log('check2');
        setProgramName(e.programName)
        setSelectedOption(e)
  
  generateBatchName(programs[0].programId)
  generateTrainer(programs[0].programId)
        }
  
  
  
        function statusChange(e){
  
          console.log("changed2")
          console.log(e, 'yyyyyy')
          let status = batchStatus.filter((data)=>{
            return data.indexDescription === e.target.value;
          })
          console.log('check1');
          console.log("Selected status val:", status)
            console.log('check2');
            setBatchStatusName(status[0].indexDescription)
            console.log(status[0].indexDescription,"opopopopopopo")
            setBatchStatusCode(status[0].indexCode)
            console.log(status[0].indexCode)
            // setSelectedStatusOption(e)
    
            }


            function trainerChange(e){
              console.log(e.target.value, 'pppppppppppp')
              let trainer = trainerDetails.filter((data)=>{
                return data.trainerName === e.target.value;
              })
              console.log("Selected trainer val:", trainer)
              setTrainerId(trainer[0].lmsTrainerId)
              setTrainerName(trainer[0].trainerName)
            }



  useEffect(()=>{
    batchesList()
    setPath(window.location.pathname)
  },[props.refresh])


  const navigate = useNavigate();


  function handleClick(id, sDate, eDate, bName) {
    console.log("opopopoppop")
    sessionStorage.setItem('batchId', id)
    sessionStorage.setItem("batchname", bName)
    sessionStorage.setItem("sDate", moment(sDate).format('MM/DD/YYYY'))
    sessionStorage.setItem("eDate", moment(eDate).format('MM/DD/YYYY'))
          navigate("/batchdetails", {state:{path: path}});
        }



        let data = {
          startDate : null,
          endDate : null,
          courseId : null
        }


  function batchesList(){
    httpdashboard.post('/dashBoardLMS/getBatchList', data)
    .then((response) => {
      if(response.data=="No records found"){
        setLoading(1)
        console.log("API Values BatchList: : ", response.data)
        setError(response.data)
      }
      else{
      setLoading(1)
      console.log("API Values BatchList: : ", response.data)
      setBatchList(response.data)
      setAllBatchList(response.data)
      }
  }).catch((e) => {
    console.log(e.response, "not successful")
    setLoading(1)
    setError("Bad Request")
  })
}





const [dataPerPage, setDataPerPage] = useState(20)

  const [totalPages, setTotalPages] = useState()

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setTotalPages(Math.round(batchList.length/dataPerPage))
  }, [batchList, dataPerPage])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset, batchList, dataPerPage])

  useEffect(() => {
    setCurrentItems(batchList.slice(itemOffset, endOffset))
    console.log(batchList.slice(itemOffset, endOffset), 'll')
  }, [endOffset, batchList, dataPerPage])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % batchList.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };    

    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  
    useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });


  const handleDataPerPageChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === "all") {
      setDataPerPage(batchList.length);
    } else {
      setDataPerPage(event.target.value);
    }
  };


function handleUpdate(e){

  e.preventDefault();

  
  httpuserprofile
  .put("/userprofileLMS/updateBatch", {
    batchId:batchId,
    batchName: batchName,
    batchDescription: description,
    batchStartDate: moment(batchStartDate).format('YYYY-MM-DD'),
    batchEndDate: moment(batchEndDate).format('YYYY-MM-DD'),
    batchStatus: batchStatusCode,
    lmsTrainerId: trainerId,
    actualBatchEndDate: null,
    actualBatchEndReason: "n/a",
    lmsModuleId: null,
    lmsTopicMasterId: null,
    lmsTopicStartPlanned: null,
    lmsTopicEndPlanned: null,
    lmsTopicEndActual: null,
    lmsTopicEndActualReason: null,
    creationDate: moment(creationDate).format('YYYY-MM-DD'),
    creationUser: sessionStorage.getItem("userEmail"),
    lastUpdatedDate: null,
    lastUpdatedUser: null,
    lmsModuleMaster: null
  })
  .then((res) => {
      batchesList()
      console.log(res.data)
      setShow(false); 
      if(res.data){
        setPop(true)
      setTimeout(() => {
        setPop(false)
      }, 1500); 
      }
  }).catch((err)=>{
    console.log(err)
  })
  setProgramName("")
      setBatchName("")
      setDescription("")
      setSelectedTrainer("")
      setBatchStartDate("")
      setBatchEndDate("")
      setSelectedStatusOption("")
      setProgramId("")
      setTrainerName("")
      setProgramId("")
      setTrainerDetails("")

}


// const columns = [{
//   dataField: 'batchName',
//   text: 'Batch Name',
//   sort: true,formatter: (cell, row) => {
//     return (
//       <td className="batch-details" onClick={() => handleClick(row.batchId, row.startDate, row.endDate, row.batchName)}>
//         {row.batchName}
//       </td>
//     )
//   }
// }, {
//   dataField: 'programName',
//   text: "Program",
//   sort: true
// }, { dataField: 'trainerName', text: 'Trainer Name', sort: true },
// { dataField: 'startDate', text: "Start Date", sort: true, formatter: (cell, row) => {
//   return (
//     <td>
//       {moment(row.startDate).format('MM/DD/YYYY')}
//     </td>
//   )
// } },
// { dataField: 'endDate', text: 'End Date', sort: true , formatter: (cell, row) => {
//   return (
//     <td>
//       {moment(row.endDate).format('MM/DD/YYYY')}
//     </td>
//   )
// }},
// { dataField: 'totalStudents', text: 'Students', sort: true },
// { dataField: 'batchStatus', text: 'Status', sort: true},
// { dataField: 'more', text: 'Actions', formatter: (cell, row) => (
//   <img src={require("../images/edit2.png")} onClick={()=>handleShow(row)} alt="Image not found" className="more"/>)}];



  // const pagination = {
  //   totalSize: batchList ? batchList.length : 0,
  //   page: 1,
  //   sizePerPage: 5,
  //   onPageChange: (page, sizePerPage) => {
  //     console.log(page, sizePerPage);
  //   },
  //   createContext: (page, sizePerPage, sortField, sortOrder) => {
  //     if (!batchList) {
  //       return {};
  //     }
  //     return {
  //       data: batchList.slice(
  //         (page - 1) * sizePerPage,
  //         (page - 1) * sizePerPage + sizePerPage
  //       ),
  //       totalSize: batchList.length,
  //       page,
  //       sizePerPage,
  //       sortField,
  //       sortOrder,
  //     };
  //   },
  // };
  
  


  return (
    <>


{pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully batch has been updated</p>
      </div>
    </div>}



<Modal
        show={show}
        onHide={handleClose}
        dialogClassName={`modal-left-small h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Edit Batch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id='create-batch-form' className="h-100">
        <div className='scrollForm'>
                  <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Select Program</Form.Label>
                    <Form.Control
                        // className={formErros.firstName ? "borderred" : ""}
                        placeholder="Program Name"
                        value={programName}
                        autoComplete="off"
                        name='batchName'
                        disabled
                        
                        // onChange={handleChange}
                      />
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>
                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Batch Name</Form.Label>
                      <Form.Control
                        // className={formErros.firstName ? "borderred" : ""}
                        placeholder="Batch Name"
                        value={batchName}
                        autoComplete="off"
                        name='batchName'
                        disabled
                        
                        // onChange={handleChange}
                      />
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Batch Description</Form.Label>
                 <Form.Control as="textarea" value={description} onChange={e => setDescription(e.target.value)} maxLength={40} rows={3} placeholder="Batch Description (max 40 characters)" />
                 </Col>
                 </Row>
                
                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <div className='d-flex flex-row'><Form.Label>Select Trainer</Form.Label><h1 className='red'>*</h1></div>

                    <select onChange={trainerChange} className="form-select-2 ps-2 pe-2" value={trainerName} name="trainer" id="trainer">
              {/* <option  value="default" className='pe-2'>Select Role</option> */}
            {trainerDetails && trainerDetails.map((data) => {
               return (
     <option value={data.trainerName} className='pe-2'>{data.trainerName}</option>
 )})
               }
 </select>
                  {/* <Select
                        defaultValue={trainerName}
                        value={selectedTrainer}
                        onChange={setSelectedTrainer}
                        placeholder="Select Trainer"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.trainerName}`
                        }
                        options={trainerDetails}
                      /> */}
          
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>




                

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start position-relative">
                    <div className='d-flex flex-row'><Form.Label>Select Start Date</Form.Label><h1 className='red'>*</h1></div>
                    <DatePicker className='w-100 rounded heightDate' selected={batchStartDate} onChange={(date) => setBatchStartDate(date)}
                    minDate={new Date()}
                    // startDate={batchStartDate}
                    // endDate={batchEndDate}
                    // placeholderText="MM/DD/YYYY"
                    disabled={batchStartDate <= new Date()}
                    selectsStart
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
                    {/* <img src={require("../images/dateselect.png")} alt="Image not found" className="dateselect"/> */}
                 </Col>
                 </Row>


                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start  position-relative">
                    <div className='d-flex flex-row'><Form.Label>Select End Date</Form.Label><h1 className='red'>*</h1></div>
                    <DatePicker className='w-100 rounded heightDate' selected={batchEndDate} onChange={(date) => setBatchEndDate(date)}
                    minDate={batchStartDate ? new Date(batchStartDate.getFullYear(), batchStartDate.getMonth(), batchStartDate.getDate() + 1) : new Date()}
                    // startDate={batchStartDate}
                    // endDate={batchEndDate}
                    // placeholderText="MM/DD/YYYY"
                    disabled={batchStartDate <= new Date()}
                    selectsEnd
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
                    {/* <img src={require("../images/dateselect.png")}  alt="Image not found" className="dateselect"/> */}
                 </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <div className='d-flex flex-row'><Form.Label>Select Status</Form.Label><h1 className='red'>*</h1></div>


                    <select onChange={statusChange} className="form-select-2 ps-2 pe-2" value={batchStatusName} name="status" id="status">
  {batchStatus && (batchStartDate <= new Date() || batchStatusName === 'Inactive'    
  ? batchStatus
    : batchStatus.filter(data => data.indexDescription !== "Upcoming")
  ).map((data) => (
    <option value={data.indexDescription} className='pe-2'>
      {data.indexDescription}
    </option>
  ))}
</select>

{/* <Select
                       
                        value={selectedStatusOption}
                        onChange={statusChange}
                        placeholder="Select Status"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        defaultValue="ABCDE"
                        styles={colourStyles}
                        // defaultValue={defaultuserDetails.batch}
                        getOptionLabel={option =>
                          `${option.indexDescription}`
                        }
                        options={batchStatus}
                      /> */}
          
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>
                 </div>
                 <button onClick={handleUpdate}  disabled={disable} className={ disable ? "profile-buttons-update w-100 mt-3 mb-5 grey" : "profile-buttons-update w-100 mt-3 mb-5 UpdatedMainBGColor"}>Update</button>
                 </Form>

                
         
        
        </Modal.Body>
      </Modal>
            

        
        {loading===0 ? <div className=" h-100 d-flex mt-5  bgwhite justify-content-center align-items-center mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div>:

error ? <div className='col-12 mt-5 text-center'>
<NoRecFound error="No batches found" />
</div> :

        <div className="scrollme mt-5">
  
        
          <div className="d-flex justify-content-between bgwhite px-4 bottomborder">
          <div className="d-flex">
              <select className="my-4 px-1 me-5 rounded borderUpated" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value="all">All</option>
      </select>
      <DatePicker className='w-100 rounded heightDate mt-4' selectsStart
      onChange={(date) => {setfilterStartDate(date); console.log(date)}}
      selected={filterStartDate}
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2 mt-4">-</h3>
      <DatePicker className='w-100 rounded heightDate mt-4' selectsEnd
      onChange={(date) => {setfilterEndDate(date); console.log(date)}}
      selected={filterEndDate}
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
      </div>
      
     
                    
                    <LeftToRightModal batchesList={batchesList} />
   
      </div>
    
        <table className="table whitecolor">
          <thead>
            <tr>
              <th scope="col">Batch Name <img onClick={() => handleSort('batchName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">Program <img onClick={() => handleSort('programName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">Trainer Name  <img onClick={() => handleSort('trainerName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">Start Date <img onClick={() => handleSort('startDate')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">End Date <img onClick={() => handleSort('endDate')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              {/* <th
                scope="col"
              >
                Status
              </th> */}
              <th scope="col">Students <img onClick={() => handleSort('totalStudents')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">Status <img onClick={() => handleSort('batchStatus')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
          {
            sortedData.map((data, index) => {
                return (
            <tr>
              <td onClick={() => handleClick(data.batchId, data.startDate, data.endDate, data.batchName)} className="batch-details"  scope="row">{data.batchName}</td>
              <td>{data.programName}</td>
              <td>{data.trainerName}</td>
              <td>{moment(data.startDate).format('MM/DD/YYYY')}</td>
                <td>{moment(data.endDate).format('MM/DD/YYYY')}</td>
              {/* <td className="d-flex flex-column align-items-center">
                {data.status>0 ? data.status + '%' : '-'} <ProgressBar className="Progress-bar" now={data.status} />
              </td> */}
              <td>{data.totalStudents}</td>
              {/* <td>{data.placedCount}</td> */}
              <td>{data.batchStatus}</td>
              <td>
              <img src={require("../images/edit2.png")} onClick={()=>handleShow(data)} alt="Image not found" className="more"/>
                              {/* <Dropdown>
                                  <Dropdown.Toggle className="dropdown-more p-0" variant="success" id="dropdown-basic">
                                      <img src={require("../images/more.png")} alt="Image not found" className="more"/>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                      <Dropdown.Item onClick={()=>handleShow(data)} value="Edit">Edit</Dropdown.Item>
                                  </Dropdown.Menu>
                              </Dropdown> */}
                          </td>
            </tr>
                )
                })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
      </div>}

    </>
  );
};

export default BatchListTable;

