import React from "react";
import "../styles/VerifyOtp.css";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_signup';
import OTPInput, { ResendOTP } from "otp-input-react";
import Logo from '../images/careerTunerLogo.svg';
import itreion from '../images/itreion.svg'

const VerifyOtp = () => {

  const[msg,setMsg] = useState()
  const [resendmsg, setResendmsg] = useState()
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const navigate = useNavigate()
  const [newOTP, setNewOTP] = useState("");

  function handleClick(){
    navigate("/")
  }
  const submitOTP = async ()=>{
    setLoading(true)
    await httpsignup.put("/userloginLMS/verifyotp",{
      userEmail:localStorage.getItem("userEmail"),
      userOtp:newOTP,
      authCode:"miidBM390",
      roleId : sessionStorage.getItem("roleId")
    }
    ).then((result) => {
      console.log(result.data);
      setLoading(false)
      setMsg(result.data)
      if (result.data==='*Invalid OTP*. Please enter Valid OTP or click on Re-send OTP') {
        setMsg("Invalid OTP entered. Try again or click on Resend OTP");
      }
      else if(result.data==='Otp is verified') {
        setMsg(result.data);
        navigate("/updatepass");
      }
    }).catch((e) => {
      console.log(e.response)
      setLoading(false)
    })
  }

  const resendOTP =async ()=>{
    setResendmsg()
    setResendLoading(true)
    await httpsignup.post("/userloginLMS/sendmailotp",{
      userEmail:localStorage.getItem("userEmail"),
      roleId : sessionStorage.getItem("roleId")
    }).then((res) => {
      console.log("resend OTP : ", res.data)
      setResendmsg(res.data)
      setResendLoading(false)
    }).catch((e) => {
      console.log(e.response)
      setResendLoading(false)
    })
  }

  return (
    <>
    <div className='row d-flex align-items-center justify-content-between vh-100 login-back-container'>
    <div className='col-6 d-flex justify-content-center d-md-block d-none'>
    <img src={itreion} alt="Image not found" className='w-100 ms-5 ps-5' />
    </div>

      <div className='col-md-5 col-12 m-md-0  h-100 justify-content-center bgwhite rounded-1 d-flex'>
        {/* <div className='col-lg-6 signin-image'></div> */}
        <div className="col px-5 pt-5 pb-5 d-flex flex-column">
          <img onClick={handleClick} width='15px' className="backarrow text-left" src={require("../images/leftarrow.png")} alt="Image not found"/>
          {/* <img src={require('../images/Logo.png')} alt="logo" className='mx-auto d-block d-md-none' />
          <img src={Logo} alt="logo" className='d-none d-md-block d-lg-none mx-auto'/> */}
          <div className="col-12 d-content">
            <div className="logo text-center">
              <img src={require("../images/lock.png")} alt="back" />
            </div>
            <div className="col-md-10 mx-auto">
              <p className="forgetpass-heading">Forget Password</p>
              <p className="font12 black60 mt-2">Please enter the 6 didgit code sent to {localStorage.getItem("userEmail")}</p>
              <div className="mt-4 mb-3">
              <p className="e-heading">Enter OTP</p>
                <OTPInput className="testInput mt-2" value={newOTP} onChange={setNewOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
              </div>
              <div className='height20'>{msg && <div className='errMsg mb-2'>{msg}</div>}</div>
              {(
                resendLoading ? <Spinner animation="border" className="ml-3 mt-2" variant="warning" />  : <button className="btn2 mt-4" onClick={resendOTP}>Resend OTP</button>
              )}
              {resendmsg && <div className='successMsg mb-2'>{resendmsg}</div>}
              <div className="text-center col-12">
              {( loading ? <Spinner animation="border" className='text-center forgetpass-spinner mb-2' variant="warning" />
                : <button onClick={submitOTP} disabled={newOTP.length<6} className={newOTP.length<6 ? "signin-btn-disable col-12 py-2 mb-4" :"verify-otp-btn"}>Verify OTP</button>
              )}
              <p className='text-center font12 black60 mt-2'>@2022 all rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default VerifyOtp;

