import React from "react";
import Input from "../components/Input";
import "../styles/ForgetPassword.css";
import { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import httpsignup from '../js/http-lms_signup';
import Spinner from 'react-bootstrap/Spinner';
import Logo from '../images/careerTunerLogo.svg';
import itreion from '../images/itreion.svg'
import axios from "axios";

function ForgetPassword() {

  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState([])
  const[msg,setMsg] = useState()
  const navigate = useNavigate()
  const [roleName, setRoleName] = useState('default');
  const [roleId, setRoleId] = useState()
  const [disable, setDisable] = useState(true);
  
  useEffect(() => {
    if(roleName==='default') {
      console.log('byyeee');
      sessionStorage.clear();
        setDisable(true)
    }
    else {
      console.log('helllooo');
      setDisable(false)
    }
  }, [roleName])

  useEffect(() => {
    if(email && !msg && sessionStorage.getItem('roleId')) {
      setDisable(false);
    }
    else {
      setDisable(true);
    }
  }, [email, msg])

  function roleChange(e){
    let roles = role.filter((data)=>{
      return data.roleName === e.target.value;
    })
    if(e.target.value!='default') {
      sessionStorage.setItem("roleId",roles[0].roleId)
      setRoleId(roles[0].roleId)
    }
      setRoleName(e.target.value)
      }
    

  useEffect(()=>{
    getRoles()
    }, [])
  
  
    const getRoles = async ()=>{
  
      await axios.get("http://34.199.176.17:13051/userloginLMS/getUserRoles?appCode=LMS").then((result)=>{
        console.log(result.data)
        setRole(result.data)
      }).catch((err)=>{
        console.log(err)
      })
    }

  function handleClick(){
    navigate("/")
  }
  function emailChange(e){
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = e.target.value;
    var validemail = emailRegex.test(email);
    if(validemail) {
      setEmail(e.target.value)
      setMsg();
    }else{
      setMsg("Please enter valid Email Id");
    }
  }
  const submitEmail =async ()=>{
    localStorage.setItem("userEmail",email)
    setLoading(true)
    setMsg();
    await httpsignup.post("/userloginLMS/sendmailotp",{
        userEmail:email,
        roleId : roleId,
        authCode:"miidBM390"
      }).then((result) => {
        console.log(result.data);
        setLoading(false)
        if (result.data==='Record Not Found With This EmailId') {
          setMsg("Please enter email id registered with us");
        }
        else if(result.data==='Sent Email on your email address, please check your email') {
          setMsg(result.data);
          navigate("/verifyotp");
        }
      }).catch((e) => {
        console.log(e.response)
        setLoading(false)
      })    
  }
  return (
    <>
    <div className='row d-flex align-items-center justify-content-between vh-100 login-back-container'>
    <div className='col-6 d-flex justify-content-center d-md-block d-none'>
    <img src={itreion} alt="Image not found" className='w-100 ms-5 ps-5'/>
    </div>
      <div className='col-md-5 col-12 m-md-0  h-100 justify-content-center align-content-center bgwhite rounded-1 d-flex'>
        {/* <div className='col-lg-6 signin-image'></div> */}
        <div className="W-100 pt-5 pb-5 d-flex flex-column">
          <img onClick={handleClick} width='15px' className="backarrow text-left" src={require("../images/leftarrow.png")} alt="Image not found"/>
          {/* <img className='mx-auto d-block d-md-none' src={require('../images/LogoUpdated.png')} alt="Image not found" /> */}
          {/* <img src={require('../images/Logo.png')} alt="logo" className='mx-auto d-block d-md-none' /> */}
          {/* <img src={Logo} alt="logo" className='d-none d-md-block d-lg-none mx-auto'/> */}
          <div className="col-12">
            <div className="logo text-center">
              <img src={require("../images/lock.png")} alt="back" />
            </div>
            <div className="col-md-10 mx-auto">
              <p className="forgetpass-heading">Forget Password</p>
              <p className="font12 black60 mt-2">Please enter your email address to receive a OTP</p>
              <div className="mt-4">
              <div>
             <p className="p-heading">Role</p>
            <select onChange={roleChange} name="role" id="role">
              <option value="default" className='pe-2'>Select Role</option>
            {role.map((data) => {
               return (
     <option value={data.roleName} className='pe-2'>{data.roleName}</option>
     )})
               }
 </select>

           </div>
                <p className="e-heading">E-mail</p>
                <Input type="email" name="email" className="col-12 pl-0 pr-0" change={emailChange} placeholder="@gmail.com" />
                <div className='height15'>{msg && <div className='errMsg mb-4'>{msg}</div>}</div>
              </div>
              <div className="text-center col-12">
              {( loading ? <Spinner animation="border" className='text-center forgetpass-spinner mb-2' variant="warning" />
                : <button onClick={submitEmail} disabled={disable} className={disable ? "signin-btn-disable col-12 py-2 mb-4" :'forgetpass-btn'}>Get OTP</button>
              )}
              <p className='text-center font12 black60 mt-2'>@2022 all rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ForgetPassword;

