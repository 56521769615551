import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import {useState,useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import NoRecFound from "../components/NORecFound";
import httpuserprofile from "../js/http-lms_userprofile"
import Spinner from 'react-bootstrap/Spinner';
import CsvDownloader from 'react-csv-downloader'
const EditPrograms = () => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [programModulesList, setProgramModulesList] = useState([])
    const [programModulesCount, setProgramModulesCount] = useState({})
    const[error, setError] = useState()
    const [currentItems, setCurrentItems] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState()
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);
    //initial data fetching
    useEffect(()=>{
        programDetailsCount()
    },[])    
    function programDetailsCount() {
        httpuserprofile.get('/userprofileLMS/getModuleAndTopicCountByProgramId?programId='+params.id)
        .then((response) => {
            console.log("program details : ", response.data)
            setProgramModulesCount(response.data)
            if(response.data.moduleCount > 0 || response.data.topicCount>0){
                ProgramDetailsList()
            }else{
                setLoading(false)
                setError("No Data Found")
            }
        }).catch((e) => {
            console.log(e.response)
        })
    }
    function ProgramDetailsList(){
        console.log("router parameters : ", params)
        httpuserprofile.get('/userprofileLMS/programPlanExport?programId='+params.id)
        .then((response) => {
            console.log("program details : ", response.data)
            setLoading(false)
            if(response.data && response.data.length > 0){
                setProgramModulesList(response.data)
            }else{
                setError("No Data Found")
            }
        }).catch((e) => {
            console.log(e.response, "not successful")
            setLoading(false)
            setError("Bad Request")
        })
    }
    //page navigation
    const navigate = useNavigate();
    function navToPrograms() {
        navigate("/programs");
    }
    //pagination
    useEffect(() => {
        setTotalPages(Math.round(programModulesList.length/dataPerPage))
    }, [programModulesList])
    useEffect(() => {
        setEndOffset(itemOffset + dataPerPage)
    }, [itemOffset, programModulesList])
    useEffect(() => {
        setCurrentItems(programModulesList.slice(itemOffset, endOffset))
        console.log(programModulesList.slice(itemOffset, endOffset), 'll')
    }, [endOffset, programModulesList, dataPerPage])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % programModulesList.length;
        setItemOffset(newOffset);
        if(event.selected===totalPages-1) {
          console.log('this is the final page');
        }
    };
    //data sorting
    const sortData = (data, sortKey, sortDirection) => {
        return data.sort((a, b) => {
          if (sortDirection === 'asc') {
            return a[sortKey] > b[sortKey] ? 1 : -1;
          } else {
            return a[sortKey] < b[sortKey] ? 1 : -1;
          }
        });
      };
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const handleSort = key => {
        if (key === sortKey) {
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          setSortKey(key);
          setSortDirection('asc');
        }
    };
    const sortedData = sortData(currentItems, sortKey, sortDirection);
    //csv file columns
    const columns = [
        {
            id: 'ProgramName',
            displayName: 'ProgramName'
        }, 
        {
            id: 'ProgramDescription',
            displayName: 'ProgramDescription'
        }, 
        {
            id: 'ModuleName',
            displayName: 'ModuleName'
        }, 
        {
            id: 'ModuleDescription',
            displayName: 'ModuleDescription'
        }, 
        {
            id: 'TopicName',
            displayName: 'TopicName'
        }, 
        {
            id: 'TopicDescription',
            displayName: 'TopicDescription'
        }, 
        {
            id: 'Duration',
            displayName: 'Duration'
        },
        {
            id: 'ProgramCode',
            displayName: 'ProgramCode'
        }
    ];
     
    return (
    <>
      <Sidebar />

      {/* Main Container */}
      <div className="dashboard-container mx-4">
        <Navb />
        <div className="px-md-5 mt160">
            <div className="col-lg-12 d-md-flex justify-content-between">
              <div className="d-flex mt-4 mb-4">
                <img onClick={navToPrograms} width="15px" height="15px" className="me-2 pointer img-fluid" src={require("../images/leftarrow.png")} alt="back"/>
                <span className='pointer font18 bold ml-2' onClick={navToPrograms}>{params.programName}</span>
              </div>
              <div>
              <CsvDownloader className="mt-4 mb-4" filename="programfile" extension=".csv" columns={columns} datas={programModulesList}>
                <span className='UpdatedMainColor updateMainBorder downloadProgrmMouseover radius5 col-12 py-2 px-3 mb-4'>
                    Download Program File
                </span>
                </CsvDownloader>
              </div>
            </div>
            <div className="col-lg-12 d-flex">
                <div className="d-flex flex-column px-3 px-md-4 me-md-4 shadow batchCards programdetailcards py-3 me-3">
                    <h6>Total Modules</h6>
                    <h2 className="mb-0">{programModulesCount.moduleCount ? programModulesCount.moduleCount : 0}</h2>
                </div>
                <div className="d-flex flex-column px-3 px-md-4 me-md-4 shadow batchCards py-3 programdetailcards">
                    <h6>Total Topics</h6>
                    <h2 className="mb-0">{programModulesCount.topicCount ? programModulesCount.topicCount : 0}</h2>
                </div>
            </div>        
        </div>
        {loading ? <div className=" h-100 d-flex mt-5 col-11 mx-auto bgwhite justify-content-center align-items-center mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div>:
            error ? <div className='col-12 mt-5 text-center'>
                <NoRecFound error="No Data found" />
            </div> :
            <div className="scrollme mt-4 px-md-5">
                <table className="table whitecolor">
                    <thead>
                        <tr>
                        <th scope="col">Program Name <img onClick={() => handleSort('ProgramName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Program Code <img onClick={() => handleSort('ProgramCode')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Module Name  <img onClick={() => handleSort('ModuleName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Topic Name <img onClick={() => handleSort('TopicName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Duration (Min) <img onClick={() => handleSort('Duration')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        {/* <th scope="col">Students <img onClick={() => handleSort('totalStudents')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Status <img onClick={() => handleSort('batchStatus')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th> */}
                        {/* <th scope="col">Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((data, index) => {
                        return (
                        <tr key={data.TopicName}>
                            <td >{data.ProgramName}</td>
                            <td>{data.ProgramCode}</td>
                            <td>{String(data.ModuleName).replace(/\"/g, "")}</td>
                            <td>{String(data.TopicName).replace(/\"/g, "")}</td>
                            <td>{data.Duration}</td>
                            {/* <td>{data.totalStudents}</td>
                            <td>{data.batchStatus}</td> */}
                            {/* <td><img src={require("../images/edit2.png")} onClick={()=>handleShow(data)} alt="Image not found" className="more"/></td> */}
                        </tr>
                        )
                        })}
                    </tbody>
                </table>
                <div className="d-flex justify-content-end">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
            </div>}
        </div>
    </>
  );
};

export default EditPrograms;
