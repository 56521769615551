import React, { useEffect } from "react";
import Navb from "../pages/Navb.jsx";
// import "../styles/Student.css";
import Sidebar from "../pages/Sidebar.jsx";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Dropdown from "react-bootstrap/Dropdown";
import Select from 'react-select'
import { useNavigate} from "react-router-dom";
import httpuserprofile from "../js/http-lms_userprofile";
import Spinner from 'react-bootstrap/Spinner';
import UpdatePassInside from '../components/UpdatePassInside'

function UpdateSelf() {

  const navigate = useNavigate()
  const [disable, setDisable] = useState(true)
  const [defaultuserDetails, setUserDetails] = useState({})
  //initial data
  const [batchList, setBatchList] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [statesList, setStatesList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [collegesList, setCollegesList] = useState([])
  const [educationList, setEducationList] = useState([])
  const [studentProgress, setStudentProgress] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [selectedCollegeNameOption, setSelectedCollegeNameOption] = useState(null);
  const [selectedBatchOption, setSelectedBatchOption] = useState(null);
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formErros, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [popped, setPopped] = useState(false);

  useEffect(()=>{
    getLoggedInUserDetails()
  }, [])
  const getLoggedInUserDetails =async ()=>{
    httpuserprofile.get(`/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem("userId")}`)
    .then((response) => {
      console.log("UserDetails in update user:", response)
      setSelectedBatchOption(response.data.batch)
      setSelectedCityOption(response.data.city)
      setSelectedStateOption(response.data.state)
      setSelectedCountryOption(response.data.country)
      setSelectedCollegeNameOption(response.data.collegeDetails)
      setUserDetails(response.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  //API call when page loader
  useEffect(() => {
    //fetching batchlist
    httpuserprofile
      .get("/userprofileLMS/getUserBatches")
      .then((res) => {
        console.log("user batches", res)
        if(res.data !== 'No records found'){
        setBatchList(res.data)
        }
      })
    //fetching countries list
    httpuserprofile
      .get("/userprofileLMS/getUserCountries")
      .then((res) => {
        console.log("user countries", res)
        if(res.data !== 'No records found'){
        setCountriesList(res.data)
        }
      })
    //fetching college list
    httpuserprofile
      .get("/userprofileLMS/getCollgeList")
      .then((res) => {
        console.log("user colleges", res)
        if(res.data !== 'No records found'){
        setCollegesList(res.data)
        }
      })
    //fetching college list
    httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=5")
      .then((res) => {
        console.log("user colleges", res)
        if(res.data !== 'No records found'){
        setEducationList(res.data)
        }
      })
    //fetching college list
    httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=2")
      .then((res) => {
        console.log("user colleges", res)
        if(res.data !== 'No records found'){
        setStudentProgress(res.data)
        }
      })
    //create list of years
    var currentYear = new Date().getFullYear();
    var startYear = currentYear-12;  
    var endyear = currentYear+6;
    var list = []
    while ( startYear <= endyear ) {
      let year = {'year' : endyear--}
      list.push(year);
    }
    setYearsList([]);
    setYearsList(list)
    // if(yearsList.length>0){
    //   setYearsList(yearsList)
    // }
    
  },[])
  // validate data and enable/disable submit btn
  useEffect(() => {
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }, [defaultuserDetails])
  function enableDisableBtn() {
    if(defaultuserDetails.firstName && defaultuserDetails.lastName && defaultuserDetails.emailId && defaultuserDetails.phoneNumber && defaultuserDetails.address && defaultuserDetails.cityId && defaultuserDetails.stateId && defaultuserDetails.countryId   && defaultuserDetails.studentStatusId) {
      console.log("false")
      setDisable(false)
    }
    else {
      console.log("true")
      setDisable(true)
    }
  }
  //updating defaultuserDetails for final submission
  function handleChange(e) {
    setErrorMessage('')
    const {name, value} = e.target;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultuserDetails)
  }
  // triggered after changing the country
  // reset selected state and city
  // fetch states list based on selected country
  function handleCountryChange(e){
    console.log("selected country", e)
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e)
    setSelectedStateOption(null);
    setSelectedCityOption(null)
    setStatesList([])
    setCitiesList([])
    defaultuserDetails.stateId = null;
    defaultuserDetails.cityId = null;
    defaultuserDetails.countryId = e.countryId;
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
    httpuserprofile
      .get("/userprofileLMS/getUserStates?countryId="+e.countryId)
      .then((res) => {
        console.log("user states", res)
        setStatesList(res.data)
      })
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function handleStateChange(e){
    console.log("selected state", e)
    //reset cities, once changed selected country
    setSelectedStateOption(e)
    setSelectedCityOption(null)
    setCitiesList([])
    defaultuserDetails.stateId = e.stateId;
    defaultuserDetails.cityId = null;
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
    httpuserprofile
      .get("/userprofileLMS/getUserCities?stateId="+e.stateId)
      .then((res) => {
        console.log("user cities", res)
        setCitiesList(res.data)
      })
  }
  // triggered after changing the city
  function handleCityChange(e) {
    console.log("selected city : ", e)
    setSelectedCityOption(e)
    defaultuserDetails.cityId = e.cityId
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }
  // triggered after selecting college name
  function handleCollegeNameChange(e) {
    console.log("selected college name : ", e)
    setSelectedCollegeNameOption(e)
    defaultuserDetails.collegeId = e.collegeId;
  }

  
  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({ 
        ...styles, 
        // border: `1px solid #ced4da`, 
        // boxShadow: `0 0 0 1px`, 
        // ':hover' : {
        //   // boxShadow: `0 0 0 1px `,  
        //   backgroundColor : "blue"
        // } 
     }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: "white",
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
      ':hover' : {
        // boxShadow: `0 0 0 1px `,  
        backgroundColor : "blue",
        color : "white"
      } 
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };

  // final submission
  function SubmitFinalData() {
    console.log("final submission : ", defaultuserDetails)
    setFormErrors(validate(defaultuserDetails))
    setIsSubmit(true)
  }
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phonenumberregex = /^[6789]\d{9}$/;
    if(!values.firstName){
      errors.firstName = "First Name is required!"
    }else if(!allowaplaspaces.test(values.firstName)){
      errors.firstName = "First name should contain alphabets and spaces"
    }
    if(!values.lastName){
      errors.lastName = "Last Name is required!"
    }else if(!allowaplaspaces.test(values.lastName)){
      errors.lastName = "Last name should contain alphabets and spaces"
    }

  
    // if(!values.emailId){
    //   errors.emailId = "Email Id is required!"
    // }
    
    else if(!emailregex.test(values.emailId)){
      errors.emailId = "Please enter valid email id"
    }
    if(!values.phoneNumber){
      errors.phoneNumber = "Phone Number is required!"
    }else if(!phonenumberregex.test(values.phoneNumber)){
      errors.phoneNumber = "Please enter valid phone number"
    }
    if(!values.address){
      errors.address = "Address is required!"
    }
    if(!values.cityId){
      errors.cityId = "City is required!"
    }
    if(!values.stateId){
      errors.stateId = "State is required!"
    }
    if(!values.countryId){
      errors.countryId = "Country is required!"
    }
    // if(!values.rollNumber){
    //   errors.rollNumber = "Roll Number is required!"
    // }
    // if(!values.collegeId){
    //   errors.collegeId = "College Name is required!"
    // }
    // if(!values.yearOfGraduation){
    //   errors.yearOfGraduation = "Year of graduation is required!"
    // }
    // if(!values.educationId){
    //   errors.educationId = "Education level is required!"
    // }
    // if(!values.batchId) {
    //   errors.batchId = "Please select Batch!"
    // }
    // if(!values.studentStatusId){
    //   errors.studentStatusId = "Please select student progress!"
    // }
    return errors;
  }


  useEffect(() => {
    console.log('Hello')
    console.log(Object.keys(formErros).length);
    if(Object.keys(formErros).length === 0 && isSubmit){
      setSubmitLoading(true)
      console.log("YEShgfuagsdfagydsfuyasduygdasdfuysdag")
      console.log("final submissionuiuiuiiu : ", defaultuserDetails)
      httpuserprofile.put('/userprofileLMS/updateProfileDetails', defaultuserDetails)
      .then((response) => {
        console.log("update response : ", response.data)
        setErrorMessage("Updated sucessfully")
        setSubmitLoading(false)
        setPopped(true)
        setTimeout(() => {
          setErrorMessage('')
          setPopped(false)
        },1500)
      }).catch((e) => {
        console.log("error update : ", e.response)
        setSubmitLoading(false)
      })
    }
  }, [formErros, isSubmit])

  function closeUpdatePasswordPopup(data) {
    console.log("while closing popup : ", data)
    setPop(false)
  }

  // UseStates to select, set and crop-image

  const [src, selectFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 200,
    height: 200,
  });

  // UseState to set resulting image

  const [result, setResult] = useState(null);

  // UseState for the crop functionality popup

  const [pop2, setPop2] = useState(false);

  function removeProfile() {
    setResult("");
    console.log(result);
  }


  // Function to select the uploaded image by the user

  const handleFileChange = (e) => {
    selectFile(URL.createObjectURL(e.target.files[0]));
    setPop2(true);
  };

  // Function to crop the selected image

  function imagecrop() {
    const canvas = document.createElement("canvas");
    const scalex = image.target.naturalWidth / image.target.width;
    const scaley = image.target.naturalHeight / image.target.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image.target,
      crop.x * scalex,
      crop.y * scaley,
      crop.width * scalex,
      crop.height * scaley,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
    setPop2(false);
  }

  //Renders the result(picture/placeholder) on screen load according to the condition in useEffect

  useEffect(() => {
    if(sessionStorage.getItem('userId')) {
      if (result) {
        uploadedImage.current.src = result;
      } else {
        uploadedImage.current.src = require("../images/ProfilePlaceholder.png");
      }
    }
  }, [result]);

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);


  //Below useState and the function runs the change password popup

  const [pop, setPop] = useState(false);

  function formPop(e) {
    e.preventDefault();
    setPop((pValue) => !pValue);
  }



  function cancelForm(e){
    e.preventDefault()
    setErrorMessage('')
    setUserDetails({})
    setFormErrors({})
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    setStatesList(null)
    setCitiesList(null)
  }

  
  return (
    <>

{popped && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully user has been updated</p>
      </div>
    </div>}

      <div>
        <Sidebar />

        {/* Main Container */}

        <div className="dashboard-container mx-auto mt120">
          {/* <Navb result={result} /> */}

          <div className="mb-2"></div>

          {/* Conditional rendering for crop functionality popup */}

          {pop2 && (
            <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
              <div className=" d-flex flex-column">
                {src && (
                  <>
                    <ReactCrop
                      crop={crop}
                      aspect={1 / 1}
                      onChange={(c) => setCrop(c)}
                    >
                      <img
                        style={{ width: "40vw" }}
                        src={src}
                        onLoad={setImage}
                      />
                    </ReactCrop>
                    <button onClick={imagecrop} className="update-picture py-2">
                      Update Picture
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Conditional rendering for change password popup */}

          {pop && (
            <UpdatePassInside onClose={closeUpdatePasswordPopup}/>
          )}



          {/* <div className=" bgwhite shadow py-4 my-5 px-4 px-lg-5"> */}
          <div className=" bgwhite shadow mt-4 mb-5 mx-1 mx-md-4">
          <div className={"font14 green text-center py-4"}>{errorMessage}</div>
            <div className="mt-4 mb-5 px-5">

              {/* File(image) uploading input */}

              <input
                type="file"
                accept="image/*"
                ref={imageUploader}
                style={{
                  display: "none",
                }}
                onChange={handleFileChange}
              />
              <div className="edit-container d-inline-block position-relative">
                <img
                  ref={uploadedImage}
                  style={{
                    width: "118px",
                    height: "118px",
                    borderRadius: "50%",
                  }}
                />

                <Dropdown
                  className=" d-inline-block position-absolute edit-dropdown"
                  drop={"end"}
                >
                  <Dropdown.Toggle
                    className="dropdown-more shadow"
                    variant="none"
                  >
                    <img
                      src={require("../images/edit.png")}
                      alt="Image not found"
                      className="more"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => imageUploader.current.click()}
                      eventKey="1"
                    >
                      Update Picture
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeProfile} eventKey="2">
                      Remove Picture
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="form-container px-5">
                <Form id="create-User-Form">
                  <Row className="pb-3">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                     <div className="d-flex required"><h6>First Name</h6></div>
                      <Form.Control
                        className={formErros.firstName ? "borderred" : ""}
                        placeholder="Enter first name"
                        autoComplete="off"
                        name='firstName'
                        defaultValue={defaultuserDetails.firstName}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.firstName}</p>
                    </Col>
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <h6>Middle Name</h6>
                      <Form.Control
                        className=""
                        placeholder="Enter middle name"
                        autoComplete="off"
                        name='middleName'
                        defaultValue={defaultuserDetails.middleName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Last Name</h6></div>
                      <Form.Control
                        className={formErros.lastName ? "borderred" : ""}
                        placeholder="Enter last name"
                        autoComplete="off"
                        name='lastName'
                        defaultValue={defaultuserDetails.lastName}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.lastName}</p>
                    </Col>
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex"> <h6>Email</h6></div>
                      <Form.Control
                        className={formErros.emailId ? "borderred" : ""}
                        placeholder="e.g. xxxx@gmail.com"
                        autoComplete="off"
                        name='emailId'
                        defaultValue={defaultuserDetails.emailId}
                        disabled
                        onChange={handleChange}
                      />
                      {/* <p className="red font12">{formErros.emailId}</p> */}
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Phone</h6></div>
                      <Form.Control
                        className={formErros.phoneNumber ? "borderred" : ""}
                        placeholder="Enter mobile"
                        autoComplete="off"
                        name='phoneNumber'
                        maxLength={10}
                        defaultValue={defaultuserDetails.phoneNumber}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.phoneNumber}</p>
                    </Col>
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                     <div className="d-flex required"> <h6>Address 1</h6></div>
                      <Form.Control
                        className={formErros.address ? "borderred" : ""}
                        placeholder="Street address"
                        autoComplete="off"
                        name='address'
                        defaultValue={defaultuserDetails.address}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.address}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                      <h6>Address 2</h6>
                      <Form.Control
                        className=""
                        placeholder="House no."
                        autoComplete="off"
                        name='address1'
                        defaultValue={defaultuserDetails.address1}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>Country</h6></div>
                      <Select
                        value={selectedCountryOption}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        // defaultValue={defaultuserDetails.country}
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.countryName}`
                        }
                        options={countriesList}
                      />
                      <p className="red font12">{formErros.countryId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>State</h6></div>
                      <Select
                        value={selectedStateOption}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        defaultValue={defaultuserDetails.state}
                        getOptionLabel={option =>
                          `${option.stateName}`
                        }
                        options={statesList}
                      />
                      <p className="red font12">{formErros.stateId}</p>
                    </Col>
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>City</h6></div>
                      <Select
                        value={selectedCityOption}
                        onChange={handleCityChange}
                        placeholder="Select City"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        defaultValue={defaultuserDetails.city}
                        getOptionLabel={option =>
                          `${option.cityName}`
                        }
                        options={citiesList}
                      />
                      <p className="red font12">{formErros.cityId}</p>
                    </Col>
                  </Row>
                    <div>
                   
                  {/* <Row className="pb-3">
                    <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <h6 className="required">Program Status</h6>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.studentStatusId ? "borderred form-select" : "form-select"}
                        name="studentStatusId"
                        aria-label="Default select example"
                        value={defaultuserDetails.studentStatusId}
                        defaultValue={defaultuserDetails.studentStatusId}
                        disabled
                      >
                        <option value="">Select Program Status</option>
                        {studentProgress.map((status, index) => {
                          return (
                            <option value={status.indexCode} key={status.indexCode}>{status.indexDescription}</option>
                        )})}
                      </Form.Select>
                    </Col>
                  </Row> */}

                  </div>
                </Form>

              {/* <div style={{display: form==="" ? 'none' : 'flex'}} className="row">
                <div className="col-lg-12">
                {submitLoading ? <div className="col-lg-4 col-12 mt-3 mb-5 text-center">
                  <Spinner className="mx-auto" animation="border" variant="secondary"/>
                  </div> :
                  <button disabled={disable} onClick={submitData} className={ disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 orange"}>
                    Create
                  </button>
                }
                </div>
              </div> */}
            </div>
            {/* <Form>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Roll No*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter roll no."
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>First Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter first name"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Last Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter last name"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>Email*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="e.g. xxxx@gmail.com"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Phone Number*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter mobile"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>College Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter college name"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Location*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter address"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>Year of Graduation*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Year of grad."
                  />
                </Col>
              </Row>
            </Form> */}

            <div className="row px-5">
              <div className="col-12 col-lg-4 text-start">
              {submitLoading ? <div className="col-lg-1 col-12 mt-3 mb-5 text-center">
                  <Spinner className="mx-auto" animation="border" variant="secondary"/>
                  </div> :
                <button disabled={disable} className={ disable ? "col-8 profile-buttons-update mt-3 mb-5 background-gray" : "col-8 profile-buttons-update mt-3 mb-5 UpdatedMainBGColor"} onClick={SubmitFinalData}>
                  Update
                </button>
              }
              </div>
              <div className="col-12 col-lg-6 d-flex">
              <button className="  cancelUser mt-3 mb-5" onClick={cancelForm}>Erase</button>
              <button className="cancelUser ms-5 mt-3 mb-5 " onClick={formPop}>Change Password</button>
                {/* <h6
                  onClick={formPop}
                  // className=" mt-lg-4 pt-lg-2 mb-4 change-pass"
                  className="ms-5 cancelUser mt-3 mb-5 pt-2 ps-5 pointer"
                >
                  Change Password
                </h6> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateSelf;
