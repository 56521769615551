import React, { useEffect } from "react";
import { useState } from "react";
import BatchDetailsTable from "../../components/BatchDetailsTable";
import httpuserprofile from "../../js/http-lms_userprofile"
import Spinner from 'react-bootstrap/Spinner';

const ListOfUsers = () => {
    //data
    const [loading, setLoading] = useState(false)
    const [Studentdata, setStudentData] = useState([]);
    useEffect(() => {
      setLoading(true)
      //fetching college list
      httpuserprofile
        .get("/userprofileLMS/getStudentsList?limitCounter=1")
        .then((res) => {
          console.log("list of students", res)
          if(res.data !== 'No records found' && res.data.length > 0){
          setStudentData(res.data)
          }
          setLoading(false)
        }).catch((e) => {
          console.log(e)
          setLoading(false)
        })
    },[])
    //navigation

    //events

      
  return (
    <>
    {loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <BatchDetailsTable data={Studentdata} setData={setStudentData} />
    }
    </>
  )
}

export default ListOfUsers
