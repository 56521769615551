import React, { useRef } from 'react'
import DatePicker from "react-datepicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button, Modal } from 'react-bootstrap';
import { useState } from "react";
import httpassignments from '../js/http-lms_assignments'

const AddAssignment = (props) => {

  const fileUpload = useRef(null)

    const [formData, setFormData] = useState({});

    const [show, setShow] = useState(false);

    const [assignment, setAssignment] = useState()

    const[file, setFile] = useState()

    const [pop, setPop] = useState(false);

    


    function onAssignmentFileChanged(event) {
      console.log(event)
      setAssignment(event.target.files[0])
      setFile(event.target.files[0])
  }




  const submitData = (event) => {
    event.preventDefault()
    // const file = event.target.files[0];
  
    console.log(file, 'lllllllllllllllll')
  
    let form = new FormData();
  
    var pblob = new Blob([JSON.stringify(file)], { type: "application/json" })
    
    form.append('file', pblob);
    httpassignments
    .post(`/api/v1/topicAssignmentLMS/uploadAssignments?assignmentType=trainer&assignmentName=${formData.assignmentName}&assignmentDescription=${formData.assignmentDesc}&programName=${props.fd.programName}&userEmail=${sessionStorage.getItem('userEmail')}&topicId=${props.fd.topicId}`, form, {
              headers: {
                  "Content-Type": "multipart/form-data",
                  "Accept" : "*/*"
                }
          })
    .then((res) => {
      console.log("Upload Assignment Status", res)
      // setTopics(res.data)
      // setLoading(1)
      setShow(false); 
      setPop(true)
  setTimeout(() => {
    setPop(false)
  }, 1500)
    }).catch((e) => {
      console.log(e)
      // setLoading(1)
    })
  }


    function handleChange(e) {
        // setErrorMessage('')
        const {name, value} = e.target;
        setFormData((pValue) => {
          console.log(pValue)
          return {
            ...pValue,
            [name]: value
          }
        })
        console.log(formData);
      }





      const handleShow = () => setShow(true);

 const handleClose = () => {
    setShow(false); 
  }

  return (
    <div>


{pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully assignment has been updated</p>
      </div>
    </div>}



<Button disabled={props.dis} className={props.dis ? "updatebtndisable px-2 me-2 mb-4" :'updatebtn  px-2 me-2 mb-4'} onClick={handleShow}>
        Add Assignment
      </Button>



      <Modal
        show={show}
        onHide={handleClose}
        className="modal-100"
        dialogClassName={`modal-left-small h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Add Assignment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id='create-assignment-form' className='h-100' >
          <div className='scrollForm'>
                  <Row className="pb-4">
                  
                  <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Assignment Name</Form.Label>
                      <Form.Control
                        // className={formErros.firstName ? "borderred" : ""}
                        placeholder="Assignment Name"
                        autoComplete="off"
                        name='assignmentName'
                        onChange={handleChange}
                      />
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                    
                      </Row>


                      <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Assignment Document</Form.Label>
                    <div className='d-flex justify-content-end pe-2 py-3 updatedesign'>
                     {assignment && <div className='pe-3 pt-2'>{assignment.name}</div>}
                    <button  onClick={(e) => {e.preventDefault(); fileUpload.current.click()}} className='updatebtn  px-2 me-2'>Attach Document</button>
                    </div>
                 </Col>
                 </Row>


                      <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Assignment Description</Form.Label>
                 <Form.Control as="textarea" onChange={handleChange} name='assignmentDesc' maxLength={40} rows={3} placeholder="Assignment Description (max 40 characters)" />
                 </Col>
                 </Row>

                 <Row className="pb-4">
                 <Form.Label>Start Date</Form.Label>
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <DatePicker className='w-100 rounded heightDate' selectsEnd
                    showYearDropdown
                    placeholderText="Start Date"
                    dateFormat="MM/dd/yyyy" />
                 </Col>
                 </Row>

                 <Row className="pb-4">
                 <Form.Label>End Date</Form.Label>
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <DatePicker className='w-100 rounded heightDate' selectsEnd
                    showYearDropdown
                    placeholderText="End Date"
                    dateFormat="MM/dd/yyyy" />
                 </Col>
                 </Row>

                 <div className="col-lg-12">
                {/* <div className="col-lg-4 col-12 mt-3 mb-5 text-center">
                  <Spinner className="mx-auto" animation="border" variant="secondary"/>
                  </div> : */}
                  <div className="d-flex justify-content-around fixed-bottom">
                  <button  onClick={submitData} className="radius5 border-0 col-5 p-2 mt-3 mb-5 btn-primary">
                    Create
                  </button>
                  <button className=" radius5 p-2 mt-3 mb-5 border-0 col-5 grey ml-3 ml" >Clear</button>
                  </div>
                
                </div>

                 </div>
                 {/* <button onClick={handleSubmit}  disabled={disable} className={ disable ? "profile-buttons-update w-100 mt-3 mb-5 grey" : "profile-buttons-update w-100 mt-3 mb-5 UpdatedMainBGColor"}>Create</button> */}
         </Form>


         <div>
    <input ref={fileUpload} style={{display: 'none'}} type="file"  onChange={(e) => {onAssignmentFileChanged(e)}} />
  </div>
        
        </Modal.Body>
      </Modal>
      
    </div>
  )
}

export default AddAssignment
