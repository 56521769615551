import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState } from "react";
import AssignedAssignments from "./assignments/AssignedAssignments";
import ReceivedAssignments from "./assignments/ReceivedAssignments";
import AssignmentsList from "./assignments/AssignmentsList";

const Assignments = () => {
  const [selectedTab, setSelectedTab] = useState('Assigned')
  const [button, setButton] = useState(1);
  function buttonClick(e) {
    if (e.target.className === "btn1 profile-buttons mt-2 px-3") {
      setButton(1);
      setSelectedTab('Assigned')
    } else if (e.target.className === "btnx profile-buttons mx-md-3 mt-2 px-3") {
      setButton(2);
      setSelectedTab('Recieved')
    } else if (e.target.className === "btn3 profile-buttons mt-2 me-2 px-3") {
      setButton(3);
      setSelectedTab('Assignments List')
    }
    handleClick(e);
  }
  const [collapse, setCollapse] = useState(1);


  function handleClick(e) {
    if (e.target.className === "btn1 profile-buttons mt-2 px-3") {
      setCollapse(1);
    } else if (e.target.className === "btnx profile-buttons mx-md-3 mt-2 px-3") {
      setCollapse(2);
    } else if (e.target.className === "btn3 profile-buttons mt-2 me-2 px-3") {
      setCollapse(3);
    }
  }

  return (
    <>
      <Sidebar />
      {/* Main Container */}
      <div className="dashboard-container mx-4">
        {/* <Navb /> */}
        <div className="px-md-5 mt160">
          

        <div className="col-lg-12">
  <div className="row">

  <div className="d-flex ps-4 me-md-4 py-4 bgwhite rounded shadow-sm col-lg-3 col-12 ms-lg-2 mb-lg-0 mb-3">
        <h6 className="font20">Total Assignments</h6>
        <h6 className="ms-4 font20">0</h6>
      </div>

      <div className="d-flex ps-4 me-md-4 bgwhite rounded shadow-sm py-4 col-lg-3 col-12">
        <h6 className="font20">Assigned Assignments</h6>
        <h6 className="ms-4 font20">0</h6>
      </div>
      </div>
      </div>



          <div className="d-flex mb-4 mt-5">
            {/* <h1 className="mb-2 mt-2 font20 pointer">Assignments  {' > '}  {selectedTab}</h1> */}
            <div className="d-flex  w-100">
              <div className="px-3 tabDesign w-100">
                <button onClick={buttonClick} className={button === 1 ? "active-button profile-buttons mt-2 px-3" : "btn1 profile-buttons mt-2 px-3"}>
                  Assigned
                </button>
                <button onClick={buttonClick} className={button === 2 ? "active-button profile-buttons mx-md-3 mt-2 px-3" : "btnx profile-buttons mx-md-3 mt-2 px-3"}>
                Received
                </button>
                <button onClick={buttonClick} className={button === 3 ? "active-button profile-buttons mt-2 me-2 px-3" : "btn3 profile-buttons mt-2 me-2 px-3"}>
              List of Assignments
                </button>
              </div>
              {/* <div className="d-flex">
                <h6 className="me-2 mt-2">Filters</h6>
                <img src={require("../images/filter.png")} alt="Image not found" className="filter-icon mt-2"/>
              </div> */}
            </div>
          </div>
          <div className="scrollme">
            {collapse === 1 && <AssignedAssignments/>}
            {collapse === 2 && <ReceivedAssignments/>}
            {collapse === 3 && <AssignmentsList/>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignments;
