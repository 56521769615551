import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import BatchListTable from "../components/BatchListTable";
import {useState, useEffect} from "react";
import LeftToRightModal from "../components/LeftToRightModal";
import httpprograms from "../js/http-lms_userprofile"
import Spinner from 'react-bootstrap/Spinner';

const BatchList = () => {

  const[refresh, setRefresh] = useState(true)

  const[error, setError] = useState()

  const [loading, setLoading] = useState(0)

  const[totalStudent, SetTotalStudent] = useState();

   const[programsCount, setProgramsCount] = useState();

   const[batchesCount, setBatchesCount] = useState();

   const[trainersCount, setTrainersCount] = useState();


  useEffect(()=>{
    allCards()
  },[])


  function allCards(){
    httpprograms.get('/userprofileLMS/getDashboardCount')
    .then((response) => {
      if(response.data=="No records found"){
        setLoading(1)
        console.log("API Values DCards : : ", response.data)
        setError(response.data)
      }
      else{
      setLoading(1)
      console.log("API Values DCards: : ", response.data)
      SetTotalStudent(response.data.studentsCount)
      setProgramsCount(response.data.programsCount)
      setBatchesCount(response.data.batchesCount)
      setTrainersCount(response.data.trainersCount)
    }
    }).catch((e) => {
      console.log(e.response)
    }) 
  }

  return (
    <>
      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4">
        {/* <Navb /> */}

        <div className="px-md-5 mt160">
        <div className="col-lg-12">
  <div className="row">


{loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 batchCards py-5 col-lg col-12 ms-lg-2"> <Spinner animation="border" variant="secondary" /></div> :
  <div className="d-flex flex-column ps-4 me-md-4 batchCards py-4 col-lg col-12 ms-lg-2">
        <h6>Total Batches</h6>
        <h2 className="pt-2">{batchesCount}</h2>
      </div>
}

{loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 batchCards py-5 col-lg col-12 my-lg-0 my-3"> <Spinner animation="border" variant="secondary" /></div> :
      <div className="d-flex flex-column ps-4 me-md-4 batchCards py-4 col-lg col-12 my-lg-0 my-3">
        <h6>Total Programs</h6>
        <h2 className="pt-2">{programsCount}</h2>
      </div>
}



{loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 batchCards py-5 col-lg col-12 mb-lg-0 mb-3"> <Spinner animation="border" variant="secondary" /></div> :
      <div className="d-flex flex-column ps-4 me-md-4 batchCards py-4 col-lg col-12 mb-lg-0 mb-3">
        <h6>Total Trainers</h6>
        <h2 className="pt-2">{trainersCount}</h2>
      </div>
}

{loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 batchCards py-5 col-lg col-12"> <Spinner animation="border" variant="secondary" /></div> :
      <div className="d-flex flex-column ps-4 me-md-4 batchCards py-4 col-lg col-12">
        <h6>Total Students</h6>
        <h2 className="pt-2">{totalStudent}</h2>
      </div>
}





    </div>
    </div>        


        {/* <div className="d-flex">
          <h6 className="me-2">Filter by</h6>
          <img
                      src={require("../images/filter.png")}
                      alt="Image not found"
                      className="filter-icon"
                    />

        </div> */}
        

<BatchListTable />

</div>
      </div>
    </>
  );
};

export default BatchList;
