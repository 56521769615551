import React from "react";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../styles/AdminProfile.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Sidebar from "./Sidebar";
import httpuserprofile from "../js/http-lms_userprofile"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from 'react-select'
import { Modal } from 'react-bootstrap';
import httpprograms from "../js/http-lms_programs"
import Spinner from 'react-bootstrap/Spinner';


const UpdateUser = (props) => {


  const [disable, setDisable] = useState(true)

  //initial data
  const [countriesList, setCountriesList] = useState([])
  const [statesList, setStatesList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [pop, setPop] = useState(false);
  
 
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [formErros, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const[profileId, setProfileId] = useState()
  const[studentStatusId , setStudentStatusId] = useState()
  const[ExistingTrainerPrograms, setExistingTrainerPrograms] = useState()
  const [loading, setLoading] = useState(0)
  const[role, setRole] = useState()
  const [emailDisable, setEmailDisable] = useState(true);
  const [roleName, setRoleName] = useState()
  const [collegesList, setCollegesList] = useState([])
  const [selectedCollegeNameOption, setSelectedCollegeNameOption] = useState(null);
  const [selectedBatchOption, setSelectedBatchOption] = useState(null);
  const[programDetails, setProgramDetails] = useState()
  const [selectedProgramOption, setSelectedProgramOption] = useState([]);
  const[status, setStatus] = useState()
  const[userId, setUserId] = useState()
  const[StatusDropValue, setStatusDropValue] = useState()
  const [educationList, setEducationList] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [batchList, setBatchList] = useState([])
  // const[userStatus, setUserStatus] = useState(2)
  

  const location = useLocation();

  // console.log(location.state.data,"lc DQAaataa")


  // useEffect(() => {
  //   if(props.tableData) {
  //     console.log(props.tableData,"jojojojojo")
  //     let names = props.tableData.name.split(' ');
  //     if(names.length===3) {
  //       props.tableData.firstName = names[0]
  //       props.tableData.middleName = names[1]
  //       props.tableData.lastName = names[2]
  //     }
  //     else if(names.length===2) {
  //       props.tableData.firstName = names[0]
  //       props.tableData.lastName = names[1]
  //     }
  //     console.log(names, 'nnnnnnnnnnnnn')
  //     setFormData(props.tableData)
  //   }
  // }, [props.tableData])
 
useEffect(() => {
  console.log(formData, '-> Form Data')
  
}, [formData])

useEffect(() => {
  console.log(role, 'ppppppppp')
}, [role])


// useEffect(() => {
//   if(roleName==='Trainer') {
//       setEmailDisable(true)
//   }
//   else {
//     setEmailDisable(false)
//   }
// }, [roleName])



// validate data and enable/disable submit btn
  useEffect(() => {
    if(formData.roleId === 1 || formData.roleId === 3 || formData.roleId === 5 || formData.roleId === 6 || formData.roleId === 7 || formData.roleId === 8){
      if(formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(formData.roleId === 2){
      if(formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && formData.rollNumber && formData.collegeId && formData.yearOfGraduation && formData.educationId && formData.batchId) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(formData.roleId === 4){
      if(formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && selectedProgramOption.length>0) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(formData.roleId === 9){
      if(formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && formData.collegeId) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    
  }, [formData, selectedProgramOption])

  //API call when page loader
  useEffect(() => {
    //fetching countries list
    httpuserprofile
      .get("/userprofileLMS/getUserCountries")
      .then((res) => {
        console.log("user countries", res)
        setCountriesList(res.data)
      })


      httpuserprofile
      .get("/userprofileLMS/getUserBatches")
      .then((res) => {
        console.log("user batches", res)
        if(res.data !== 'No records found'){
        setBatchList(res.data)
        }
      })

  
    //create list of years
    var currentYear = new Date().getFullYear();
    var startYear = currentYear-12;  
    var endyear = currentYear+6;
    var list = []
    while ( startYear <= endyear ) {
      let year = {'year' : endyear--}
      list.push(year);
    }
    setYearsList([]);
    setYearsList(list)


      httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=5")
      .then((res) => {
        console.log("user colleges", res)
        if(res.data !== 'No records found'){
        setEducationList(res.data)
        }
      })

  },[])



  useEffect(() => {
    programCards()
  }, [])


  const programCards =async ()=>{
  
      // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
      httpprograms.get("/programsLMS/getModulesTopicCount")
      .then((response) => {
        console.log("Program Details:", response)
        let data = [];
        response.data.map(x => {
          data.push({
            value: x.program_id,
            label: x.program_name
          })
        })
        console.log(data, 'ppppppppppp')
        setProgramDetails(data)
      }).catch((err)=>{
        console.log(err)
      })

  }


  function getGetTrainerDetails(id){
    httpuserprofile
      .post(`/userprofileLMS/getTrainersDataById?profileId=${id}`)
      .then((res) => {
        console.log("Trainer Details", res)
        console.log("Trainer Programs", res.data[0].programNameListVO)
        let data = [];
        res.data[0].programNameListVO.map(x => {
          data.push({
            value: x.programId,
            label: x.programName
          })
        })
        setSelectedProgramOption(data)
      })
  }


  //updating formdata for final submission
  function handleChange(e) {
    setErrorMessage('')
    const {name, value} = e.target;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    // console.log(formData);
    // setFirstName(e.target.value)
    // setLastName(e.target.value)
  }
  // triggered after changing the country
  // reset selected state and city
  // fetch states list based on selected country
  function handleCountryChange(e){
    console.log("selected country", e)
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e)
    setSelectedStateOption(null);
    setSelectedCityOption(null)
    setStatesList([])
    setCitiesList([])
    formData.stateId = null;
    formData.cityId = null;
    // formData.countryId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["countryId"]: e.countryId
      }
    })

    // handleChange(e)
    httpuserprofile
      .get("/userprofileLMS/getUserStates?countryId="+e.countryId)
      .then((res) => {
        console.log("user states", res)
        setStatesList(res.data)
      })
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function 
  
  handleStateChange(e){
    console.log("selected state", e)
    //reset cities, once changed selected country
    setSelectedStateOption(e)
    setSelectedCityOption(null)
    setCitiesList([])
    // formData.stateId = e.id;
    formData.cityId = null;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["stateId"]: e.stateId
      }
    })

    console.log(formData)
    // handleChange(e)
    httpuserprofile
      .get("/userprofileLMS/getUserCities?stateId="+e.stateId)
      .then((res) => {
        console.log("user cities", res)
        setCitiesList(res.data)
      })
  }

  
  // triggered after changing the city
  function handleCityChange(e) {
    console.log("selected city : ", e)
    setSelectedCityOption(e)
    // formData.cityId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["cityId"]: e.cityId
      }
    })
  }

  function handleProgramChange(e) {
    console.log("Selected Program details:", e)
    setSelectedProgramOption(e)
    let p_id = [];
    e.map(x => {
      p_id.push(x.value)
    })
    // formData.batchId = e.value;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["programId"]: p_id
      }
    })
  }
  
  // final api call to save user information
  // update issubmit data if don't have any errors
  function submitData() {
    setFormErrors(validate(formData))
    setIsSubmit(true)
  }



  
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phonenumberregex = /^[6789]\d{9}$/;
    if(!values.firstName){
      errors.firstName = "First Name is required!"
    }else if(!allowaplaspaces.test(values.firstName)){
      errors.firstName = "First name should contain alphabets and spaces"
    }
    if(!values.lastName){
      errors.lastName = "Last Name is required!"
    }else if(!allowaplaspaces.test(values.lastName)){
      errors.lastName = "Last name should contain alphabets and spaces"
    }
    if(!values.emailId){
      errors.emailId = "Email Id is required!"
    }else if(!emailregex.test(values.emailId)){
      errors.emailId = "Please enter valid email id"
    }
    if(!values.phoneNumber){
      errors.phoneNumber = "Phone Number is required!"
    }else if(!phonenumberregex.test(values.phoneNumber)){
      errors.phoneNumber = "Phone number must be of 10 digits only"
    }
    if(!values.address){
      errors.address = "Address is required!"
    }
    if(!values.cityId){
      errors.cityId = "City is required!"
    }
    if(!values.stateId){
      errors.stateId = "State is required!"
    }
    if(!values.countryId){
      errors.countryId = "Country is required!"
    }
    // formData.rollNumber && formData.collegeName && formData.yearOfGraduation && formData.educationId
    if(formData.roleId == '2'){
      if(!values.rollNumber){
        errors.rollNumber = "Roll Number is required!"
      }
      if(!values.collegeId){
        errors.collegeId = "College Name is required!"
      }
      if(!values.educationId){
        errors.educationId = "Education level is required!"
      }
      if(!values.batchId) {
        errors.batchId = "Please select Batch!"
      }
      if(!values.studentStatusId){
        errors.studentStatusId = "Please select student progress!"
      }
    }
    return errors;
  }

  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({ 
        ...styles, 
     }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };


  function cancelForm(){
    let form = document.getElementById("update-User-Form")
    console.log(formData.firstName);
    if(form){
      form.reset();
    }
    setErrorMessage('')
    setFormData({
      firstName: '',
      middleName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      address: '',
      address1: ''
    })
    setFormErrors({})
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    setStatesList(null)
    setCitiesList(null)
  }

  

  function statusChange(e){
    setStatus(e.target.value)
    console.log(e.target.value)
    console.log("hello")
  }


  

  useEffect(()=>{
    
    // setUId(props.tableData)
    getLoggedInUserDetails()

    httpuserprofile
      .get("/userprofileLMS/getCollgeList")
      .then((res) => {
        console.log("user colleges", res)
        setCollegesList(res.data)
      })
  }, [props.tableData])




  const getLoggedInUserDetails =async ()=>{
    httpuserprofile.get(`/userprofileLMS/getLoggedInUserDetails?userId=${props.tableData.userId}`)
    .then((response) => {
      console.log("UserDetails:", response)
      setLoading(1)
    getGetTrainerDetails(response.data.profileId);
    setStudentStatusId(response.data.studentStatusId)
    setRole(response.data.role.roleId)
    setRoleName(response.data.role.roleName)
    // setActiveStatus(response.data.signUp.activeStatus)
    // console.log(response.data.profileId)
    // console.log(profileId,"knlnlknkln")
    // console.log(studentStatusId,"ooo")
    // console.log(activeStatus, "Active/Inactive status is:")
    // setFirstName(response.data.firstName)
    setProfileId(response.data.profileId)
    setSelectedCountryOption(response.data.country)
    setSelectedBatchOption(response.data.batch)
      setSelectedCityOption(response.data.city)
      setSelectedStateOption(response.data.state)
      setSelectedCollegeNameOption(response.data.collegeDetails)
      setStatus(response.data.signUp.activeStatus)
      setUserId(response.data.signUp.userId)
    setFormData(response.data)
    console.log(response.data.country.countryName)
    }).catch((err)=>{
      console.log(err)
    })
  }

  // const navigate = useNavigate();

  // function handleClick() {
  //   let path = localStorage.getItem("path");
  //   localStorage.setItem("path", window.location.pathname);
  //   navigate(path);
  // }


  function handleCollegeNameChange(e) {
    console.log("selected college name : ", e)
    setSelectedCollegeNameOption(e)
    // formData.collegeId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["collegeId"]: e.collegeId
      }
    })
  }


  const changeStatus=()=>{
    httpuserprofile.put('userprofileLMS/updateUserStatus?userId='+userId+'&status='+status)
    .then((response) => {
      console.log("after update user status : ", response.data)
    }).catch((e) => {
      console.log(e.response)
    }) 

}


  useEffect(() => {
    if(Object.keys(formErros).length === 0 && isSubmit){
      setSubmitLoading(true)
      httpuserprofile.put("/userprofileLMS/updateProfileDetails", {
        "profileId": profileId,
        "firstName": formData.firstName,
        "middleName": formData.middleName,
        "lastName": formData.lastName,
        "emailId": formData.emailId,
        "roleId": sessionStorage.getItem("roleId"),
        "phoneNumber": formData.phoneNumber,
        "address": formData.address,
        "address1": formData.address1,
        "cityId": formData.cityId,
        "stateId": formData.stateId,
        "countryId": formData.countryId,
        "batchId": "",
        "rollNumber": formData.rollNumber,
        "collegeId": formData.collegeId,
        "yearOfGraduation": "",
        "educationId": "",
        "studentStatusId": studentStatusId
      })
      .then((response) => {
        console.log("Update user profile data : ", response)
        changeStatus()
        setSubmitLoading(false)
        if(response.data){
          setPop(true)
        setTimeout(() => {
          setPop(false)
          props.setShow(false)
        }, 1500); 
        }
        setErrorMessage(response.data)
      }).catch((e) => {
        console.log("error updating user : ", e.response)
        setSubmitLoading(false)
        setIsSubmit(false);
      })
    }
  }, [formErros])


  function handleUpdate(e){
    e.preventDefault();
      setFormErrors(validate(formData))
      setIsSubmit(true)
console.log("Submission Done")
  }



  


  // "profileId": profileId,
  //     "firstName": formData.firstName,
  //     "middleName": formData.middleName,
  //     "lastName": formData.lastName,
  //     "emailId": formData.emailId,
  //     "roleId": sessionStorage.getItem("roleId"),
  //     "phoneNumber": formData.phoneNumber,
  //     "address": formData.address,
  //     "address1": formData.address1,
  //     "cityId": formData.cityId,
  //     "stateId": formData.stateId,
  //     "countryId": formData.countryId,
  //     "batchId": "",
  //     "rollNumber": "",
  //     "collegeId": "",
  //     "yearOfGraduation": "",
  //     "educationId": "",
  //     "studentStatusId": studentStatusId


  function handleUpdateTrainer(e){
    e.preventDefault();
    setSubmitLoading(true)
    httpuserprofile.put("/userprofileLMS/updateTrainer", {
      "programId":formData.programId,
   "creationUserInfo":sessionStorage.getItem("userEmail"),
   "userProfileVO":{
        "profileId":profileId,
        "firstName":formData.firstName,
        "middleName":formData.middleName,
        "lastName":formData.lastName,      
        "phoneNumber":formData.phoneNumber,
        "address":formData.address,
        "address1":formData.address1,
        "cityId":formData.cityId,
        "stateId":formData.stateId,
        "countryId":formData.countryId
   }
    })
    .then((response) => {
      console.log("Updated Trainer Profile data : ", response)
      changeStatus()
      setSubmitLoading(false)
      if(response.data){
        setPop(true)
        setTimeout(() => {
          setPop(false)
          props.setShow(false)
        }, 1500);
      }
      setErrorMessage(response.data)
    }).catch((e) => {
      console.log("error updating trainer : ", e.response)
      setSubmitLoading(false)
      setIsSubmit(false);
    })
    console.log(formData, "kkkkkoooooo")
  }



  


  

  const handleClose = () => {
  
    props.setShow(false);
    let form = document.getElementById("update-User-Form")
    console.log(formData.firstName);
    if(form){
      form.reset();
    }
    setErrorMessage('')
    setFormData({
      firstName: '',
      middleName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      address: '',
      address1: ''
    })
    setFormErrors({})
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    setStatesList(null)
    setCitiesList(null)
  }

 


  return (
    <>
    {pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully user has been updated</p>
      </div>
    </div>}
      
    <Modal
        show={props.show}
        onHide={handleClose}
        className="modal-100"
        dialogClassName={`modal-left h-100 mt-0 ${props.show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Update User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* <div className=" d-flex align-items-center ms-4 mt160">
          <img
            onClick={handleClick}
            className="pe-3 backarrow"
            src={require("../images/leftarrow.png")}
            alt="Image not found"
          />
          <h4 className="mt-2">Back</h4>
        </div> */}

        {/* <div className=" bgwhite shadow mt-4 mb-5 mx-1 mx-md-4">
          <div className="d-flex justify-content-start pt-5"> */}
            {/* <div className="admin-pro-pic"></div> */}

            {/* <div className="d-flex justify-content-center align-items-center">
              <h2 className="ms-5"><b>{location.state.data.name}</b></h2>
            </div>
          </div> */}

          <div className="form-container h-100">

          {loading===0 ? <div className=" h-100 d-flex  bgwhite justify-content-center align-items-center mb-3 pb-5 "> <Spinner animation="border" variant="secondary" /></div>:
           
          (role === 1 || role === 2 || role === 4 || role === 9 || role === 3 || role === 5 || role === 6 || role === 7 || role === 8 ) && (
<Form id="update-User-Form" className="h-100">
<div className='scrollForm'>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                     <div className="d-flex required"><h6>First Name</h6></div>
                      <Form.Control
                        className={formErros.firstName ? "borderred" : ""}
                        placeholder="Enter first name"
                        value={formData.firstName}
                        autoComplete="off"
                        name='firstName'
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.firstName}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <h6>Middle Name</h6>
                      <Form.Control
                        className=""
                        placeholder="Enter middle name"
                        value={formData.middleName}
                        autoComplete="off"
                        name='middleName'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Last Name</h6></div>
                      <Form.Control
                        className={formErros.lastName ? "borderred" : ""}
                        placeholder="Enter last name"
                        autoComplete="off"
                        name='lastName'
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.lastName}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex"> <h6>Email</h6></div>
                      <Form.Control
                       disabled={emailDisable}
                        className={formErros.emailId ? "borderred" : ""}
                        placeholder="Enter email address"
                        autoComplete="off"
                        name='emailId'
                        value={formData.emailId}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.emailId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Phone</h6></div>
                      <Form.Control
                        className={formErros.phoneNumber ? "borderred" : ""}
                        placeholder="Enter mobile"
                        autoComplete="off"
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        maxLength={10}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.phoneNumber}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                     <div className="d-flex required"> <h6>Address 1</h6></div>
                      <Form.Control
                        className={formErros.address ? "borderred" : ""}
                        placeholder="Street address"
                        autoComplete="off"
                        name='address'
                        value={formData.address}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.address}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <h6>Address 2</h6>
                      <Form.Control
                        className=""
                        placeholder="House no."
                        autoComplete="off"
                        name='address1'
                        value={formData.address1}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>Country</h6></div>
                     
                      <Select
                        // value={{value: selectedCountryOption, label: selectedCountryOption}}
                        value={selectedCountryOption}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.countryName}`
                        }
                        options={countriesList}
                      />
                      <p className="red font12">{formErros.countryId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>State</h6></div>
                      <Select
                        value={selectedStateOption}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.stateName}`
                        }
                        options={statesList}
                      />
                      <p className="red font12">{formErros.stateId}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>City</h6></div>
                      <Select
                        value={selectedCityOption}
                        onChange={handleCityChange}
                        placeholder="Select City"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.cityName}`
                        }
                        options={citiesList}
                      />
                      <p className="red font12">{formErros.cityId}</p>
                    </Col>
                    </Row>


                  {role === 2 && ( 

                    <>
           <Row className="pb-3">
           <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
           <div className="d-flex required"><h6>Roll Number</h6></div>
             <Form.Control
               className={formErros.rollNumber ? "borderred" : ""}
               placeholder="Roll no."
               name='rollNumber'
               autoComplete="off"
               defaultValue={formData.rollNumber}
               onChange={handleChange}
             />
             <p className="red font12">{formErros.rollNumber}</p>
           </Col>
           <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
           <div className="d-flex required"> <h6>College Name</h6></div>
             <Select
               value={selectedCollegeNameOption}
               onChange={handleCollegeNameChange}
               placeholder="Select College"
               className="multiselectdropdown"
               classNamePrefix="react-select"
               styles={colourStyles}
               defaultValue={formData.collegeDetails}
               getOptionLabel={option =>
                 `${option.collegeName}`
               }
               options={collegesList}
             />
             <p className="red font12">{formErros.collegeId}</p>
           </Col>
         </Row>  


         <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Year of Graduation</h6></div>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.yearOfGraduation ? "borderred form-select" : "form-select"}
                        name="yearOfGraduation"
                        aria-label="Default select example"
                        value={formData.yearOfGraduation}
                        defaultValue={formData.yearOfGraduation}
                        // style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Graduation  Year</option>
                        {yearsList.map((year, index) => {
                          return (
                            <option value={year.year} key={index}>{year.year}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.yearOfGraduation}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <h6 className="required">Education Level</h6>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.educationId ? "borderred form-select" : "form-select"}
                        name="educationId"
                        aria-label="Default select example"
                        value={formData.educationId}
                        defaultValue={formData.educationId}
                        // style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Education Level</option>
                        {educationList.map((degree, index) => {
                          return (
                            <option value={degree.indexCode} key={degree.indexCode}>{degree.indexDescription}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.educationId}</p>
                    </Col>
                  </Row>


                  <Row className="pb-3">
                    <Col className="col-lg col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Batch</h6></div>
                      <Select
                        value={selectedBatchOption}
                        placeholder="Select Batch"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        defaultValue={formData.batch}
                        getOptionLabel={option =>
                          `${option.batchName}`
                        }
                        options={batchList}
                      />
                      </Col>
                      </Row>



</>

  )}




                  
                  {role === 4 && ( 
              <Row>     
  <Col className="col-lg-6 pb-3 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>Select Program</h6></div>
                      <Select
                         value={selectedProgramOption}
                         onChange={handleProgramChange}
                         placeholder="Select Program"
                         className="multiselectdropdown"
                         classNamePrefix="react-select"
                         styles={colourStyles}
                         isMulti
                         options={programDetails}
                      />
                      {/* <p className="red font12">{formErros.collegeId}</p> */}
                    </Col>
                      </Row>)}

                      {role === 9 && ( 
              <Row>     
  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
  <div className="d-flex required"> <h6>College Name</h6></div>
                    <Select
                        value={selectedCollegeNameOption}
                        onChange={handleCollegeNameChange}
                        placeholder="Select College"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.collegeName}`
                        }
                        options={collegesList}
                      />
                      <p className="red font12">{formErros.collegeId}</p>
                    </Col>
                      </Row>)}


                      <Row>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mb-2">
                      <h6 className="required">Student Status</h6>
                      <Form.Select
                      value={status}
                        onChange={statusChange}
                        name="status"
                        aria-label="Default select example"
                      >
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                    
                      </Form.Select>
                      {/* <p className="red font12">{formErros.studentStatusId}</p> */}
                    </Col>
                   
                  </Row>

         </div>
         <div className="col-lg-12 px-3 fixed-bottom">
                 
                  {submitLoading ? <div className="col-lg-6 col-12 mt-3 mb-5 pb-4 text-center">
                  <Spinner className="mx-auto" animation="border" variant="secondary"/>
                  </div> : 
                  <div className="d-flex">
                      <button onClick={role === 1 || role === 2 || role === 9 || role === 3 || role === 5 || role === 6 || role === 7 || role === 8  ? handleUpdate : handleUpdateTrainer} disabled={disable} className={ disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 UpdatedMainBGColor"}>Update</button>
                  <button className=" ms-5 cancelUser mt-3 mb-5" onClick={cancelForm}>Cancel</button>
                  </div>
                  }
                  </div>
                      </Form>)

}
                  
          </div>
          </Modal.Body>
      </Modal>
        
      
    </>
  );
};

export default UpdateUser;
