import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpdashboard from "../js/http-lms_dashboard"
import ReactPaginate from 'react-paginate';
import BatchListTable from "../components/BatchListTable";
import LeftToRightModal from "../components/LeftToRightModal";
import profile from '../images/profile.svg';
import home from '../images/home.svg';
import batches from '../images/batches.svg';
import suscription from '../images/suscription.svg';
import assignments from '../images/assignments.svg';
import programs from '../images/programs.svg';
import httpprograms from "../js/http-lms_userprofile"
import users from '../images/users.svg';
import { useNavigate} from "react-router-dom";



const Dashboard = () => {

  const [test, setTest] = useState([])

  const [currentData, setCurrentData] = useState([]);

  const [dataPerPage, setDataPerPage] = useState(10)

  const [totalPages, setTotalPages] = useState()

  const [pagination, setPagination] = useState([]);

// const [totalstudent, SetTotalStudent] = useState()

const [newStudents, SetNewStudents] = useState()

const [courseData, setCourseData] = useState()

const [newCourseData, setNewCourseData] = useState()

const[error, setError] = useState()

const[error2, setError2] = useState()

  const [loading, setLoading] = useState(0)

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  const[refresh, setRefresh] = useState(true)

   const[totalStudent, SetTotalStudent] = useState();

   const[programsCount, setProgramsCount] = useState();

   const[batchesCount, setBatchesCount] = useState();

   const[trainersCount, setTrainersCount] = useState();

   const[collegeSubscriptionCount, setCollegeSubscriptionCount] = useState();


   const navigate = useNavigate();

   function handleChangeStudent() {
    localStorage.setItem("path", window.location.pathname+'/student');
    navigate("/manageusers");
  }

  function handleChangePrograms() {
    navigate("/programs");
  }

  function handleChangeBatches() {
    navigate("/batchlist");
  }

  function handleChangeTrainers() {
    localStorage.setItem("path", window.location.pathname+'/trainer');
    navigate("/manageusers");
  }





  useEffect(() => {
    for(let i=0; i<500; i++){
      test.push({
        BatchName:`batch${i}`,
        Programs:"7",
        Sdate:"1 Dec",
        Edate:"24 Dec",
        Status:"n/a",
        tStudents:"78",
        Placed:"40"
      })
    }
    console.log(test);
  }, [])

  useEffect(() => {
    setTotalPages(Math.round(test.length/dataPerPage))
  }, [test])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset])

  useEffect(() => {
    setCurrentItems(test.slice(itemOffset, endOffset))
  }, [endOffset])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % test.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };


        useEffect(()=>{
          // card1()
          // card2()
          allCards()
        },[])


        function allCards(){
          httpprograms.get('/userprofileLMS/getDashboardCount')
          .then((response) => {
            if(response.data=="No records found"){
              setLoading(1)
              console.log("API Values DCards : : ", response.data)
              setError(response.data)
            }
            else{
            setLoading(1)
            console.log("API Values DCards: : ", response.data)
            SetTotalStudent(response.data.studentsCount)
            setProgramsCount(response.data.programsCount)
            setBatchesCount(response.data.batchesCount)
            setTrainersCount(response.data.trainersCount)
            setCollegeSubscriptionCount(response.data.collegeSubscriptionCount)
          }
          }).catch((e) => {
            console.log(e.response)
          }) 
        }


        //  function card1(){
        //   httpdashboard.get('/dashBoardLMS/getAllIndividualStudentCount')
        //   .then((response) => {
        //     if(response.data=="No records found"){
        //       setLoading(1)
        //       console.log("API Values C1: : ", response.data)
        //       setError(response.data)
        //     }
        //     else{
        //     setLoading(1)
        //     console.log("API Values C1: : ", response.data)
        //     SetTotalStudent(response.data.totalStudents)
        //     setCourseData(Object.entries(response.data.studentCountForEachProgram))
        //   }
        //   }).catch((e) => {
        //     console.log(e.response)
        //   }) 
        // }

        // function card2(){
        //   httpdashboard.get('/dashBoardLMS/getNewBatchStudentCount')
        //   .then((response) => {
        //     if(response.data=="No records found"){
        //       setLoading(1)
        //       console.log("API Values C2: : ", response.data)
        //       setError2(response.data)
        //     }
        //     else{
        //     setLoading(1)
        //     console.log("API Values C2: : ", response.data)
        //     SetNewStudents(response.data.totalStudents)
        //     setNewCourseData(Object.entries(response.data.studentCountForEachProgram))
        //     }
        //   }).catch((e) => {
        //     console.log(e.response)
        //   }) 
        // }


  return (
    <>
      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4">

        <div className="w-100 h-100 position-relative">

        {/* <Navb /> */}

        {/* Sect 1 of Dashboard */}    

<div className="px-md-5 mt160">
        <div className="container-fluid mb-5 dashboard-cards">

<div className="col-lg-12">
  <div className="row">

    {loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded shadow justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /></div> :

    <div onClick={handleChangeStudent} className="d-flex bgwhite pointer dashclick col-lg col-12 py-4 px-3 rounded shadow justify-content-between">
      <div className="d-flex flex-column ps-3">
        <h6>Students</h6>
        <h2 className="py-3">{totalStudent}</h2>
        <h6 className="grey2 font14">Total no. of Students</h6>
      </div>
      <div>
        <div className="circleIcon me-3">
      <img src={profile} alt="Image not found" className=''/>
      </div>
      </div>
    </div>

}

{loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 my-lg-0 my-3 mx-lg-5 px-3 rounded shadow justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /></div> :
    <div onClick={handleChangePrograms} className="d-flex bgwhite pointer dashclick col-lg col-12 py-4 my-lg-0 my-3 mx-lg-5 px-3 rounded shadow justify-content-between">
      <div className="d-flex flex-column ps-3">
        <h6>Programs</h6>
        <h2 className="py-3">{programsCount}</h2>
        <h6 className="grey2 font14">Total no. of Programs</h6>
      </div>
      <div>
        <div className="circleIcon me-3">
      <img src={programs} alt="Image not found" className=''/>
      </div>
      </div>
    </div>
}



{loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded shadow justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /></div> :
    <div onClick={handleChangeBatches} className="d-flex bgwhite pointer dashclick col-lg col-12 py-4 px-3 rounded shadow justify-content-between">
      <div className="d-flex flex-column ps-3">
        <h6>Batches</h6>
        <h2 className="py-3">{batchesCount}</h2>
        <h6 className="grey2 font14">Total no. of Batches</h6>
      </div>
      <div>
        <div className="circleIcon me-3">
      <img src={batches} alt="Image not found" className=''/>
      </div>
      </div>
    </div>
}


    
  </div>


  <div className="row mt-lg-5 mt-3">


{loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded shadow justify-content-center align-items-center">  <Spinner animation="border" variant="secondary" /> </div> :
<div onClick={handleChangeTrainers} className="d-flex bgwhite pointer dashclick col-lg col-12 py-4 px-3 rounded shadow justify-content-between">
  <div className="d-flex flex-column ps-3">
    <h6>Trainers</h6>
    <h2 className="py-3">{trainersCount}</h2>
    <h6 className="grey2 font14">Total no. of Trainers</h6>
  </div>
  <div>
    <div className="circleIcon me-3">
  <img src={users} alt="Image not found" className=''/>
  </div>
  </div>
</div>
}

{loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 mx-lg-5 my-lg-0 my-3 px-3 rounded shadow justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /> </div> :
<div className="d-flex bgwhite col-lg col-12 py-4 mx-lg-5 my-lg-0 my-3 px-3 rounded shadow justify-content-between">
  <div className="d-flex flex-column ps-3">
    <h6>Individual Subscriptions</h6>
    <h2 className="py-3">0</h2>
    <h6 className="grey2 font14">Total no. of Individual Subscriptions</h6>
  </div>
  <div>
    <div className="circleIcon me-3">
  <img src={suscription} alt="Image not found" className=''/>
  </div>
  </div>
</div>
}

{loading === 0 ? <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded shadow justify-content-center align-items-center ">  <Spinner animation="border" variant="secondary" /> </div> :
<div className="d-flex bgwhite col-lg col-12 py-4 px-3 rounded shadow justify-content-between ">
  <div className="d-flex flex-column ps-3">
    <h6>College Subscriptions</h6>
    <h2 className="py-3">{collegeSubscriptionCount}</h2>
    <h6 className="grey2 font14">Total no. of College Subscriptions</h6>
  </div>
  <div>
    <div className="circleIcon me-3">
  <img src={suscription} alt="Image not found" className=''/>
  </div>
  </div>
</div>
}

</div>

</div>

        </div>


      
        {/* <div className="d-flex justify-content-between">
        <h1 className="mb-4">Batches List</h1>
        <div className="mb-4"><LeftToRightModal refresh={refresh} setRefresh={setRefresh} /></div>
        </div>

        <BatchListTable refresh={refresh} /> */}

</div>

</div>
      </div>
    </>
  );
};

export default Dashboard;
