import { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import CircularSlider from '@fseehawer/react-circular-slider';
import httpassignments from '../../js/http-lms_assignments'
import httpreviews from '../../js/http-lms_userprofile'
import NoRecFound from "../../components/NORecFound";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

function MyVerticallyCenteredModal(props) {

  const [char, setChar] = useState(200)

  function changeFeedback(e) {
    props.setFeedback(e.target.value)
    setChar(200-e.target.value.length)
  }

    return (
      <Modal
      className="modal-100"
        {...props}
        onHide={() => {
          props.onHide();
          props.setSliderVal('0')
          props.setFeedback('')
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Feedback & Score
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="d-flex justify-content-between ">
              <Form.Label>Add Score</Form.Label>
              <h4 className="scoreBorder p-1 rounded-1">{props.sliderVal}%</h4>
              </div>
              <input type="range" onChange={props.handleChange} value={props.sliderVal} className="w-100" id="points" name="points"  min="0" max="100"></input>
              {/* <Form.Control
                type="number"
                autoFocus
                maxLength={100}
                onChange={(e) => props.score(e.target.value)}
              /> */}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Add Feedback ( Max {char} characters )</Form.Label>
              <Form.Control as="textarea" maxLength={200} rows={3} value={props.feedback} onChange={changeFeedback} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="w-100" disabled={props.disable} onClick={() => {
            props.onHide();
            props.reviews();
            props.setSliderVal('0')
            props.setFeedback('')
          }}>Update</Button>
        </Modal.Footer>
      </Modal>
    );
  }

const ReceivedAssignments = () => {

  const [score, setScore] = useState('')
  const [feedback, setFeedback] = useState('')
  const [studentTrackerId, setStudentTrackerId] = useState()

    //data
    const [loading, setLoading] = useState(true)
    const [receivedAssignmentsList, setReceivedAssignmentsList] = useState([])
    const [modalShow, setModalShow] = React.useState(false);

    const [disable, setDisable] = useState();
    const [sliderVal, setSliderVal] = useState('0');
    const [reviewsRefresh, setReviewsRefresh] = useState(0);

    const [dataPerPage, setDataPerPage] = useState(20)

  const [totalPages, setTotalPages] = useState()

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setTotalPages(Math.round(receivedAssignmentsList.length/dataPerPage))
  }, [receivedAssignmentsList])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset, receivedAssignmentsList])

  useEffect(() => {
    setCurrentItems(receivedAssignmentsList.slice(itemOffset, endOffset))
    console.log(receivedAssignmentsList.slice(itemOffset, endOffset), 'll')
  }, [endOffset, receivedAssignmentsList])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % receivedAssignmentsList.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };    

  
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  
    useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });


    const handleDataPerPageChange = (event) => {
      console.log(event.target.value);
      if (event.target.value === "all") {
        setDataPerPage(receivedAssignmentsList.length);
      } else {
        setDataPerPage(parseInt(event.target.value));
      }
    };



    

    function handleChange(e){
      setSliderVal(e.target.value)
    }

    useEffect(() => {
      if(feedback) {
        if(feedback.length>0) {
          setDisable(false)
        }
        else {
          setDisable(true)
        }
      }
    }, [feedback])

    //API call when page loader
    useEffect(() => {
      //fetching recieved assignment list
     getRecievedAssignments()
    },[reviewsRefresh])


    function getRecievedAssignments(){
      setLoading(true)
      httpassignments
        .get(`/api/v1/topicAssignmentLMS/lmsReceivedAssignmentsList?RoleId=1&UserId=${sessionStorage.getItem('userEmail')}`)
        .then((res) => {
          console.log("recieved assignments", res)
          setReceivedAssignmentsList(res.data)
          setLoading(false)
        }).catch((e) => {
          console.log(e)
          setLoading(false)
        })
    }


    function addReviews(){
      httpreviews
        .put(`/userprofileLMS/updateStudentScore?studentTrackerId=${studentTrackerId}&score=${sliderVal}&feedBack=${feedback}`)
        .then((res) => {
          console.log("Reviews are:", res)
          setPop(true)
        setTimeout(() => {
          setPop(false)
        }, 1200, setReviewsRefresh(reviewsRefresh+1) );
        }).catch((e) => {
          console.log(e)
        })

    }


    const [pop, setPop] = useState(false);
  


      
  return (
    <>
    <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        score={setScore}
        setFeedback={setFeedback}
        feedback={feedback}
        reviews={addReviews}
        dialogClassName={`modal-left h-100 mt-0 ${modalShow ? 'fade-left' : 'fade-right'}`}
        disable={disable}
        handleChange={handleChange}
        sliderVal={sliderVal}
        setSliderVal={setSliderVal}
      />
    {/* status update popup */}
    
    {pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully reviews has been updated</p>
      </div>
    </div>}


    {loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (sortedData.length > 0) ? 
        <div className="scrollme">
          <div className="d-flex bgwhite px-4 bottomborder">  <select className="my-4 px-1 rounded borderUpated me-5" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value="all">All</option>
      </select>
      <div className="d-flex my-4">
      <DatePicker className='w-100 rounded heightDate' selectsEnd
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2">-</h3>
      <DatePicker className='w-100 rounded heightDate' selectsEnd
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
      </div>
      </div>
          <table className="table bgwhite">
                <thead>
                    <tr>
                        <th scope="col">Student Name <img onClick={() => handleSort('studentName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Topic Name <img onClick={() => handleSort('topicName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Batch Name <img onClick={() => handleSort('batchName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assignment Name <img onClick={() => handleSort('assignmentName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        {/* <th scope="col">Mail Id</th> */}
                        <th scope="col">Start Date <img onClick={() => handleSort('startDate')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">End Date <img onClick={() => handleSort('endDate')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Submited Date <img onClick={() => handleSort('assignmentSubmisionDate')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Score <img onClick={() => handleSort('score')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((data, index) => {
                        return (
                            <tr key={index+'tr'}>
                                <td scope="row">{data.studentName}</td>
                                <td>{data.topicName}</td>
                                <td>{data.batchName}</td>
                                <td>{data.assignmentName ? data.assignmentName : '--'}</td>
                                {/* <td>{data.emailId}</td> */}
                                <td>{moment(data.startDate).format('MM/DD/YYYY')}</td>
                                <td>{moment(data.endDate).format('MM/DD/YYYY')}</td>
                                <td>{moment(data.assignmentSubmisionDate).format('MM/DD/YYYY')}</td>
                                <td>{data.score>0 ? data.score + '%' : '-'}</td>
                                <td className="">
                                    <Dropdown>
                                        <Dropdown.Toggle className="dropdown-more p-0 pb-1" variant="success" id="dropdown-basic">
                                            <img src={require("../../images/more.png")} alt="Image not found" className=""/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>{window.open(data.s3PresignedAssignmentLink)}} style={{color:"#545454"}} value="Edit" href="#/action-1"><img
                                                className="more-assignment-hover me-1 mb-1" 
                                                src={require("../../images/recievedimg1.png")}
                                                alt="Image not found"
                                            />View Submitted Assignment</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setModalShow(true);
                                                setStudentTrackerId(data.studentAssignmenttrackerId);
                                               data.score===null ? setScore('0') : setSliderVal(data.score);
                                               data.feedback===null ? setFeedback('') : setFeedback(data.feedBack);
                                            }} style={{color:"#545454"}} href="#/action-2" value="Active"><img 
                                                src={require("../../images/recievedimg3.png")}
                                                alt="Image not found"
                                                className="more-assignment-hover me-1 mb-1"
                                            />Add Score & Feedback</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
          </table>
          <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
        </div>
        : <div className='col-12 text-center'>
          <NoRecFound error="No assignments found" />
        </div>
      }</div>
    }
    </>
  )
}

export default ReceivedAssignments
