import React from "react";
import Sidebar from "./Sidebar";
import "../styles/AdminProfile.css";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import Accordion from "react-bootstrap/Accordion";
import {DefaultPlayer as Video} from "react-html5video"
import "react-html5video/dist/styles.css"
import thum from "../images/vid2.png"
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from 'axios';
import httpuserprofile from "../js/http-lms_userprofile"
import Spinner from 'react-bootstrap/Spinner';



const ProgramDetails = () => {

  const [data, setData] = useState([])

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

        function handleClick() {
                navigate("/programs");
              }

              useEffect(() => {
                const courseName = sessionStorage.getItem("courseName");
                programDetails(courseName)
                console.log(data.moduleName)
              }, [])

            //   const programDetails =async ()=>{
      
            //     const response=await axios.get("http://34.199.176.17:13051/userprofileLMS/getAllCourseWithModulesAndTopics?programName=DataScience with Python_course")
            //     setData(response.data)
            //     console.log(response)
      
            // }


            function programDetails(course) {
              setLoading(true)
            httpuserprofile.get(`/userprofileLMS/getAllCourseWithModulesAndTopics?programName=${course}`)
            .then((response) => {
              console.log("program details : ", response.data)
              setData(response.data)
              setLoading(false)
            })

            }
  return (
    <>
      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4 right-container">
        <Navb />

        <div className="container-fluid ms-5">
          <div className="row mt-4">
            <div className="col-lg-12 d-flex">
              <div className=" d-flex align-items-center ms-4">
                <img onClick={handleClick}
                  className="pe-3 backarrow"
                  src={require("../images/leftarrow.png")}
                  alt="Image not found"
                />
                <h4 className='mt-2'>{sessionStorage.getItem("courseName")}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid scroll-container">
          <div className="row mt-3 h-100 justify-content-center">
            <div className="col-lg-5 h-100 mt-3 scroll-section">
            <div className="bgwhite fit-content-h">

            <h4 className="pt-4 pb-2 ps-3" style={{ fontWeight: "350" }}>
                    Course Completion
                  </h4>
                  <div className="d-flex mt-2 ps-3 mb-4">
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={100}
                    />
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={100}
                    />
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={0}
                    />
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={0}
                    />
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={0}
                    />
                    <ProgressBar
                      className="progress-bar-0 rounded-3 me-2"
                      now={0}
                    />
                    <ProgressBar className="progress-bar-0 rounded-3" now={0} />
                    </div>

              <Accordion className="">
              {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
                data.map((res,index)=>{
                  return(

                    <Accordion.Item
                    className="programdetails-accordian my-3"
                    eventKey={index}
                  >
                    <Accordion.Header><div className="d-flex"><h5 className="me-4 ms-2 moduleCount d-flex justify-content-center align-items-center">{index+1}</h5><h5 className="mt-2">{res.moduleName}</h5></div></Accordion.Header>
                    <Accordion.Body>
                      {res.topicName.map((topicname,i)=>{
                        return(
                          <div className="d-flex">
                         <h6 className="me-4 ms-2 mt-2 submoduleCount d-flex justify-content-center align-items-center">{index+1}.{i+1}</h6> <h6 className="mt-3">{topicname}</h6>
                          </div>
                        )
                      })}
                    </Accordion.Body>
                  </Accordion.Item>

                  )
                  

                }) }
              
              </Accordion>
            </div>
            </div>

            <div className="col-lg-6 mt-3 d-flex flex-column align-items-start bgwhite scroll-section h-100">
            <Video loop
poster={thum}
>
  
</Video>
              <h5 className="my-4">Variables</h5>
              <h6>Description</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <div className="row my-4">
                <div className="col-lg-6 d-flex flex-column align-items-start">
                  <h6>Assignment Details</h6>
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>

                <div className="col-lg d-flex flex-column p-3 borderoutline mx-lg-5 mt-4 ms-2 rounded-top">
                  <h6>Deadline</h6>
                  <h5>15 Oct 22</h5>
                  <h5>12:00 PM (IST)</h5>
                </div>
              </div>

              {/* <h6>Exhibit File</h6>
              <h6>Premative Assignmentm 1.pdf</h6>

              <div className="d-flex flex-column align-items-center mx-auto border-file-upload mt-4">
                <img
                  src={require("../images/upload.png")}
                  alt="Image not found"
                  className=""
                />
                <h6 className="my-3">Exhibit File</h6>
                <button className="profile-buttons">Upload</button>
              </div> */}

              <div className="d-flex justify-content-between py-4 w-100">
                <div className="d-flex">
                  <img
                    src={require("../images/Previous.png")}
                    alt="Image not found"
                    className="me-1"
                  />
                  <h6 className="mb-0 d-flex align-items-center">Previous</h6>
                </div>
                <div className="d-flex">
                  <h6 className="mb-0 d-flex align-items-center">Next</h6>
                  <img 
                    src={require("../images/Next.png")}
                    alt="Image not found"
                    className="ms-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramDetails;
