import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from "react-router-dom";
import BatchDetailsTable from "../components/BatchDetailsTable";
import { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import httpuserprofile from "../js/http-lms_userprofile"
import moment from "moment/moment";
import {useLocation} from 'react-router-dom';
import NoRecFound from "../components/NORecFound";

const BatchDetails = () => {

  const [loading, setLoading] = useState(true)
  const [arr, setArr] = useState([])
  const [checked, setChecked] = useState([])
  const [disable, setDisable] = useState(true);
  const [data, setData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [img, setImg] = useState("grey");
  const [err, setErr] = useState()

  const location = useLocation();

    useEffect(() => {
      console.log(checked.length);
      if(checked.length>0) {
        setDisable(false)
      }
      else {
        setDisable(true)
      }
    }, [checked])

    useEffect(() => {
      console.log(data, 'llllllllllll');
    }, [data])


   function handleCheck(e) {
    const value = e.target.value;
    if(e.target.checked===true) {
      arr.push(value)
    }
    else {
      arr.splice(value, 1)
    }
    let array = arr
    setChecked(arr)
   }


//    useEffect(() => {

//     if(document.getElementById('barrow1'))
//     {
//          document.getElementById('barrow1').addEventListener('mouseenter', mouseInImg)
//          document.getElementById('barrow1').addEventListener('mouseleave', mouseOutImg)
//     }
// else (document.getElementById('barrow2'))
// {
//          document.getElementById('barrow2').addEventListener('mouseenter', mouseInImg)
//          document.getElementById('barrow2').addEventListener('mouseleave', mouseOutImg)
// }
  
//    }, [])


  function mouseInImg() {
    setImg("blue");
  }

  function mouseOutImg() {
    setImg("grey");
  }

  const navigate = useNavigate();

  function handleClick(){
    navigate(location.state.path)
  }

  const [pop, setPop] = useState(false);

  function formPop(e) {
    e.preventDefault();
    setPop((pValue) => !pValue);
  }

  useEffect(()=>{
    setData([]);
    getStudentData();
    batchPrograms();
  }, [])

  async function getStudentData() {
  
  httpuserprofile.get(`/userprofileLMS/getUsersListByBatchId?batchId=${sessionStorage.getItem('batchId')}&studentName=`)
    .then((result) => {
      console.log("batch details :", result)
      if(result.data==="No records found"){
        setLoading(false)
        setErr(result.data)
      }
      else{ result.data.map((res) => {
        setData((pValue) => {
          return [...pValue,
            {
              name: res.userProfile.firstName,
              mailId: res.userProfile.emailId,
              JoinedDate: res.userProfile.creationDate,
              programName: res.programName,
              programStatus: res.programStatus,
              placedStatus: res.placedStatus,
          }]
        })
      })
      setLoading(false)}
     
    })
  }
  

  function batchPrograms(){
    httpuserprofile.get(`/userprofileLMS/getProgramNamesByBatchId?batchId=${sessionStorage.getItem('batchId')}`)
    .then((response) => {
      console.log("Batch Programs : ", response.data)
      setPrograms(response.data)
    }).catch((e) => {
      console.log(e.response)
    }) 
  }

  return (
    <>
      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4">
        <Navb />



<div className="px-md-5 mt160">

        <div className="container-fluid batch-dates">
          <div className="row d-flex flex-column flex-sm-row justify-content-between mt-4">
              <div className="col-2 d-flex align-items-center me-5">
              {img === 'grey' &&
                <img onClick={handleClick} onMouseEnter={mouseInImg}
                  className="pe-3 backarrow"
                  src={require("../images/leftarrow.png")}
                  alt="Image not found"
                  id="barrow1"
                />}
                {img === 'blue' &&
                <img onClick={handleClick} onMouseLeave={mouseOutImg}
                  className="pe-3 backarrow"
                  src={require("../images/leftarrow-blue.png")}
                  alt="Image not found"
                  id="barrow2"
                />}
                <h4 className="mt-2">{sessionStorage.getItem("batchname")}</h4>
              </div>

              <div className="col-2 d-flex align-items-center mt-2 mx-sm-5">
                <h6 className="pe-2">Start Date:</h6>
                <h5>{sessionStorage.getItem("sDate")}</h5>
              </div>

              <div className="col-2 d-flex mt-2 align-items-center me-5">
                <h6 className="pe-2">End Date:</h6>
                <h5>{sessionStorage.getItem("eDate")}</h5>
              </div>
            </div>

        </div>

        <div className="container-fluid">
          {Array.isArray(programs) ? <div className="row mt-4">
            {programs.map((data) => {
              return (
                <div className="col-lg-3 col-12  d-flex flex-column bgwhite-batchdetails mt-2 mx-2">
                <div className="d-flex">
                  <h6>{data.programName}</h6>
                  <h6 className="mx-5 px-3">{data.percentage}%</h6>
                  <h5>{data.studentCount}</h5>
                </div>
  
                <ProgressBar className="Progress-bar-batch-details" now={data.percentage} />
              </div>
              )
            })}

            {/* <div className="assign-prog-border col-lg-2 col-12 ms-2 px-4 py-2 mt-2 d-flex align-items-center justify-content-center">
              <h6 onClick={formPop} className="pt-2">
                Assign Program
              </h6>
            </div> */}
          </div> : <div>No Programs Found</div>}

          <div className="d-flex justify-content-between mt-5">
        <h1 className="mb-4">Students List</h1>
        {/* <div className="d-flex">
          <h6 className="me-2">Filter by</h6>
          <img
                      src={require("../images/filter.png")}
                      alt="Image not found"
                      className="filter-icon"
                    />

        </div> */}
        </div>
        {loading ? <div className=" h-100 d-flex  bgwhite justify-content-center align-items-center mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div>:
        err ? <div className='col-12 text-center'>
        <NoRecFound error="No batch details found." />
        </div>:
        data &&     <div className="scrollme">
        <table className="table bgwhite">
          <thead>
            <tr>
              <th scope="col">Student Name</th>
              <th scope="col">Mail Id</th>
              <th scope="col">Joined Date</th>
              <th scope="col">Program Name</th>
              <th scope="col">Program Status</th>
              <th scope="col">Placed</th>
              {/* <th scope="col">Remove</th> */}
            </tr>
          </thead>
          <tbody>
            {data.map((data, index) => {
              return (
                <tr key={index}>
                  <td scope="row">{data.name}</td>
                  <td>{data.mailId}</td>
                  <td>{moment(data.JoinedDate).format('MM/DD/YYYY')}</td>
                  <td>{data.programName}</td>
                  <td>{data.programStatus}%</td>
                  <td>{data.placedStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}
        </div>
        </div>
      </div>
    </>
  );
};

export default BatchDetails;
