import React from 'react'
import "../styles/Sidebar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import home from '../images/home.svg';
import programs from '../images/programs.svg';
import batches from '../images/batches.svg';
import assignments from '../images/assignments.svg';
import users from '../images/users.svg';
import profile from '../images/profile.svg';
import subscription from '../images/suscription.svg';


import homewhite from '../images/home_white.svg';
import programswhite from '../images/programs_white.svg';
import batcheswhite from '../images/batches_white.svg';
import assignmentswhte from '../images/assignments_white.svg';
import userswhite from '../images/users_white.svg';
import profilewhite from '../images/profile_white.svg';
import subscriptionwhite from '../images/suscription_white.svg';

import homeblack from '../images/home_black.svg';
import assignmentsblack from '../images/assignments_black.svg';
import profileblack from '../images/profile_black.svg';
import programsblack from '../images/programs_black.svg';
import batchesblack from '../images/batches_black.svg';
import usersblack from '../images/users_black.svg';
import subscriptionblack from '../images/suscription_black.svg';




const Sidebar = () => {

        const [img1, setImg1] = useState('black')

        const [img2, setImg2] = useState('black')

        const [img3, setImg3] = useState('black')

        const [img4, setImg4] = useState('black')

        const [img5, setImg5] = useState('black')

        const [img6, setImg6] = useState('black')

        const [img7, setImg7] = useState('black')

        const location = useLocation();


        useEffect(() => {
          function change(){
          if(location.pathname==='/dashboard') {
               setImg1('white');
          }
           else if(location.pathname==='/programs') {
               setImg2('white')
           } 
           else if(location.pathname==='/batchlist') {
               setImg3('white')
           }
           else if(location.pathname==='/assignments') {
            setImg4('white')
        }
        else if(location.pathname==='/manageusers') {
          setImg5('white')
      }
      else if(location.pathname==='/updateself') {
        setImg6('white')
    }
    else if(location.pathname==='/managprogandassignments') {
      setImg7('white')
  }
          }
          setInterval(() => {
                  change()
          },50)
       }, [location])

        


        function mouseInImg1() {
                setImg1('blue')
        }

        function mouseOutImg1() {
                setImg1('black')
        }

        function mouseInImg2() {
          setImg2('blue')
  }

  function mouseOutImg2() {
          setImg2('black')
  }

  function mouseInImg3() {
    setImg3('blue')
}

function mouseOutImg3() {
    setImg3('black')
}

function mouseInImg4() {
  setImg4('blue')
}

function mouseOutImg4() {
  setImg4('black')
}
function mouseInImg5() {
  setImg5('blue')
}

function mouseOutImg5() {
  setImg5('black')
}
function mouseInImg6() {
  setImg6('blue')
}

function mouseOutImg6() {
  setImg6('black')
}
function mouseInImg7() {
  setImg7('blue')
}

function mouseOutImg7() {
  setImg7('black')
}
       



        // useEffect(() => {
        //   if(window.location.pathname==='/' || window.location.pathname==='/forgetpassword' || window.location.pathname==='/verifyotp' || window.location.pathname==='/updatepass') {
        //     sideB.current.style.display='none'
        //   }
        //   else {
        //     sideB.current.style.display='block'
        //   }
        // }, [window.location.pathname])



        const sideB = React.useRef(null);


        const navigate = useNavigate();

        function handleClick() {
                navigate("/dashboard");
              }

              function handleClick2() {
                navigate("/manageusers");
              } 

              function handleClick3() {
                navigate("/programs");
              } 

              function handleClick4() {
                navigate("/batchlist");
              } 

              function handleClick5() {
                navigate("/assignments");
              } 

              function handleClick7() {
                navigate("/managprogandassignments");
              } 

              function handleClickSelf() {
                navigate("/updateself");
              } 

              function handleClick6() {
                sessionStorage.clear()
                navigate("/");
              } 


  return (
   <>
   <div ref={sideB} className='container-fluid position-fixed sidebar'>

    <div className='row'>

        <div className='width10 px-0 min-vh-100 bgwhite sidebar-height shadow'>

            <ul className=' list-unstyled'>

                    <li>
                            <a className=' d-flex justify-content-center mt-5 nav-link px-2'>
                                 <img src={require('../images/Logo.png')} alt="Image not found" />
                            </a>

                    </li>

                 


<li className={location.pathname==='/dashboard' ? 'd-flex mt-4 selectedOption ps-3 pe-3' : 'd-flex mt-4 sidemenuoption ps-3 pe-3'} id="img1" onClick={handleClick} onMouseEnter={mouseInImg1} onMouseLeave={mouseOutImg1}>
                            {img1 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick} src={homeblack} height='20px' width='20px' alt="dashboard" />
                            </a>}
                            {img1 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick} src={home} height='20px' width='20px' alt="dashboard" />
                            </a>}
                            {img1 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick} src={homewhite} height='20px' width='20px' alt="dashboard" />
                            </a>}
                            
                            <h6 className={location.pathname==='/dashboard' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Dashboard</h6>
                        </li>






<li className={location.pathname==='/programs' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img2" onClick={handleClick3}  onMouseEnter={mouseInImg2} onMouseLeave={mouseOutImg2}>
                            {img2 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={programsblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img2 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={programs} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img2 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={programswhite} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/programs' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Programs</h6>
                        </li>





                        <li className={location.pathname==='/batchlist' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img3" onClick={handleClick4}  onMouseEnter={mouseInImg3} onMouseLeave={mouseOutImg3}>
                            {img3 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={batchesblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img3 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={batches} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img3 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={batcheswhite} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/batchlist' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Batches</h6>
                        </li>






                        <li className={location.pathname==='/assignments' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img4" onClick={handleClick5}  onMouseEnter={mouseInImg4} onMouseLeave={mouseOutImg4}>
                            {img4 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={assignmentsblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img4 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={assignments} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img4 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={assignmentswhte} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/assignments' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Assignments</h6>
                        </li>






                        <li className={location.pathname==='/manageusers' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img5" onClick={handleClick2}  onMouseEnter={mouseInImg5} onMouseLeave={mouseOutImg5}>
                            {img5 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={usersblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img5 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={users} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img5 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={userswhite} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/manageusers' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Users</h6>
                        </li>




                        <li className={location.pathname==='/managprogandassignments' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img7" onClick={handleClick7}  onMouseEnter={mouseInImg7} onMouseLeave={mouseOutImg7}>
                            {img7 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={subscriptionblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img7 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={subscription} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img7 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={subscriptionwhite} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/managprogandassignments' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Manage Program & Assignments</h6>
                        </li>





                
                        <li className={location.pathname==='/updateself' ? 'd-flex mt-3 selectedOption ps-3 pe-3' : 'd-flex mt-3 sidemenuoption ps-3 pe-3'} id="img6" onClick={handleClickSelf}  onMouseEnter={mouseInImg6} onMouseLeave={mouseOutImg6}>
                            {img6 === 'black' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={profileblack} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img6 === 'blue' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={profile} height='20px' width='20px' alt="assignments" />
                            </a>}
                            {img6 === 'white' && <a className='d-flex flex-column align-items-center nav-link px-2'>
                                <img onClick={handleClick2} src={profilewhite} height='20px' width='20px' alt="assignments" />
                            </a>}
                            <h6 className={location.pathname==='/updateself' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Profile</h6>
                        </li>


                        

                        

                    <li onClick={handleClick6} className='d-flex ps-4 mt-5 pt-5'>
                    <a  className=' pb-2  dash6'>
                            <img  src={require('../images/logout.png')} alt="Image not found" />
                            </a>
                            <div className='sidebar-options mt-1 ps-2'>Logout</div>

                    </li>
                
            </ul>


        </div>


    </div>

   </div>
   </>
  )
}

export default Sidebar;
