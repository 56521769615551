import React, {useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "../styles/AdminProfile.css"
import { useNavigate } from "react-router-dom";
import httpuserprofile from "../js/http-lms_userprofile"
import { useState } from 'react';

const Navb = ({result}) => {

  useEffect(() => {
    if(window.location.pathname==='/' || window.location.pathname==='/forgetpassword' || window.location.pathname==='/verifyotp' || window.location.pathname==='/updatepass') {
      nav.current.style.display='none'
    }
    else {
      nav.current.style.display='block'
    }
  }, [window.location.pathname])

  const [roleName, setRoleName] = useState()

  const [userFirstName, setUserFirstName] = useState()

  const [userLastName, setUserLastName] = useState()

  const uploadedImage = React.useRef(null);

  const nav = React.useRef(null); 

  useEffect(() => {
    if(result) {
      uploadedImage.current.src=result
    } else {
      uploadedImage.current.src=require('../images/ProfilePlaceholder.png')
    }
  }, [result])

  const navigate = useNavigate();

  function handleClickDash() {
    navigate("/dashboard");
  }

  function handleClickProg() {
    navigate("/programs");
  }

  function handleClickBatch() {
    navigate("/batchlist");
  }

  function handleClickAssign() {
    navigate("/assignments");
  }

  function handleClickUsers() {
    navigate("/manageusers");
  }

  function handleClick() {
    localStorage.setItem('path', window.location.pathname)
    navigate("/adminprofile");
  }

  function handleClick6() {
    navigate("/");
    sessionStorage.clear()
    localStorage.clear()
  } 

  function handleProfile1(){
    console.log("ko")
    navigate("/updateself")
  }



  useEffect(() => {
    getLoggedInUserDetails()
  }, [sessionStorage.getItem('userId')])




  const getLoggedInUserDetails =async ()=>{
    httpuserprofile.get(`/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem('userId')}`)
    .then((response) => {
      console.log("UserDetails:", response)
      // setLoading(1)
      setRoleName(response.data.role.roleName)
      setUserFirstName(response.data.firstName)
      setUserLastName(response.data.lastName)
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <>

    <div ref={nav} className='mt-4 mx-1'>
    
    <Navbar className='bgwhite nav1 borderBottom' fixed='top' expand="lg">
      <Container fluid>
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <div className='ms-5 ps-3 pt-4 pb-5 d-flex'>
         <img className='pe-3 LogoUpdated' src={require('../images/LogoUpdated.png')} alt="Image not found" />
          <h3 className='UpdatedMainColor pt-1'>ITERION</h3>
          </div>
          </Nav>
          <Nav.Link  onClick={handleProfile1} style={{ padding: '0px' }}>
            <div  className='d-flex pe-5'>
            <div>
          <img className='admin-pro-pic my-1 me-2'
          ref={uploadedImage}
        />
        </div>
          <div className='d-flex flex-column mt-3'>
            <h6 className='font12 mb0 UpdatedMainColor'>{roleName}</h6>
            <h6 className='font14 mb0 black60'>{userFirstName} {userLastName}</h6>
            {/* <h6 className='font10 mb0 black60'>Timestamp</h6> */}
          </div>
          </div>
          </Nav.Link>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>



    <Navbar className='bgwhite nav2 rounded-3'fixed='top' expand="lg">
      <Container fluid>
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
          <Nav.Link onClick={handleClickDash} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic1.png')} alt="Image not found" />Dashboard</Nav.Link>
          <Nav.Link onClick={handleClickProg} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic2.png')} alt="Image not found" />Programs</Nav.Link>
          <Nav.Link onClick={handleClickBatch} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic3.png')} alt="Image not found" />Batch List</Nav.Link>
          <Nav.Link onClick={handleClickAssign} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic5.png')} alt="Image not found" /> Assignments</Nav.Link>
          <Nav.Link onClick={handleClickUsers} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic4.png')} alt="Image not found" /> Manage Users</Nav.Link>
          <Nav.Link onClick={handleClick6}  href="#action1"><img className='me-2 mb-1' src={require('../images/logout.png')} alt="Image not found" />Logout</Nav.Link>        
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
</div>
      
    </>
  )
}

export default Navb;
