import "../../styles/Dashboard.css";
import "../../styles/AdminProfile.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, { useEffect } from "react";
import { useState } from "react";
import httpuserprofile from "../../js/http-lms_userprofile"
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner';
import { Button, Modal } from 'react-bootstrap';
import httpprograms from "../../js/http-lms_programs"
import axios from "axios";

const CreateUsers = ({form, setForm}) => {

  const [disable, setDisable] = useState(true)
  const uploadedImage =require('../../images/ProfilePlaceholder.png')
  //initial data
  const [userRoles, setUserRoles] = useState([])
  const [realestRoles, setrealestUserRoles] = useState([])
  const [batchList, setBatchList] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [statesList, setStatesList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [collegesList, setCollegesList] = useState([])
  const [educationList, setEducationList] = useState([])
  const [studentProgress, setStudentProgress] = useState([])
  const [trainerProgress, setTrainerProgress] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [selectedCollegeNameOption, setSelectedCollegeNameOption] = useState(null);
  const [selectedBatchOption, setSelectedBatchOption] = useState(null);
  const [selectedProgramOption, setSelectedProgramOption] = useState([]);
  const [show, setShow] = useState(false);
  const [pop, setPop] = useState(false);
  const [authCode, setAuthCode] = useState("")

  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [formErros, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const[programDetails, setProgramDetails] = useState()
  const [programId, setProgramId] = useState()
  const [programName, setProgramName] = useState()

  useEffect(() => {
    if(form==='2') {
      formData.roleId='2'
    }
  }, [form])
  
  // validate data and enable/disable submit btn
  useEffect(() => {
    if(form === "1" || form === "3" || form === "5" || form === "6" || form === "7" || form === "8" || form === "10" || form === "11" || form === "12"){
      if((formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId)) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(form === "2"){
      if((formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && formData.collegeId && formData.yearOfGraduation && formData.educationId && formData.batchId && formData.studentStatusId)) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(form === "4")
    {
      if((formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && selectedProgramOption.length>0)){
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
    else if(form === "9")
    {
      if((formData.firstName && formData.lastName && formData.emailId && formData.phoneNumber && formData.address && formData.cityId && formData.stateId && formData.countryId && formData.collegeId )){
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }
  }, [formData])



  // const getUserBatches = async ()=>{

  //   await axios.get("http://localhost:13051/userprofileLMS/getUserBatches", {headers:{
  //     'userId': sessionStorage.getItem("userEmail"), 
  //       'Authorization': sessionStorage.getItem("JWT")
  //   }}).then((result)=>{
  //     console.log(result.data)
  //     setBatchList(result.data)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
 
  //API call when page loader
  useEffect(() => {
    //fetching user roles
    httpuserprofile
      .get("/userprofileLMS/getUserRoles?appCode=LMS")
      .then((res) => {
        console.log("user roles", res)
        setUserRoles(res.data)
      })


      //fetching realestate roles
      httpuserprofile
      .get("/userprofileLMS/getUserRoles?appCode=KRD")
      .then((res) => {
        console.log("user roles", res)
        setrealestUserRoles(res.data)
      })

    // fetching batchlist
    httpuserprofile
      .get("/userprofileLMS/getUserBatches")
      .then((res) => {
        console.log("user batches", res)
        setBatchList(res.data)
      })

// getUserBatches();


    // var config = {
    //   method: 'get',
    //   url: 'http://localhost:13051/userprofileLMS/getUserBatches',
    //   headers: { 
    //     'userId': sessionStorage.getItem("userEmail"), 
    //     'Authorization': sessionStorage.getItem("JWT")
    //   }
    // };
    
    // axios(config)
    // .then(function (response) {
    //   setUserRoles(response.data)
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });





    //fetching countries list
    httpuserprofile
      .get("/userprofileLMS/getUserCountries")
      .then((res) => {
        console.log("user countries", res)
        setCountriesList(res.data)
      })
    //fetching college list
    httpuserprofile
      .get("/userprofileLMS/getCollgeList")
      .then((res) => {
        console.log("user colleges", res)
        setCollegesList(res.data)
      })
    //fetching education list
    httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=5")
      .then((res) => {
        console.log("Education", res)
        setEducationList(res.data)
      })
    //fetching college list
    httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=1")
      .then((res) => {
        console.log("Progress", res)
        setStudentProgress(res.data)
      })

      httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=1")
      .then((res) => {
        console.log("Progress", res)
        setTrainerProgress(res.data)
      })
    //create list of years
    var currentYear = new Date().getFullYear();
    var startYear = currentYear-12;  
    var endyear = currentYear+6;
    var list = []
    while ( startYear <= endyear ) {
      let year = {'year' : endyear--}
      list.push(year);
    }
    setYearsList([]);
    setYearsList(list)
    // if(yearsList.length>0){
    //   setYearsList(yearsList)
    // }
    
  },[])





  //updating formdata for final submission
  function handleChange(e) {
    setErrorMessage('')
    const {name, value} = e.target;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log(formData);
  }



  
  // triggered after changing the country
  // reset selected state and city
  // fetch states list based on selected country
  function handleCountryChange(e){
    console.log("selected country", e)
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e)
    setSelectedStateOption(null);
    setSelectedCityOption(null)
    setStatesList([])
    setCitiesList([])
    formData.stateId = null;
    formData.cityId = null;
    // formData.countryId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["countryId"]: e.countryId
      }
    })
    // handleChange(e)
    httpuserprofile
      .get("/userprofileLMS/getUserStates?countryId="+e.countryId)
      .then((res) => {
        console.log("user states", res)
        setStatesList(res.data)
      })
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function handleStateChange(e){
    console.log("selected state", e)
    //reset cities, once changed selected country
    setSelectedStateOption(e)
    setSelectedCityOption(null)
    setCitiesList([])
    // formData.stateId = e.id;
    formData.cityId = null;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["stateId"]: e.stateId
      }
    })
    // handleChange(e)
    httpuserprofile
      .get("/userprofileLMS/getUserCities?stateId="+e.stateId)
      .then((res) => {
        console.log("user cities", res)
        setCitiesList(res.data)
      })
  }
  // triggered after changing the city
  function handleCityChange(e) {
    console.log("selected city : ", e)
    setSelectedCityOption(e)
    // formData.cityId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["cityId"]: e.cityId
      }
    })
  }
  // triggered after selecting college name
  function handleCollegeNameChange(e) {
    console.log("selected college name : ", e)
    setSelectedCollegeNameOption(e)
    // formData.collegeId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["collegeId"]: e.collegeId
      }
    })
  }
  // triggered after selecting batch name
  function handleBatchChange(e) {
    console.log("selected college name : ", e)
    setSelectedBatchOption(e)
    // formData.batchId = e.id;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["batchId"]: e.batchId
      }
    })
  }

  function handleProgramChange(e) {
    console.log("Selected Program details:", e)
    setSelectedProgramOption(e)
    let p_id = [];
    e.map(x => {
      p_id.push(x.value)
    })
    // formData.batchId = e.value;
    setFormData((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["programId"]: p_id
      }
    })
  }


  useEffect(() => {
    programCards()
  }, [])


  const programCards =async ()=>{
  
      // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
      httpprograms.get("/programsLMS/getModulesTopicCount")
      .then((response) => {
        console.log("Program Details:", response)
        let data = [];
        response.data.map(x => {
          data.push({
            value: x.program_id,
            label: x.program_name
          })
        })
        console.log(data, 'ppppppppppp')
        setProgramDetails(data)
      }).catch((err)=>{
        console.log(err)
      })

  }


  // final api call to save user information
  // update issubmit data if don't have any errors
  function submitData(e) {
    e.preventDefault()
    setFormErrors(validate(formData))
    setIsSubmit(true)
  }
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phonenumberregex = /^[6789]\d{9}$/;
    if(!values.firstName){
      errors.firstName = "First Name is required!"
    }else if(!allowaplaspaces.test(values.firstName)){
      errors.firstName = "First name should contain alphabets and spaces"
    }
    if(!values.lastName){
      errors.lastName = "Last Name is required!"
    }else if(!allowaplaspaces.test(values.lastName)){
      errors.lastName = "Last name should contain alphabets and spaces"
    }
    if(!values.emailId){
      errors.emailId = "Email Id is required!"
    }else if(!emailregex.test(values.emailId)){
      errors.emailId = "Please enter valid email id"
    }
    if(!values.phoneNumber){
      errors.phoneNumber = "Phone Number is required!"
    }else if(!phonenumberregex.test(values.phoneNumber)){
      errors.phoneNumber = "Phone number must be of 10 digits only"
    }
    if(!values.address){
      errors.address = "Address is required!"
    }
    if(!values.cityId){
      errors.cityId = "City is required!"
    }
    if(!values.stateId){
      errors.stateId = "State is required!"
    }
    if(!values.countryId){
      errors.countryId = "Country is required!"
    }
    // formData.rollNumber && formData.collegeName && formData.yearOfGraduation && formData.educationId
    if(formData.roleId == '2'){
      // if(!values.rollNumber){
      //   errors.rollNumber = "Roll Number is required!"
      // }
      if(!values.collegeId){
        errors.collegeId = "College Name is required!"
      }
      if(!values.yearOfGraduation){
        errors.yearOfGraduation = "Year of graduation is required!"
      }
      if(!values.educationId){
        errors.educationId = "Education level is required!"
      }
      if(!values.batchId) {
        errors.batchId = "Please select Batch!"
      }
      if(!values.studentStatusId){
        errors.studentStatusId = "Please select student progress!"
      }
    }
    if(formData.roleId == "4"){
      if(!values.programId){
        errors.programId = "Please select at least 1 program"
      }
      if(!values.status){
        errors.status = "Please select trainer status"
      }
    }
    return errors;
  }

  function handleKeyDown(e) {
    const keyCode = e.keyCode;
    const shiftKey = e.shiftKey;
    
    // Allow letters and special characters, but not numbers
    if (!shiftKey && (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
      e.preventDefault();
    } else if (keyCode === 187 || keyCode === 189 || keyCode === 219 || keyCode === 221) {
      e.preventDefault();
    }
  }



  function handleKeyDown2(e) {
    const keyCode = e.keyCode;
    
    // Allow numbers only, but not letters or special characters
    if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) ||
        (keyCode === 187) || (keyCode === 189) || (keyCode === 191) ||
        (keyCode === 107) || (keyCode === 109) || (keyCode === 106) || (keyCode === 111) ||
        (keyCode === 187) || (keyCode === 107)) {
      e.preventDefault();
    }
  }
  
  useEffect(() => {
    if(Object.keys(formErros).length === 0 && isSubmit){
      setSubmitLoading(true)
      formData.creationUserI = sessionStorage.getItem('userEmail');
      console.log("final submission : ", formData)
      httpuserprofile.post(`/userprofileLMS/saveUserProfile?authCode=${authCode}`, JSON.stringify(formData))
      .then((response) => {
        console.log("save user profile data : ", response)
        if(response.data == 'SUCCESS User created '){
          resetEntireForm()
          setShow(false); 
          setPop(true)
      setTimeout(() => {
        setPop(false)
      }, 1500)
        }
        else if(response.data == 'Email id already exist'){
          setSubmitLoading(false)
        }
        setErrorMessage(response.data)
      }).catch((e) => {
        console.log("error saving user : ", e.response)
        setSubmitLoading(false)
        setIsSubmit(false);
      })

      console.log(formData)
    }
  }, [formErros])
  // student form data
  function handleChangeStudent(e){

    const {name, value} = e.target;
    setFormData((pValue) => {
      return {
        ...pValue,
        [name]: value
      }
    })
    
    console.log(formData);
  }

  // UseState to set resulting image
  const [result, setResult] = useState(null);

  //set the form
  // const [form, setForm] = useState("");

  function resetEntireForm() {
    let form = document.getElementById("create-User-Form")
    let roledropdown = document.getElementById("roleselector")
    if(form){
      form.reset();
    }
    if(roledropdown){
      roledropdown.value = "";
    }
    setForm('');
    setFormData({})
    setFormErrors({})
    setIsSubmit(false);
    setSubmitLoading(false)
    setSelectedCityOption(null)
    setSelectedStateOption(null)
    setSelectedCountryOption(null)
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    
  }



  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({ 
        ...styles, 
        // border: `1px solid #ced4da`, 
        // boxShadow: `0 0 0 1px`, 
        // ':hover' : {
        //   // boxShadow: `0 0 0 1px `,  
        //   backgroundColor : "blue"
        // } 
     }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };
  
  // function handlemultiChange(option) {
  //   // this.setState({ selectedOption });
  //   setselectedOption(option)
  //   console.log(`Option selected:`, option);
  // };

  function cancelForm(e){
    let form = document.getElementById("create-User-Form")
    if(form){
      form.reset();
    }
    e.preventDefault()
    setErrorMessage('')
    setFormData({})
    setFormErrors({})
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    setStatesList(null)
    setCitiesList(null)
  }
  

  function testClick(e, auth) {
    setAuthCode(auth)

    let form = document.getElementById("create-User-Form")
    if(form){
      form.reset();
    }
    setErrorMessage('')
    setFormData({})
    setFormErrors({})
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setSelectedBatchOption(null)
    setSelectedCollegeNameOption(null)
    setForm(e.target.value);
    console.log("value of selected dropdown : ", e.target.value)
    setFormData((pValue) => {
      return {
        ...pValue,
        'roleId': e.target.value
      }
    })
    console.log("formdata : ", formData)
    console.log("formerrors : ", formErros)
  }


  const handleShow = () => setShow(true);

 const handleClose = () => {
    setShow(false); 
  }


  return (
    <>



{pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully user has been created</p>
      </div>
    </div>}


{form==='2' ? 
<Button className='batchbtn col-12 mb-4' onClick={handleShow}>
+ Add Student
</Button>
: form==='4' ? <Button className='batchbtn col-12 mb-4' onClick={handleShow}>
+ Add Trainer
</Button> : <Button className='batchbtn col-12 mb-4' onClick={handleShow}>
        + Add User
      </Button>}
    
        {/* create user forms section */}


        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={`modal-left h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {form===2 ? 'Add Student' : 'Add User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>



        
{ form==='2' || form==='4' ? null :          
<div>
            <div className="d-flex col-md-12 flex-column align-items-start px-2 pb-4">
              <h6 className="my-2 red">{errorMessage}</h6>

              
              <h6 style={{ fontWeight: "400" }}>Select a Role</h6>

              
              <div className="d-md-flex">
              <Form.Select
                onChange={(e) => testClick(e, "miidBM390")}
                className="form-select"
                id="roleselector"
                aria-label="Default select example"
                style={{marginBottom: form==="" ? '50px' : '0px'}}
              >
                <option value="">Select Role</option>
                {userRoles.map((data, index) => {
                  return (
                    <option value={data.roleId} key={data.roleId}>{data.roleName}</option>
                )})}
              </Form.Select>




              <Form.Select
                onChange={(e) => testClick(e, "KRDREBM390" )}
                className="form-select"
                id="roleselector"
                aria-label="Default select example"
                style={{marginBottom: form==="" ? '50px' : '0px'}}
              >
                <option value="">Select Realestate Role</option>
                {realestRoles.map((data, index) => {
                  return (
                    <option value={data.roleId} key={data.roleId}>{data.roleName}</option>
                )})}
              </Form.Select>
              </div>
            </div>

            
            {/* <div className={(errorMessage == 'SUCCESS User created ') ? "font14 green text-center pb-4" : "red text-center"}>{errorMessage}</div>
            {(errorMessage=='SUCCESS User created ')} */}


            </div>
}

            {/* <div className="justify-content-start mt-3 px-5" style={{display: form==="" ? 'none' : 'flex'}}>
              <div className="mt-4 mb-5">
                <div className="edit-container d-inline-block position-relative">
                  <img
                    // ref={uploadedImage}
                    src={uploadedImage}
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%"
                    }}
                  />
                </div>
              </div>
            </div> */}

            <div className="form-container h-100 px-2">
              {(form === "1" || form === '2' || form === '4' || form === '9' || form === '3' || form === '5' || form === '6' || form === '7' || form === '8' || form === '10' || form === '11' || form === '12') && (
                <Form id="create-User-Form" className='h-100'>
                  <div className='scrollForm'>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                     <div className="d-flex required"><h6>First Name</h6></div>
                      <Form.Control
                        className={formErros.firstName ? "borderred" : ""}
                        placeholder="Enter first name"
                        autoComplete="off"
                        name='firstName'
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                      <p className="red font12">{formErros.firstName}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <h6>Middle Name</h6>
                      <Form.Control
                        className=""
                        placeholder="Enter middle name"
                        autoComplete="off"
                        name='middleName'
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Last Name</h6></div>
                      <Form.Control
                        className={formErros.lastName ? "borderred" : ""}
                        placeholder="Enter last name"
                        autoComplete="off"
                        name='lastName'
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                      <p className="red font12">{formErros.lastName}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>Email</h6></div>
                      <Form.Control
                        className={formErros.emailId ? "borderred" : ""}
                        placeholder="Enter email address"
                        autoComplete="off"
                        name='emailId'
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.emailId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Phone</h6></div>
                      <Form.Control
                        className={formErros.phoneNumber ? "borderred" : ""}
                        placeholder="Enter mobile"
                        autoComplete="off"
                        name='phoneNumber'
                        maxLength={10}
                        type="text"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown2}
                      />
                      <p className="red font12">{formErros.phoneNumber}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                     <div className="d-flex required"> <h6>Address 1</h6></div>
                      <Form.Control
                        className={formErros.address ? "borderred" : ""}
                        placeholder="Street address"
                        autoComplete="off"
                        name='address'
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.address}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <h6>Address 2</h6>
                      <Form.Control
                        className=""
                        placeholder="House no."
                        autoComplete="off"
                        name='address1'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>Country</h6></div>
                      {/* <Form.Control
                        className={formErros.countryId ? "borderred" : ""}
                        placeholder="Country"
                        autoComplete="off"
                        name='countryId'
                        onChange={handleCountryChange}
                      /> */}
                      {/* <Form.Select
                        onChange={handleCountryChange}
                        className={formErros.countryId ? "borderred form-select" : "form-select"}
                        name="countryId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Country</option>
                        {countriesList.map((country, index) => {
                          return (
                            <option value={country.id} key={country.id}>{country.name}</option>
                        )})}
                      </Form.Select> */}
                      <Select
                        value={selectedCountryOption}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.countryName}`
                        }
                        options={countriesList}
                      />
                      <p className="red font12">{formErros.countryId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>State</h6></div>
                      {/* <Form.Control
                        className={formErros.stateId ? "borderred" : ""}
                        placeholder="State"
                        autoComplete="off"
                        name='stateId'
                        onChange={handleChange}
                      /> */}
                      {/* <Form.Select
                        onChange={handleStateChange}
                        className={formErros.stateId ? "borderred form-select" : "form-select"}
                        name="stateId"
                        typeof="number"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select State</option>
                        {statesList.map((state, index) => {
                          return (
                            <option value={state.id} key={state.id}>{state.name}</option>
                        )})}
                      </Form.Select> */}
                      <Select
                        value={selectedStateOption}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.stateName}`
                        }
                        options={statesList}
                      />
                      <p className="red font12">{formErros.stateId}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>City</h6></div>
                      {/* <Form.Control
                        className={formErros.cityId ? "borderred" : ""}
                        placeholder="City"
                        autoComplete="off"
                        name='cityId'
                        onChange={handleChange}
                      /> */}
                      {/* <Form.Select
                        onChange={handleChange}
                        className={formErros.cityId ? "borderred form-select" : "form-select"}
                        name="cityId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select City</option>
                        {citiesList.map((city, index) => {
                          return (
                            <option value={city.id} key={city.id}>{city.name}</option>
                        )})}
                      </Form.Select> */}
                      <Select
                        value={selectedCityOption}
                        onChange={handleCityChange}
                        placeholder="Select City"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.cityName}`
                        }
                        options={citiesList}
                      />
                      <p className="red font12">{formErros.cityId}</p>
                    </Col>
                  </Row>
                  {form === "2" && (
                    <div>
                    <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex"><h6>College Roll Number</h6></div>
                      <Form.Control
                        className={formErros.rollNumber ? "borderred" : ""}
                        placeholder="Roll no."
                        name='rollNumber'
                        autoComplete="off"
                        onChange={handleChange}
                      />
                      {/* <p className="red font12">{formErros.rollNumber}</p> */}
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>College Name</h6></div>
                      {/* <Form.Control
                        className=""
                        placeholder="Enter college"
                        name='collegeName'
                        onChange={handleChangeStudent}
                      /> */}
                      {/* <Form.Select
                        onChange={handleChange}
                        className={formErros.collegeId ? "borderred form-select" : "form-select"}
                        name="collegeId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select collegeName</option>
                        {collegesList.map((college, index) => {
                          return (
                            <option value={college.id} key={college.id}>{college.institueName}</option>
                        )})}
                      </Form.Select> */}
                      <Select
                        value={selectedCollegeNameOption}
                        onChange={handleCollegeNameChange}
                        placeholder="Select College"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.collegeName}`
                        }
                        options={collegesList}
                      />
                      <p className="red font12">{formErros.collegeId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Year of Graduation</h6></div>
                      {/* <Form.Control
                        className=""
                        placeholder="Year of grad."
                        name='yearOfGraduation'
                        onChange={handleChange}
                      /> */}
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.yearOfGraduation ? "borderred form-select" : "form-select"}
                        name="yearOfGraduation"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Graduation  Year</option>
                        {yearsList.map((year, index) => {
                          return (
                            <option value={year.year} key={index}>{year.year}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.yearOfGraduation}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <h6 className="required">Education Level</h6>
                      {/* <Form.Control
                        className=""
                        placeholder="e.g. UG/PG/PHD"
                        name='educationId'
                        onChange={handleChangeStudent}
                      /> */}
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.educationId ? "borderred form-select" : "form-select"}
                        name="educationId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Education Level</option>
                        {educationList.map((degree, index) => {
                          return (
                            <option value={degree.indexCode} key={degree.indexCode}>{degree.indexDescription}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.educationId}</p>
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Batch</h6></div>
                      {/* <Form.Select
                        onChange={handleChange}
                        className={formErros.batchId ? "borderred form-select" : "form-select"}
                        name="batchId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Batch</option>
                        {batchList.map((batch, index) => {
                          return (
                            <option value={batch.id} key={batch.id}>{batch.name}</option>
                        )})}
                      </Form.Select> */}
                      <Select
                        value={selectedBatchOption}
                        onChange={handleBatchChange}
                        placeholder="Select Batch"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.batchName}`
                        }
                        options={batchList}
                      />
                      <p className="red font12">{formErros.batchId}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <h6 className="required">Student Status</h6>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.studentStatusId ? "borderred form-select" : "form-select"}
                        name="studentStatusId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Student Status</option>
                        {studentProgress.map((status, index) => {
                          return (
                            <option value={status.indexCode} key={status.indexCode}>{status.indexDescription}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.studentStatusId}</p>
                    </Col>
                  </Row>
                  </div>
                  )}

{form === "4" && (
  <div>
  <Row className="pb-3">
   <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
   <div className="d-flex required"> <h6>Select Program</h6></div>
   <Select
                        value={selectedProgramOption}
                        onChange={handleProgramChange}
                        placeholder="Select Program"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        isMulti
                        options={programDetails}
                      />
                      {/* <p className="red font12">{formErros.programId}</p> */}
                      </Col>
                      <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <h6 className="required">Trainer Status</h6>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.status ? "borderred form-select" : "form-select"}
                        name="status"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Trainer Status</option>
                        {trainerProgress.map((status, index) => {
                          return (
                            <option value={status.indexCode} key={status.indexCode}>{status.indexDescription}</option>
                        )})}
                      </Form.Select>
                      <p className="red font12">{formErros.status}</p>
                    </Col>
                      {/* <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Program</h6></div>
                      <Form.Select
                        onChange={handleChange}
                        className={formErros.yearOfGraduation ? "borderred form-select" : "form-select"}
                        name="programId"
                        aria-label="Default select example"
                        style={{marginBottom: form==="" ? '50px' : '0px'}}
                      >
                        <option value="">Select Program</option>
                        {programDetails.map((data, index) => {
                          return (
                            <option value={data.program_id} key={index}>{data.program_name}</option>
                        )})}
                      </Form.Select>
                    </Col> */}
                      </Row>
                  </div>)}

                  {form === "9" && (
  <div>
  <Row className="pb-3">
  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required"> <h6>College Name</h6></div>
                    <Select
                        value={selectedCollegeNameOption}
                        onChange={handleCollegeNameChange}
                        placeholder="Select College"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.collegeName}`
                        }
                        options={collegesList}
                      />
                      <p className="red font12">{formErros.collegeId}</p>
                    </Col>
                      </Row>
                  </div>)}
                  </div>
                  <div style={{display: form==="" ? 'none' : 'flex'}} className="row">
                <div className="col-lg-12">
                {submitLoading ? <div className="col-lg-4 col-12 mt-3 mb-5 text-center">
                  <Spinner className="mx-auto" animation="border" variant="secondary"/>
                  </div> :
                  <div className="d-flex">
                  <button disabled={disable} onClick={submitData} className={ disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 UpdatedMainBGColor"}>
                    Create
                  </button>
                  <button className=" ms-5 cancelUser mt-3 mb-5" onClick={cancelForm}>Clear</button>
                  </div>
                }
                </div>
              </div>
                </Form>
              )}


              
            </div>
            </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateUsers;
