import axios from 'axios'

const callaxios = axios.create({
	baseURL: "https://apiv1.worknotion.com",
	headers:{
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin' : "https://apiv1.worknotion.com",
        'Access-Control-Allow-Methods' :  "POST, GET, OPTIONS, PUT, DELETE",
        'Access-Control-Allow-Headers' :  "Content-Type, X-Auth-Token, Origin, Authorization, UserId"
    },
});

// configure headers
// callaxios.interceptors.request.use(
//     config =>
//   {
//     if(sessionStorage.getItem("LOGGEDIN")){
//         config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem("JWT");
//         config.headers['UserId'] = sessionStorage.getItem('USERID');
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

export default callaxios