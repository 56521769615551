import React from 'react'
import Input from "../components/Input";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_signup';
import "../styles/SignIn.css"
import showPwdImg from '../images/showpwd.svg';
import hidePwdImg from '../images/hidepwd.svg';
import Logo from '../images/careerTunerLogo.svg';
import itreion from '../images/itreion.svg'
import axios from "axios";

const SignInBS = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [pass, setPass] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState([])
  const [emailError, setEmailError] = useState();
  const [passError, setPassError] = useState();
  const [disable, setDisable] = useState(true);
  const [roleName, setRoleName] = useState('default');
  const [roleId, setRoleId] = useState()

  useEffect(() => {
    if(roleName==='default') {
      sessionStorage.clear();
        setDisable(true)
    }
    else {
      setDisable(false)
    }
  }, [roleName])

  useEffect(() => {
    if(pass.length>0 && email.length>0 && sessionStorage.getItem('roleId')) {
      setDisable(false);
    }
    else {
      setDisable(true);
    }
  }, [pass, email])

  function roleChange(e){
let roles = role.filter((data)=>{
  return data.roleName === e.target.value;
})
console.log('check1');
  if(e.target.value!='default') {
    sessionStorage.setItem("roleId",roles[0].roleId)
    setRoleId(roles[0].roleId)
  }
  console.log('check2');
  setRoleName(e.target.value)
  }


  function passChange(e){
    setPass(e.target.value)
  }

  function emailChange(e){
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = e.target.value;
    var validemail = emailRegex.test(email);
    if(validemail) {
      setEmail(e.target.value)
      setEmailError();
    }else{
      setEmailError("Please enter valid Email Id");
    }
  }



    useEffect(()=>{
  getRoles()
  }, [])


  const getRoles = async ()=>{

    await axios.get("https://apiv1.worknotion.com/userloginLMS/getUserRoles?appCode=LMS").then((result)=>{
      console.log(result.data)
      setRole(result.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  
  const SignInAuth =async ()=>{

    setLoading(true)
    setPassError()
    setEmailError()
    // const response=await axios.post("https://apiv1.worknotion.com/userloginLMS/validate",{
    await httpsignup.post("/userloginLMS/validate",{
      emailId:email,
      passWord:pass,
      roleId:roleId,
      authCode:"miidBM390"
    }).then((result) => {
      console.log(result.data);
      setLoading(false)
      if (result.data[0].apiStatusMessage==='Account doesnt exist') {
        setEmailError(result.data[0].apiStatusMessage);
        setPassError();
      }
      else if(result.data[0].apiStatusMessage==='PASSWORD_INCORRECT') {
        setPassError("Please enter correct password");
        setEmailError();
      }
      else if(result.data[0].apiStatusMessage==='Account is terminated') {
        setEmailError(result.data[0].apiStatusMessage);
        setPassError();
      }
      else {
        const bearerToken = result.data[0].jwtToken;
        sessionStorage.setItem("JWT",bearerToken)
        sessionStorage.setItem("userEmail", result.data[0].userEmail)
        sessionStorage.setItem("userId", result.data[0].userId)
        sessionStorage.setItem("LOGGEDIN", true)

        navigate("/dashboard")
      }
    }).catch((e) => {
      console.log(e.response)
      setLoading(false)
    })

  }

  return (
    <>
    <div className='row d-flex align-items-center justify-content-between vh-100 login-back-container'>
      <div className='col-6 d-flex justify-content-center d-md-block d-none'>
    <img src={itreion} alt="Image not found" className='w-100 ms-5 ps-5' />
    </div>
      <div className='col-md-5 col-12 m-md-0  h-100 justify-content-center align-items-center bgwhite rounded-1 d-flex'>
        {/* <div className='col-lg-6 signin-image'></div> */}
        
        <div className="col px-5 pt-5 pb-5 d-flex flex-column">
          <div className="col-12">
            <div className="logo mx-auto text-center pt-1">
            <img className=' LogoUpdated' src={require('../images/LogoUpdated.png')} alt="Image not found" />
              {/* <img src={require('../images/Logo.png')} alt="logo" className='mx-auto d-none d-lg-block' /> */}
              {/* <img src={Logo} alt="logo" className='mx-auto d-block d-lg-none'/> */}
            </div>
            <div className="col-md-10 mx-auto">
              <p className="signin-heading">Log in to Iterion!</p>
              <div className="mt-4 mb-3">
                <p className="e-heading">E-mail</p>
                <Input change={emailChange} type="email" name="email" placeholder="@gmail.com" />
                <div className='height15'>{emailError && <div className='errMsg mb-2'>{emailError}</div>}</div>
              </div>
              <p className="e-heading">Password</p>
              <div className="pwd-container">
                <input name="pwd" placeholder="Password" type={isRevealPwd ? "text" : "password"} value={pass}
                  onChange={e => setPass(e.target.value)}/>
                <img title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd(prevState => !prevState)}/>
                <div className='height15 pb-4'>{passError && <div className='errMsg mb-4'>{passError}</div>}</div>
              </div>
              <div>
             <p className="p-heading">Role</p>
            <select onChange={roleChange} name="role" id="role">
              <option value="default" className='pe-2'>Select Role</option>
            {role.map((data) => {
               return (
     <option value={data.roleName} className='pe-2'>{data.roleName}</option>
     )})
               }
 </select>

           </div>
              <Button2 path={"/forgetpassword"} button={"Forget Password?"} />
              <div className="text-center col-12">
              {( loading ? <Spinner animation="border" className='mt-4 mb-4' variant="warning" />
                : <button onClick={SignInAuth} disabled={disable} className={disable ? "signin-btn-disable col-12 py-2 mb-4" : "signin-btn col-12 py-2 mb-4"}>Login</button>
              )}
              <p className='text-center font12 black60 mt-2'>@2022 all rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* <div className='container d-flex justify-content-center align-items-center login-back-container'>
        <div className=' bgwhite m-5 rounded-1 d-flex shadow-lg'>
          <div className='signin-image'></div>
            <div className='px-5 pb-5 d-flex flex-column login-details-container align-items-center'>
              <div className="logo pt-1">
                <img src={require('../images/Logo.png')} alt="logo" className='d-none d-lg-block' />
                <img src={Logo} alt="logo" className='d-block d-lg-none'/>
              </div>
              <p className="signin-heading">Sign in to Carrertuner</p>
              <div>
                <p className="e-heading">E-mail</p>
                <Input change={emailChange} type="email" name="email" placeholder="@gmail.com" />
                {emailError && <div className='errMsg mb-2'>{emailError}</div>}
              </div>
              <div className="pwd-container">
                <input name="pwd" placeholder="Password" type={isRevealPwd ? "text" : "password"} value={pass}
                  onChange={e => setPass(e.target.value)}/>
                <img title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd(prevState => !prevState)}/>
                {passError && <div className='errMsg mb-4'>{passError}</div>}
              </div>
              <Button2 path={"/forgetpass"} button={"Forget Password?"} />
              {( loading ? <Spinner animation="border" className='mt-4 mb-4' variant="warning" />
                : <button onClick={SignInAuth} disabled={disable || emailError} className='signin-btn col-12 py-2 mb-4'>Sign In</button>
              )}
              <p className='font12 black60'>@2022 all rights reserved</p>
            </div>
        </div>
      </div> */}
    </>
  )
}

export default SignInBS
