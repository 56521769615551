import React from "react";
import { useState, useEffect } from "react";
import eventBus from "../../js/Event-bus";
import httpvideos from "../../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
//images


const TopicDetails = ({selectedTopicData, setSelectedTopicData}) => {
    // const [selectedTopicData, setSelectedTopicData] = useState({})
    const [topicFullData, setTopicFullData] = useState([])
    const [loading, setLoading] = useState(true)
    const [renderState, setRenderState] = useState(false)
    const [removeState, setRemoveState] = useState(false)
    // const [reloadState, setReloadState] = useState(false)
    const [finalLoading, setFinalLoading] = useState(false)
    // submit assignment
    const [message, setMessage] = useState()
    // const [selectedAssignmentId, setSelectedAssignmentId] = useState(null)
    // const [selectedAssignmentRename, setSelectedAssignmentRename] = useState(null)
    // const [selectedAssignmentFile, setSelectedAssignmentFile] = useState(null)

    useEffect(() => {
        // eventBus.on("send-selected-topic-info", (data) =>{
        //     // console.log("inside course details section : ", data.message)
        //     setSelectedTopicData(data.message)
        //     if(data.message && data.message.lmsTopicMasterId){
        //         setLoading(true)
        //         // console.log("calling serice to get topic data information")
                getTopicAssignmentData(selectedTopicData.lmsTopicMasterId)
        //     }
        // });
    },[selectedTopicData])
    function getTopicAssignmentData(topicId) {
        httpvideos.get('/api/v1/topicAssignmentLMS/lmsTopicAssignmentManager?RoleId='+sessionStorage.getItem("roleId")+'&UserId='+sessionStorage.getItem('userEmail')+'&topicMasterId='+topicId+'&studentId='+sessionStorage.getItem('userId'))
        .then((response) => {
            console.log("topic data information : ", response.data)
            if(response.data && response.data.length > 0){
                if(response.data[0].assignmentMasterVO && response.data[0].assignmentMasterVO.length>0){
                    for(var i =0; i < response.data[0].assignmentMasterVO.length-1; i++){
                        response.data[0].assignmentMasterVO[i].selectedAssignmentFile = null;
                        response.data[0].assignmentMasterVO[i].finalLoading = false;
                    }
                }
            }
            setTopicFullData(response.data)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }
    function UpdateTopicAssignmentData(topicId, assignment,index){
        httpvideos.get('/api/v1/topicAssignmentLMS/lmsTopicAssignmentManager?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&topicMasterId='+topicId+'&studentId='+sessionStorage.getItem('profileid'))
        .then((response) => {
            console.log("render data : ", response.data)
            if(response.data && response.data.length > 0){
                let assignmentsArray = response.data[0].assignmentMasterVO;
                if( assignmentsArray && assignmentsArray.length>0){
                    for(var i =0; i < assignmentsArray.length; i++){
                        if(assignmentsArray[i].assignmenId == assignment.assignmenId){
                            console.log("selected updated : ", assignmentsArray[i])
                            topicFullData[0].assignmentMasterVO[index] = assignmentsArray[i];
                            setTopicFullData(topicFullData)
                            // setReloadState(assignment.assignmenId)
                        }
                        // for (var j=0; j < topicFullData[0].assignmentMasterVO.length-1;j++){
                        //     if(assignmentsArray[i].assignmenId == topicFullData[0].assignmentMasterVO[j].assignmenId){
                        //         topicFullData[0].assignmentMasterVO[j] = assignmentsArray[i]
                        //     }
                        // }
                    }
                }
                
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    // function selectAssignment(e) {
    //     console.log("selected asshimeys : ", e.target.value)
    //     console.log("selected asshimeys : ", e.target.name)
    //     console.log("selected asshimeys : ", e.target.innerHTML)
    //     let assignmentId = topicFullData[0].assignmentMasterVO[e.target.value].assignmenId;
    //     let assignmentName = topicFullData[0].assignmentMasterVO[e.target.value].assignmentName;
    //     setSelectedAssignmentId(assignmentId)
    // }
    function chooseFilesopen(assignment){
        // console.log("assignment selected : ", assignment)
        // setSelectedAssignmentId(assignment.assignmenId)
        // let filename = sessionStorage.getItem('profileid')+'_'+sessionStorage.getItem('fullName')+'_'+assignment.assignmentName+'_answerset'
        // setSelectedAssignmentRename(filename)
        document.getElementById("postfileUpload"+assignment.assignmenId).click()
    }
    function onAssignmentFileChanged(event, assignment, i) {
        assignment.selectedAssignmentFile = event.target.files[0]
        topicFullData[0].assignmentMasterVO[i] = assignment;
        setTopicFullData(topicFullData)
        setRenderState(assignment.assignmenId)
        // setSelectedAssignmentFile(event.target.files[0])
        // console.log("selected file : ", event.target.files[0])
        // console.log("selected assignmentid : ", selectedAssignmentId)
        // console.log("selected assignmentrename : ", selectedAssignmentRename)
        // console.log("selected file : ", topicFullData)
    }
    function submitAssignmentFinal(assignment,i) {
        setFinalLoading(assignment.assignmenId)
        let filename = sessionStorage.getItem('profileid')+'_'+sessionStorage.getItem('fullName')+'_'+assignment.assignmentName+'_answerset'
        let fd = new FormData()
        var obj = {
            assignmentId : assignment.assignmenId,
            studentId : sessionStorage.getItem('profileid'),
            assignmentResponseAttachmentFileName : filename,
            assignmentSubmittedDate : null
        }
        // console.log("final obj : ", obj)
        // console.log("final file : ", assignment.selectedAssignmentFile)
        var pblob = new Blob([JSON.stringify(obj)], { type: "application/json" })
        fd.append("submitAssignmentRequestVO", pblob)
        fd.append("attachmentFiles", assignment.selectedAssignmentFile)
        httpvideos.post('/api/v1/topicAssignmentLMS/updateAssignmentResponseLinkURL',fd, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept" : "*/*"
              }
        })
        .then((response) => {
            console.log("saving assignment info information : ", response.data)
            setMessage(response.data)
            setTimeout(() => {
                setMessage()
            }, 1600);
            if(response.data !== "Record Not Found"){
                clearData()
                UpdateTopicAssignmentData(selectedTopicData.lmsTopicMasterId, assignment,i)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    function deleteAssignment(assignment,i){
        console.log("delete assignment : ", assignment)
        setFinalLoading(assignment.assignmenId)
        httpvideos.delete('/api/v1/topicAssignmentLMS/lmsDeleteAssignmentsFromS3?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&assignmentId='+assignment.assignmenId+'&studentId='+sessionStorage.getItem('profileid'))
        .then((response) => {
            console.log("deleting assignment : ", response.data)
            setMessage(response.data)
            setTimeout(() => {
                setMessage()
            }, 1600);
            clearData()
            UpdateTopicAssignmentData(selectedTopicData.lmsTopicMasterId, assignment,i)
        }).catch((e) => {
            console.log(e)
        })
    }
    function clearData() {
        let dropdown = document.getElementById('assignmentdropdown')
        if(dropdown) {
            dropdown.value = '';
        }
        setRemoveState(false)
        setRenderState(false)
        setFinalLoading(false)
        // setSelectedAssignmentId(null)
        // setSelectedAssignmentRename(null)
        // setSelectedAssignmentFile(null)
    }
    function RemoveAssignmentDocs(e, assignment){
        setRenderState(null)
        setFinalLoading(null)
        assignment.selectedAssignmentFile = null;
        setRemoveState(assignment.assignmenId)
        console.log(assignment)
        // setSelectedAssignmentFile(null)
        // setSelectedAssignmentId(null)
        // setSelectedAssignmentRename(null)
    }
    return (
        
        <div className="">
            {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
            <div>
                {(topicFullData.length > 0 &&topicFullData[0].lmsTopicMaster) ? <h5 className="my-4">{topicFullData[0].lmsTopicMaster.topicName}</h5> : <h5>Topic Name</h5>}
                <h6>Description</h6>
                {(topicFullData.length>0 &&topicFullData[0].lmsTopicMaster && topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink) ? <a href={topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink} className="orangeCol" target="_blank">Topic description</a> : <p className="font16 black60 bold">No Description found</p>}
            
                {/* <h6>Exhibit File</h6>
                  <h6>Premative Assignmentm 1.pdf</h6> */}

                {/* <div className="d-flex flex-column align-items-center mx-auto border-file-upload mt-4">
                    <img
                      src={require("../../images/upload.png")}
                      alt="Image not found"
                      className=""
                    />
                    <h6 className="mt-3">Exhibit File</h6>
                    <span className="font12 black mb-3">Note: Please select an assignment from dropdown before upload any file</span>
                    <input type="file" className="form-control-file" id="postfileUpload"  multiple  accept="*" onChange={onAssignmentFileChanged}
                        onClick={chooseFilesopen} hidden/>
                    <button className="profile-buttons" onClick={chooseFilesopen} disabled={!selectedAssignmentId}>Upload</button>
                </div> */}
                {/* {topicFullData.length>0 && topicFullData[0].assignmentMasterVO && topicFullData[0].assignmentMasterVO.length>0 ?
                <div className="d-flex flex-column mt-3">
                    {topicFullData[0].assignmentMasterVO.map((assignment,i)=>{
                        return (
                            (assignment.assignmentSubmitionFlagVO.assignmentSubmittedFlag=='Y'? 
                            <div className="border-black60 d-flex justify-content-between radius5 p-1 ps-md-4 pe-md-4 mt-2">
                                <a href={assignment.assignmentSubmitionFlagVO.s3PreSignedAssignmentLink} target="_blank">{assignment.assignmentName}</a>
                                <img src={require("../../images/closebtn.png")}  onClick={(e) => deleteAssignmentDocs(e, assignment, i)} alt="delete" width="20px" className="pointer"/>
                            </div> : '')
                        )
                    })}
                </div>
                : '' } */}
            </div>
            }
        </div>
    )
}
export default TopicDetails;