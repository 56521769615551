// import React from 'react'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import httpuserprofile from "../../js/http-lms_userprofile"
import React, { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import NoRecFound from "../../components/NORecFound";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import CreateUsers from "./CreateUsers";
import DatePicker from "react-datepicker";
import UpdateUser from "../../pages/UpdateUser";

const ListOfUsers = () => {
    //data
    const [loading, setLoading] = useState(false)
    const [SName, setSName] = useState()
    const [listofUsers, setListofUsers] = useState([])
    const [status, setStatus] = useState()
    const [index, setIndex] = useState()
    const [userId, setUserId] = useState()
    const [statusPop, setStatusPop] = useState(false)
    const[path, setPath] = useState()
    


    

const [dataPerPage, setDataPerPage] = useState(20)

const [totalPages, setTotalPages] = useState()

const [itemOffset, setItemOffset] = useState(0);

const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

const [currentItems, setCurrentItems] = useState([]);

const [show, setShow] = useState(false);

const [form, setForm] = useState('')

const [tableData, setTableData] = useState()

useEffect(() => {
  setTotalPages(Math.round(listofUsers.length/dataPerPage))
}, [listofUsers])

useEffect(() => {
  setEndOffset(itemOffset + dataPerPage)
}, [itemOffset, listofUsers])

useEffect(() => {
  setCurrentItems(listofUsers.slice(itemOffset, endOffset))
  console.log(listofUsers.slice(itemOffset, endOffset), 'll')
}, [endOffset, listofUsers])

const handlePageClick = (event) => {
  const newOffset = (event.selected * dataPerPage) % listofUsers.length;
  setItemOffset(newOffset);
  if(event.selected===totalPages-1) {
    console.log('this is the final page');
  }
};    

  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });


  const handleDataPerPageChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === "all") {
      setDataPerPage(listofUsers.length);
    } else {
      setDataPerPage(event.target.value);
    }
  };



    //API call when page loader
    useEffect(() => {
      
      getOtherUsersList()

    },[])

    useEffect(()=>{
      setPath(window.location.pathname)
    },[])


    function getOtherUsersList()
    {
      setLoading(true)
      httpuserprofile
        .get("/userprofileLMS/getAllUsers")
        .then((res) => {
          console.log("list of users", res)
          if(res.data !== 'No records found' && res.data.length > 0){
          setListofUsers(res.data)
          }
          setLoading(false)
        }).catch((e) => {
          console.log(e)
          setLoading(false)
        })
    }

    

    //navigation
    //useNavigate and handleClickAdmin is used to redirect to adminprofile route
    const navigate = useNavigate();

    function handleClickAdmin(data){
      console.log("Data is:",data)
      localStorage.setItem('path', window.location.pathname)
        navigate("/updateuser", {state:{path: path, data:data}});
    }

    //events
    const statusPopup = (e, i) => {
        if(e.target.innerText===listofUsers[i].status) {
          return null
        }
        else {
          console.log(listofUsers[i].userId)
          setUserId(listofUsers[i].userId)
          setStatusPop(true)
          setSName(listofUsers[i].name)
          setStatus(e.target.innerText)
          setIndex(i)
        }
    }
     // The changeStatus function is used to change the state of user. E.g. Active/InActive
    const changeStatus=()=>{
        httpuserprofile.put('userprofileLMS/updateUserStatus?userId='+userId+'&status='+status)
        .then((response) => {
          console.log("after update user status : ", response.data)
          listofUsers[index].status=status
          setStatusPop(false)
        }).catch((e) => {
          console.log(e.response)
        }) 

    }


    const handleShow = (data) => 
    {
      setShow(true);
      // console.log(data)
      setTableData(data)

    }



    


      
  return (
    <>

<UpdateUser setShow={setShow} tableData={tableData} show={show} />

    {/* status update popup */}
    {statusPop && <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
        <div className=" d-flex flex-column P-Update-Success-4 rounded-2 position-relative ">
            <img onClick={() => {setStatusPop(false)}} src={require('../../images/cancel.png')} alt="Image not found" className="cancelimg2 position-absolute" />
            <h5 style={{fontWeight:"400"}} className="mb-5 mt-4 pt-5 mx-3 text-center">Are you sure you want to make {SName} as {listofUsers[index].status==='ACTIVE'? 'INACTIVE' : 'ACTIVE' }?</h5>
            <div className="d-flex justify-content-around">
              <button onClick={changeStatus} className="profile-buttons ms-2">Yes Proceed</button>
              <button onClick={() => {setStatusPop(false)}} className="profile-buttons me-2">Cancel</button>
            </div>
          </div>
        </div>
    }



    
    {loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (sortedData.length > 0) ? 
        <div className="scrollme">
           <div className="d-flex justify-content-between bgwhite px-4 bottomborder"> 
           
           <div className="d-flex">
            <select className="my-4 px-1 me-5 rounded borderUpated" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value="all">All</option>
      </select>
    
      <DatePicker className='w-100 rounded heightDate mt-4' selectsEnd
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2 mt-4">-</h3>
      <DatePicker className='w-100 rounded heightDate mt-4' selectsEnd
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
                    
                    </div>
                    <CreateUsers form={form} setForm={setForm} />
                  
      
      </div>
          <table className="table bgwhite">
              <thead>
                  <tr>
                    <th scope="col">User Name <img onClick={() => handleSort('name')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Mail Id <img onClick={() => handleSort('emailId')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Phone <img onClick={() => handleSort('phoneNumber')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Created Date <img onClick={() => handleSort('creationD')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Role <img onClick={() => handleSort('role')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Status <img onClick={() => handleSort('status')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                    <th scope="col">Actions</th>
                  </tr>
              </thead>
              <tbody>
                  {sortedData.map((data, index) => {
                      return (
                      <tr>
                          <td scope="row">{data.name}</td>
                          <td>{data.emailId}</td>
                          <td>{data.phoneNumber}</td>
                          <td>{moment(data.creationD).format('MM/DD/YYYY')}</td>
                          <td>{data.role}</td>
                          <td className="d-flex justify-content-center text-capitalize"><h6 className={data.status=="ACTIVE" ? "status-active" : "status-inactive"}>{data.status}</h6></td>
                          <td>
                          {/* <img src={require("../../images/edit2.png")} onClick={()=>handleClickAdmin(data)} alt="Image not found" className="more"/> */}

                          <img src={require("../../images/edit2.png")} onClick={()=>handleShow(data)} alt="Image not found" className="more"/>

                              {/* <Dropdown>
                                  <Dropdown.Toggle className="dropdown-more p-0" variant="success" id="dropdown-basic">
                                      <img src={require("../../images/more.png")} alt="Image not found" className="more"/>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                      <Dropdown.Item value="Edit" onClick={()=>handleClickAdmin(data)}>Edit</Dropdown.Item>
                                      {data.status == 'ACTIVE' && 
                                      <Dropdown.Item eventKey="INACTIVE" value="INACTIVE" onClick={(e) => statusPopup(e, index)} >INACTIVE</Dropdown.Item>
                                      }
                                      {data.status == 'INACTIVE' && 
                                      <Dropdown.Item eventKey="ACTIVE"  value="ACTIVE" onClick={(e) => statusPopup(e, index)} >ACTIVE</Dropdown.Item>
                                    }
                                  </Dropdown.Menu>
                              </Dropdown> */}
                          </td>
                      </tr>
                      );
                  })}
              </tbody>
          </table>
          <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
        </div>
        : <div className='col-12 text-center'>
          <NoRecFound error="No admin users found." />
        </div>
      }</div>
    }
    </>
  )
}

export default ListOfUsers
