import React, { useRef } from 'react'
import Sidebar from "../pages/Sidebar.jsx";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import {useState, useEffect} from "react";
import httpassignments from '../js/http-lms_assignments'
import AddAssignment from '../components/AddAssignment.jsx';
import Select from 'react-select'


const ManageProgandAssignments = () => {

  const fileUpload = useRef(null)

    const [loading, setLoading] = useState(1)

    const [trainerName, setTrainerName] = useState([])

    const [ formData, setFormData] = useState({})

    const [ programsList, setProgramsList] = useState([])

    const [ modules, setModules] = useState([])

    const [ topics, setTopics] = useState([])

    const [ selectedTrainerOption, setSelectedTrainerOption] = useState(null)

    const [ selectedProgramOption, setSelectedProgramOption] = useState(null)

    const [ selectedModuleOption, setSelectedModuleOption] = useState(null)

    const [ selectedTopicOption, setSelectedTopicOption] = useState(null)
    
    const [ topicName, setTopicName] = useState()

    const [video,setVideo] = useState()

    const [disable, setDisable] = useState(true)

    const [pop, setPop] = useState(false);

    const [error, setError] = useState()


    const colourStyles = {
      control: (styles) => ({ 
          ...styles, 
       }),
      option: (provided, state) => ({
        ...provided,
        fontWeight: state.isSelected ? "bold" : "normal",
        color: state.isFocused || state.isSelected ? 'white' : 'black',
        backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
        fontSize: "14px",
        paddingTop: "4px",
        paddingBottom: "4px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: state.data.color,
        fontSize: state.selectProps.myFontSize
      })
    };



  

    useEffect(() => {
      if((formData.programName && formData.topicId)) {
        console.log("false")
        setDisable(false)
      }
      else {
        console.log("true")
        setDisable(true)
      }
    }, [formData])



    useEffect(() => {
        //fetching college list
        // setLoading(true)
        httpassignments
          .get("/api/v1/topicAssignmentLMS/getTrainerNamesList")
          .then((res) => {
            console.log("list of Trainers", res)
            setTrainerName(res.data)
            // setLoading(1)
          }).catch((e) => {
            console.log(e)
            // setLoading(1)
          })
      },[])


  



     function getProgramList(id)
{

    httpassignments
    .get(`/api/v1/topicAssignmentLMS/getprogramListByTrainerId?profileId=${id}`)
    .then((res) => {
      console.log("list of programs", res)
      setProgramsList(res.data)
      // setLoading(1)
    }).catch((e) => {
      console.log(e)
      // setLoading(1)
    })

}


function getModules(progId)
{

  console.log("hdkajhlksjdf")

    httpassignments
    .get(`/api/v1/topicAssignmentLMS/getModuleNamesByProgramId?programId=${progId}`)
    .then((res) => {
      console.log("list of modules", res)
      setModules(res.data)
      // setLoading(1)
    }).catch((e) => {
      console.log(e)
      // setLoading(1)
    })
}



function getTopics(moduleId){
    
    httpassignments
    .get(`/api/v1/topicAssignmentLMS/getTopicNamesByModuleId?moduleId=${moduleId}`)
    .then((res) => {
      console.log("list of topics", res)
      setTopics(res.data)
      // setLoading(1)
    }).catch((e) => {
      console.log(e)
      // setLoading(1)
    })
}




// const uploadVideo = (file) => {
//   const formData = new FormData();
//   formData.append("video", file);
//   formData.append("videoType", "online");
//   formData.append("topicId", "302");
//   formData.append("programName", "java");

//   axios.post("http://localhost:13056/api/v1/topicAssignmentLMS/uploadRecordedVideo", formData)
//     .then(response => {
//       console.log("Video uploaded successfully");
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }



// const uploadAssignments = (file) => {
//   const formData = new FormData();
//   formData.append("video", file);
//   formData.append("videoType", "online");
//   formData.append("topicId", "302");
//   formData.append("programName", "java");

//   axios.post("http://localhost:13056/api/v1/topicAssignmentLMS/uploadAssignments", formData)
//     .then(response => {
//       console.log("Assignmen5 uploaded successfully");
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  setVideo(file);

  console.log(file, 'lllllllllllllllll')

  let form = new FormData();

  var pblob = new Blob([JSON.stringify(file)], { type: "application/json" })
  form.append('file', pblob);
  httpassignments
  .post(`/api/v1/topicAssignmentLMS/uploadRecordedVideo?videoType=trainer&topicId=${formData.topicId}&programName=${formData.programName}`, form, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept" : "*/*"
              }
        })
  .then((res) => {
    console.log("Upload Status", res)
    if(res.data == 'video uploaded successfully'){
      // resetEntireForm()
      // setShow(false); 
      setPop(true)
  setTimeout(() => {
    setPop(false)
  }, 1500)
    }
    else if(res.data == 'Video already exists please upload another video'){
      // setSubmitLoading(false)
      setError(res.data)
    }
    // setTopics(res.data)
    // setLoading(1)
  }).catch((e) => {
    console.log(e)
    // setLoading(1)
  })
}








function handleTrainerChange(e){
  console.log("selected trainer", e)
  
  setSelectedTrainerOption(e)
  setSelectedProgramOption(null);
  setSelectedModuleOption(null)
  setSelectedTopicOption(null)

  setVideo({})

  setTopicName([])
  

  setFormData((pValue) => {
    console.log(pValue)
    return {
      ...pValue,
      ["programName"]: null,
      ["topicId"]:null
    }
  })


  // formData.programName = null;

  // formData.topicId = null;

  getProgramList(e.profileId)

}






function handleProgramChange(e){
  console.log("selected program", e)
 
  setSelectedProgramOption(e)
  setSelectedModuleOption(null)
  setSelectedTopicOption(null)
  setVideo({})
  

  setTopicName([])

  // formData.topicId = null;

  

  setFormData((pValue) => {
    console.log(pValue)
    return {
      ...pValue,
      ["programName"]: e.lmsProgramName,
      ["topicId"]: null
    }
  })

  console.log(formData)

  getModules(e.lmsProgramId)
  
}




function handleModuleChange(e) {
  console.log("selected module : ", e)
  setSelectedModuleOption(e)
  setVideo({})

  setFormData((pValue) => {
    console.log(pValue)
    return {
      ...pValue,
      ["topicId"]: null
    }
  })

  setSelectedTopicOption(null)

  setTopicName([])

  console.log(formData)

  getTopics(e.lmsModuleId)

}




function handleTopicChange(e) {
  console.log("selected topic : ", e)
  setSelectedTopicOption(e)

  setVideo({})

  setTopicName(e.topicName)

  setFormData((pValue) => {
    console.log(pValue)
    return {
      ...pValue,
      ["topicId"]: e.lmsTopicMasterId,
    }
  })

 console.log("FINAL FORMDATA", formData)

}









      // function handleChange(e) {
      //   // setErrorMessage('')

      //   const {name, value} = e.target;
      //   setFormData((pValue) => {
      //     console.log(pValue)
      //     return {
      //       ...pValue,
      //       [name]: value
      //     }
      //   })
      //   console.log(formData, "form data is :::::::");
        
      //   console.log(trainerName, "tytytytyty")
      //   console.log(e.target.value, 'llllllll');
      //   let id = trainerName.filter((x) => {
      //       return e.target.value===x.trainerName
      //   })

      //   console.log(id, 'iiiiiiiiii');
      //   getProgramList(id[0].profileId)


        
      //  let progId = programsList.filter((y) => {
      //   return e.target.value = y.lmsProgramName
      //  })

      //  console.log(progId,"okokokokoko")
      //  getModules(progId[0].lmsProgramId)



      //  getTopics(progId[0].lmsModuleId)


       
      // }


  return (
    <>

{pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully video has been updated</p>
      </div>
    </div>}
      
      <Sidebar />

        {/* Main Container */}

        <div className="dashboard-container mx-auto mt120">
          {/* <Navb result={result} /> */}

          <div className="mb-2"></div>


          <div className=" bgwhite shadow mt-4 mb-5 mx-1 mx-md-4">

            <h3 className='ps-5 pt-5'>Manage Program & Assignments</h3>


          <div className="form-container px-5">
                <Form id="create-User-Form">
                  <Row className="pb-3">
                    <Col className="col-lg-6 col-12 mt-5 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Trainer</h6></div>
                      


                    <Select
                        value={selectedTrainerOption}
                        onChange={handleTrainerChange}
                        placeholder="Select Trainer"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.trainerName}`
                        }
                        options={trainerName}
                      />


                      {/* <p className="red font12">{formErros.yearOfGraduation}</p> */}
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Program</h6></div>
                      

                    <Select
                        value={selectedProgramOption}
                        onChange={handleProgramChange}
                        placeholder="Select Program"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.lmsProgramName}`
                        }
                        options={programsList}
                      />



                      {/* <p className="red font12">{formErros.yearOfGraduation}</p> */}
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Module</h6></div>
                     
                    <Select
                        value={selectedModuleOption}
                        onChange={handleModuleChange}
                        placeholder="Select Module"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.lmsModuleName}`
                        }
                        options={modules}
                      />
                      {/* <p className="red font12">{formErros.yearOfGraduation}</p> */}
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required"> <h6>Select Topic</h6></div>
                    

                    <Select
                        value={selectedTopicOption}
                        onChange={handleTopicChange}
                        placeholder="Select Topic"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.topicName}`
                        }
                        options={topics}
                      />

                      {/* <p className="red font12">{formErros.yearOfGraduation}</p> */}
                    </Col>
                </Row>

                <h4 className='my-4'>Topic Info</h4>


                <Row className="pb-4">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <Form.Label>Topic Name</Form.Label>
                      <Form.Control
                        // className={formErros.firstName ? "borderred" : ""}
                        placeholder="Topic Name"
                        value={topicName}
                        autoComplete="off"
                        name='topicName'
                        disabled
                      />
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <Form.Label>Topic Description</Form.Label>
                 <Form.Control  as="textarea"  maxLength={40} rows={3}  />
                 </Col>
                 </Row>

                  <Row className="pb-4">
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <Form.Label>Video Link</Form.Label>
                    <div className='d-flex justify-content-end pe-2 py-3 updatedesign'>
                    {error && <div className='pe-3 pt-2'>{error}</div>}
                     {video && <div className='pe-3 pt-2'>{video.name}</div>}
                    <button disabled={disable} onClick={(e) => {e.preventDefault(); fileUpload.current.click()}} className={disable ? "updatebtndisable px-2 me-2" :'updatebtn  px-2 me-2'}>Update Video</button>
                    </div>
                 </Col>
                 </Row>


             </Form>

             <div>
    <input ref={fileUpload} style={{display: 'none'}} type="file" accept="video/mp4,video/x-m4v,video/*" onChange={handleFileUpload} />
  </div>

             <AddAssignment fd={formData} dis={disable}/>
             </div>   



















          </div>




          </div>
    </>
  )
}

export default ManageProgandAssignments
