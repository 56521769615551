import React from "react";
import { useState, useEffect } from "react";
import httpuserprofile from '../js/http-lms_userprofile';
import closebtn from '../images/close_btn.svg'
import Spinner from 'react-bootstrap/Spinner';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import showPwdImg from '../images/showpwd.svg';
import hidePwdImg from '../images/hidepwd.svg';
import { Button, Modal } from 'react-bootstrap';

const 
UpdatePassInside = ({selectedAssignment, onClose}) => {
    const [isRevealPwd1, setIsRevealPwd1] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);
    const [isRevealPwd3, setIsRevealPwd3] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setreNewPassword] = useState('');

    const [finalLoading, setFinalLoading] = useState(false)
    const [message, setMessage] = useState()
    const [errormessage, setErrorMessage] = useState()
    const [selectedAssignmentFile, setSelectedAssignmentFile] = useState(null)
    function closeUpdatePassword(status) {
        onClose(status)
    }
    function updatingPassword() {
        setFinalLoading(true)
        setMessage(null)
        setErrorMessage(null)
        let passwordObj = {
            userEmail : sessionStorage.getItem('userEmail'),
            newPassword : newPassword,
            oldPassword : oldPassword,
            authCode:"miidBM390"
        }
        httpuserprofile.put('userprofileLMS/changePassword',passwordObj)
        .then((response) => {
            console.log("saving assignment info information : ", response.data)
            setMessage(response.data)
            setFinalLoading(false)
            if(response.data == 'Password Updated'){
                setTimeout(() => {
                    setMessage()
                }, 1600);
                setTimeout(() => {
                    closeUpdatePassword('SUCCESS')
                }, 2000);
            }
        }).catch((e) => {
            console.log(e)
            setFinalLoading(false)
        })
    }
    function clearData() {
        setFinalLoading(false)
        closeUpdatePassword('SUCCESS')
    }
    function oldPassChange(e) {
        setOldPassword(e.target.value)
        // checkPassword()
    }
    function newPassChange(e) {
        setNewPassword(e.target.value)
        // checkPassword()
    }
    function reNewPassChange(e) {
        setreNewPassword(e.target.value)
        // checkPassword()
    }
    function checkPassword(){
        if(oldPassword == newPassword){
            console.log("old password is matching")
            setErrorMessage("Old password should not match with new password")
        }else{
            console.log("old password is not matching")
            if(newPassword.length >= 8){
                let format = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                if (format.test(newPassword)) {
                    setErrorMessage()
                    if(newPassword === reNewPassword){
                        console.log("new password matching with enter password")
                        // setMessage("New password should match with re-enter new passwor")
                        setErrorMessage("")
                        updatingPassword()
                    }else{
                        setErrorMessage("New password should match with re-enter new passwor")
                    }
                } else {
                    setErrorMessage("Minimum 8 characters, 1 capital letter, 1 special character and 1 number")
                }
            }else{
                setErrorMessage("Minimum 8 characters, 1 capital letter, 1 special character and 1 number")
            }
        }
      }


//       const [show, setShow] = useState(false);

//     const handleShow = () => setShow(true);

//  const handleClose = () => {
//     setShow(false); 
//   }



    return (

    //     <>

    //     <Button className='batchbtn col-12 mb-4' onClick={handleShow}>
    //     Change Password
    //   </Button>



    //   <Modal
    //     show={show}
    //     onHide={handleClose}
    //     dialogClassName={`modal-left h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
    //     aria-labelledby="example-custom-modal-styling-title"
    //   >
    //     <Modal.Header closeButton>
    //       <Modal.Title id="example-custom-modal-styling-title">
    //         Add New Batch
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
        

    //     </Modal.Body>
    //   </Modal>

    //   </>

        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
            <div className="card radius10 border-0 col-11 col-md-8 col-lg-3 d-flex flex-column">
                <div className="col-12 p-2 py-3 radius10 border-0 bold1 background-gray font18 text-center">
                    <span>Change Password</span> 
                    <img src={closebtn} alt="close" width="25px" className="closebtn mouseoverstyles1 radiushalf pointer border-0" onClick={() => closeUpdatePassword('NOT SUCCESS')}/>
                </div>
                <div className="col-12 p-1 d-flex flex-column">
                    {message && <span className="mb-2 text-center mx-auto font14 blue height15">{message}</span>}
                    {errormessage && <span className="mb-2 text-center mx-auto font14 red height15">{errormessage}</span>}
                    <Form className=" d-flex flex-column align-items-center">
                  <Form.Group
                    style={{ width: "90%" }}
                    className=""
                    controlId="formBasicPassword"
                  >
                    <label className="required font14"> Old Password</label>
                    <div className="pwd-container">
                        <input name="Opass" placeholder="Enter New Password" autoComplete="off" maxLength="16" type={isRevealPwd1 ? "text" : "password"} value={oldPassword}
                        onChange={oldPassChange}/>
                        <img title={isRevealPwd1 ? "Hide password" : "Show password"} src={isRevealPwd1 ? hidePwdImg : showPwdImg}
                        onClick={() => setIsRevealPwd1(prevState => !prevState)}/>
                    </div>
                    <label className="required font14"> New Password</label>
                    <div className="pwd-container">
                        <input name="Npass" placeholder="Enter New Password" autoComplete="off" maxLength="16" type={isRevealPwd2 ? "text" : "password"} value={newPassword}
                        onChange={newPassChange}/>
                        <img title={isRevealPwd2 ? "Hide password" : "Show password"} src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                        onClick={() => setIsRevealPwd2(prevState => !prevState)}/>
                    </div>
                    <label className="required font14"> Re - type New Password</label>
                    <div className="pwd-container">
                        <input name="RNpass" placeholder="Enter New Password" autoComplete="off" maxLength="16" type={isRevealPwd3 ? "text" : "password"} value={reNewPassword}
                        onChange={reNewPassChange}/>
                        <img title={isRevealPwd3 ? "Hide password" : "Show password"} src={isRevealPwd3 ? hidePwdImg : showPwdImg}
                        onClick={() => setIsRevealPwd3(prevState => !prevState)}/>
                    </div>
                  </Form.Group>
                </Form>
                </div>
                <div className="footer text-center p-3">
                    {finalLoading ? <div className="col-10 text-center mx-auto"> <Spinner animation="border" className="spinner" variant="secondary" /> </div> :
                    <button className={(oldPassword.length >= 8 && newPassword.length >= 8 && reNewPassword.length >=8) && (oldPassword!==newPassword) && (newPassword == reNewPassword) ? "background-organe white w-100 border-0 p-1 radius15":"w-100 border-button p-1 black60 background-accordion radius15"} 
                    onClick={() => {checkPassword()}} disabled={(oldPassword.length < 8 || newPassword.length < 8 || reNewPassword.length <8) || (oldPassword==newPassword) || (newPassword !== reNewPassword)}>
                        Update Password
                    </button>
                    }
                </div>
              </div>
            </div>
    )
}
export default UpdatePassInside