import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import httpuserprofile from "../js/http-lms_userprofile"
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from 'react-bootstrap/Spinner';
import CsvDownloader from 'react-csv-downloader'
const CreateProgramModal = (props) => {
  const [show, setShow] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedCSVfile, setSelectedCSVfile] = useState(null);
  const [disable, setDisable] = useState(true)
  const [pop, setPop] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleClose = (e) => {
    e.preventDefault();
    resetData()
    props.setRefresh(false)
    setShow(false)
  }

  const handleClosebtn = () => {
    resetData()
    props.setRefresh(false)
    setShow(false)
  }

  const handleShow = () => setShow(true);
  const columns = [{
    id: 'ProgramName',
    displayName: 'ProgramName'
  }, {
    id: 'ProgramDescription',
    displayName: 'ProgramDescription'
  }, {
    id: 'ModuleName',
    displayName: 'ModuleName'
  }, {
    id: 'ModuleDescription',
    displayName: 'ModuleDescription'
  }, {
    id: 'TopicName',
    displayName: 'TopicName'
  }, {
    id: 'TopicDescription',
    displayName: 'TopicDescription'
  }, {
    id: 'Duration',
    displayName: 'Duration'
  },{
    id: 'ProgramCode',
    displayName: 'ProgramCode'
  }];

  const datas = [
  //   {
  //   first: 'foo',
  //   second: 'bar'
  // }, {
  //   first: 'foobar',
  //   second: 'foobar'
  // }
];
  useEffect(() => {
    if(selectedCSVfile) {
      setDisable(false)
    }
    else {
      setDisable(true)
    }
  }, [selectedCSVfile])

  function resetData() {
    setErrorMessage(null)
    setSuccessMessage(null)
    setSelectedCSVfile(null)
    setSelectedFileName('')
    setSubmitLoading(false)
  }
  function UploadCSVfile(e) {
    resetData()
    console.log("file selected : ", e.target.files)
    if(e.target.files && e.target.files.length > 0){
      setSelectedFileName(e.target.files[0].name)
      setSelectedCSVfile(e.target.files[0])
      console.log("after selecte=tion : ", selectedCSVfile)
    }
  }

  function handleSubmit(e){
    e.preventDefault();
    setSubmitLoading(true)
    console.log("selectd file after submit : ", selectedCSVfile)
    let pfd = new FormData();
    pfd.append("programCSVFile", selectedCSVfile)
    httpuserprofile.post('/userprofileLMS/uploadCSVFileProgramDetails?userId='+sessionStorage.getItem('userEmail')+'&userRole='+sessionStorage.getItem('roleId'), pfd, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => {
      console.log("uploading document : ", response.data)
      setSubmitLoading(false)
      if(response.data && response.data.message === 'Data saved successfully'){
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          resetData()
          props.setRefresh(true)
          setShow(false)
        },2000)
      }else{
        setSelectedCSVfile(null)
        setSelectedFileName('')
        setErrorMessage(response.data.message)
        setSuccessMessage(null)
      }
    }).catch((e) => {
      console.log("error while uploading : ", e.response)
      setSubmitLoading(false)
      setErrorMessage(e.response.data)
      setSuccessMessage(null)
    })

  }

  return (
      <>
      {pop && 
        <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
          <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
            <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
            <p className='text-center w213 '>Sucessfully new batch has been created</p>
          </div>
        </div>
      }
      <Button className='batchbtn col-12 py-2 mb-4' onClick={handleShow}>
        Add Program
      </Button>

      <Modal show={show} onHide={handleClosebtn} className=""
        dialogClassName={`modal-left-small h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id='create-batch-form'>
            <Row className="pb-4">
              <Col className="col-12 d-flex flex-column align-items-start">
                <div className='bordergray radius5 d-flex p-1 col-10'>
                  <input type="text" disabled name="filename" id="filename" value={selectedFileName} className='border-0 ps-2 col-8' placeholder='Upload CSV File' />
                  <label htmlFor='broswefile_btn' className='radius5 col-4 border-0 p-1 UpdatedMainBGColor white text-center pointer'>Browse File</label>
                  <input type="file" name="upload file" id="broswefile_btn" hidden accept=".csv" placeholder='Choose File' onChange={UploadCSVfile}/>
                </div>
                {errorMessage && <div className="text-muted red mt-3">{errorMessage}</div>}
                {successMessage && <div className="text-muted green mt-3">{successMessage}</div>}
                <Form.Text className="text-muted bold">Note : </Form.Text>
                <Form.Text className="text-muted">1. One program can be added in one file</Form.Text>
                <Form.Text className="text-muted">2. Need to maintain unique module & topic names and description </Form.Text>
                <Form.Text className="text-muted">3. Uploaded file should have minimum 5 modules information </Form.Text>
                <Form.Text className="text-muted">4. Download the document format update content and upload here 
                <CsvDownloader filename="programfile" extension=".csv" columns={columns} datas={datas}><span className='blue'>Document 1</span></CsvDownloader>
                </Form.Text>
              </Col>
            </Row>
            <div className='d-flex justify-content-around fixed-bottom'>
              {submitLoading ? <div className="col-lg-4 col-12 mt-3 mb-5 text-center">
                <Spinner className="mx-auto" animation="border" variant="secondary"/>
                </div> :
                <button onClick={handleSubmit}  disabled={disable} className={ disable ? "radius5 p-2 mt-3 border-0 mb-5 grey col-5 mr-2" : "radius5 border-0 col-5 p-2 mt-3 mb-5 btn-primary"}>Create</button>
              }
              <button onClick={handleClose} className="radius5 p-2 mt-3 mb-5 border-0 col-5 grey ml-3 ml">Cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>  
    </>
  );
}

export default CreateProgramModal
