import { useState } from "react";
import React, { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import CircularSlider from '@fseehawer/react-circular-slider';
import '../../styles/PopUp.css'
import httpassignments from '../../js/http-lms_assignments'
import NoRecFound from "../../components/NORecFound";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";

const AssignmentsList = () => {
  
    //data
    const [loading, setLoading] = useState(false)
    const [assignmentsList, setAssignmentsList] = useState([])

    const [listofAssignments, setListofAssignments] = useState([])

    //API call when page loader
    
    useEffect(() => {
    getAssignmentList();
    },[])


    function getAssignmentList(){
      
        setLoading(true)

          httpassignments.get("/api/v1/topicAssignmentLMS/getAssignmentList")
          .then((res) => {
            console.log("Assignment List:", res)
            setListofAssignments(res.data)
            setLoading(false)
          }).catch((e) => {
            console.log(e)
            setLoading(false)
          })
        
    }





    const [pop2, setPop2] = useState(false);
    function formPop2(e) {
        e.preventDefault();
        setPop2((pValue) => !pValue);
    }

  
  const [dataPerPage, setDataPerPage] = useState(20)

  const [totalPages, setTotalPages] = useState()

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setTotalPages(Math.round(listofAssignments.length/dataPerPage))
  }, [listofAssignments])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset, listofAssignments])

  useEffect(() => {
    setCurrentItems(listofAssignments.slice(itemOffset, endOffset))
    console.log(listofAssignments.slice(itemOffset, endOffset), 'll')
  }, [endOffset, listofAssignments])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % listofAssignments.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };    

  
  
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  
    useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });

    const handleDataPerPageChange = (event) => {
      console.log(event.target.value);
      if (event.target.value === "all") {
        setDataPerPage(listofAssignments.length);
      } else {
        setDataPerPage(parseInt(event.target.value));
      }
    };


  return (
    <>
    {/* status update popup */}
    {pop2 && (
        <div className=" position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
            <div className="col-12 d-flex flex-column score-card rounded-2 ">
                <div className="add-prog-pop rounded-top">
                    <h4 className="my-3 font20">Add Score & Feedback</h4>
                    <img onClick={formPop2} src={require("../../images/cancel.png")} alt="Image not found" className="cancelimg my-2 me-3"/>
                </div>
                <div className="popup pt-3 pb-3 p-2">
                <CircularSlider
                    label="Score"
                    labelColor="#005a58"
                    knobColor="#c85400"
                    progressColorFrom="#ff9548"
                    progressColorTo="#ff9548"
                    progressSize={24}
                    trackColor="#eeeeee"
                    trackSize={24}
                    data={["1","2","3","4","5","6","7","8","9","10"]} //...
                    dataIndex={10}
                    onChange={ value => { console.log(value); } }
                />
                <Form className=" d-flex flex-column align-items-center">
                    <Form.Group className="col-12" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{ fontWeight: "350" }}> Enter Feedback</Form.Label>
                        <Form.Control style={{ height: "114px" }} as="textarea" rows={3}/>
                    </Form.Group>
                </Form>
                </div>
                <button className="my-4 atb-btn ">Add</button>
            </div>
        </div>
    )}

    {loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>


{loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (listofAssignments.length > 0) ? 
        <div className="scrollme">
          <div className="d-flex bgwhite px-4 bottomborder">  <select className="my-4 px-1 rounded borderUpated me-5" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value="all">All</option>
      </select>
      <div className="d-flex my-4">
      <DatePicker className='w-100 rounded heightDate' selectsEnd
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2">-</h3>
      <DatePicker className='w-100 rounded heightDate' selectsEnd
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
      </div>
      </div>
          <table className="table bgwhite">
                <thead>
                    <tr>
                    <th scope="col" >Assignment Name<img onClick={() => handleSort('assignmentName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Program Name<img onClick={() => handleSort('programName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Module Name <img onClick={() => handleSort('moduleName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Topic Name <img onClick={() => handleSort('topicName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assign Document <img onClick={() => handleSort('assignmentName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        {/* <th scope="col">Assign</th> */}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((data, index) => {
                        return (
                            <tr key={index+'tr'}>
                                <td>{data.assignmentName ? data.assignmentName : '--'}</td>
                                <td>{data.programName}</td>
                                <td>{data.moduleName}</td>
                                <td>{data.topicName}</td>
                                <td><div> <a  href={data.preAssignedUrl} target="_blank" className={data.preAssignedUrl ? 'blue text-underline pointer underline mb-3 d-block' : 'd-block black60 mb-3'}>{data.preAssignedUrl ? data.assignmentName + '.pdf' : 'No File'}</a></div></td>
                                {/* <td><img src={require("../../images/assign.png")} alt="Image not found" className=""/></td> */}
                            </tr>
                        );
                    })}
                </tbody>
          </table>
          <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
        </div>
        : <div className='col-12 text-center'>
          <NoRecFound error="No assignments found" />
        </div>
      }</div>
    }
      </div>
    }
    </>
  )
}

export default AssignmentsList;
