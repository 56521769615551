import React from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import "../styles/SignIn.css";
import { useNavigate } from "react-router-dom";

function SignIn() {

  const navigate = useNavigate();

  function handleClick() {
    navigate("/dashboard");
  }


  return (
    <div className="flex-container">
      <div className="signin-container">
        <div className="signin-image">
          <div className="color-fill"></div>
        </div>
        <form className="signin-content">
          <div className="logo">
            <img src={require('../images/Logo.png')} alt="Image not found" />
          </div>
          <p className="signin-heading">Sign in to Carrertuner</p>
          <div>
            <p className="e-heading">E-mail</p>
            <Input type="email" name="email" placeholder="@gmail.com" />
          </div>
          <div>
            <p className="p-heading">Password</p>
            <Input type="password" name="pass" placeholder="Password" />
          </div>
          <div>
            <p className="p-heading">Role</p>
            <select name="role" id="role">
    <option value="">Choose Role</option>
    <option value="Admin">Admin</option>
    <option value="Student">Student</option>
</select>
          </div>
          <div className="additional-options">
            <div className="cookies-permission">
              <input style={{marginRight:"8px"}} type="checkbox" className="remember" name="checkbox" />
              <p>Remember me</p>
            </div>
            <Button2 path='/updatepass' button="Forget Password?" />
          </div>
          <Button path='/dashboard' class="signin-btn py-2" button="Sign In" />
          <p
            style={{
              color:"#010101",
              opacity: 0.46,
              fontSize: "8px",
              fontFamily: "Roboto",
              marginTop: "122px"
            }}
          >
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
