import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import httpuserprofile from "../js/http-lms_userprofile"
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import httpprograms from "../js/http-lms_programs"
import moment from "moment/moment";
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";

const LeftToRightModal = (props) => {

    const [show, setShow] = useState(false);
    const [program, setProgram] = useState()
    const [programId, setProgramId] = useState()
    const [programName, setProgramName] = useState()
    const [batchStatusName, setBatchStatusName] = useState()
    // const [programId, setProgramId] = useState([])
    const[batchName, setBatchName] = useState()
    const [selectedOption, setSelectedOption] = useState(null);

    const [selectedStatusOption, setSelectedStatusOption] = useState(null)

    const [disable, setDisable] = useState(true)

    const [batchStatus, setBatchStatus] = useState()

    // const [startDate, setStartDate] = useState(new Date());

    // const [endDate, setEndDate] = useState(new Date());

    const [startDate, setStartDate] = useState(moment().tz('Asia/Kolkata').toDate());

const [endDate, setEndDate] = useState(moment().tz('Asia/Kolkata').toDate());

    
    const [selectedTrainer, setSelectedTrainer] = useState(null);

    const[trainerDetails, setTrainerDetails] = useState(null)

    const[batchStatusCode, setBatchStatusCode] = useState()

    const[trainerId, setTrainerId] = useState()

    const [description, setDescription] = useState()

    const [pop, setPop] = useState(false);

    const [trainerName, setTrainerName] = useState()

    

  const handleClose = () => {

    setShow(false);
    setSelectedOption("")
    setTrainerDetails("")
    setBatchName("")
    setDescription("")
    setSelectedTrainer("")
    setStartDate(moment().tz('Asia/Kolkata').toDate())
    setEndDate(moment.tz(null))
    setSelectedStatusOption("")
      
    
  }

  const handleShow = () => setShow(true);


  useEffect(() => {
    if((selectedOption && batchName && selectedTrainer && startDate && endDate && selectedStatusOption )) {
      console.log("false")
      setDisable(false)
    }
    else {
      console.log("true")
      setDisable(true)
    }
  }, [selectedOption, batchName, selectedTrainer, startDate, endDate, selectedStatusOption ])

useEffect(()=>{

  httpprograms.get("/programsLMS/getModulesTopicCount")
    .then((res) => {
        console.log(res.data,"ProgramsSSSS")
      setProgram(res.data)
    })



    httpuserprofile
      .get("/userprofileLMS/getStaticValuesListById?indexCode=7")
      .then((res) => {
        console.log("Batch Status", res)
        setBatchStatus(res.data)
      })

},[]
)

function generateTrainer(id){

  httpuserprofile
    .get(`/userprofileLMS/getTrainerNameByProgramId?programId=${id}`)
    .then((res) => {
        console.log("Trainer Details:",res.data)
      setTrainerDetails(res.data)
      // console.log(res.data[0].lmsTrainerId, "The trainer id")
    })


}

function generateBatchName(id){

    httpuserprofile
    .get(`/userprofileLMS/generateBatchName?programId=${id}`)
    .then((res) => {
        console.log(res.data)
        setBatchName(res.data.batchName)
    })

}

const colourStyles = {
    control: (styles) => ({ 
        ...styles, 
     }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };


function programChange(e){

    console.log("changed")
    console.log(e, 'bbbbbbbbbbbbbbb')
    let programs = program.filter((data)=>{
      return data.program_name === e.program_name;
    })
    console.log('check1');
    console.log("Selected prog val:", programs)
      console.log('check2');
      setProgramName(e.program_name)
      setSelectedOption(e)

generateBatchName(programs[0].program_id)
generateTrainer(programs[0].program_id)
      }


      

      function statusChange(e){

        console.log("changed2")
        console.log(e, 'yyyyyy')
        let status = batchStatus.filter((data)=>{
          return data.indexDescription === e.indexDescription;
        })
        console.log('check1');
        console.log("Selected status val:", status)
          console.log('check2');
          setBatchStatusName(e.indexDescription)
          setBatchStatusCode(e.indexCode)
          setSelectedStatusOption(e)
    
          }

          function trainerChange(e){

            console.log("changed3")
            console.log(e, 'ttttt')
            let trainer = trainerDetails.filter((data)=>{
              return data.trainerName === e.trainerName;
            })
            console.log('check1');
            console.log("Selected trainer val:", trainer)
              console.log('check2');
              setTrainerId(e.lmsTrainerId)
              setTrainerName(e.trainerName)
              setSelectedTrainer(e)

              // console.log(e.lmsTrainerId, e.trainerName)
        
        // generateBatchName(programs[0].programId)
              }



          function handleSubmit(e){

            e.preventDefault();

            



            httpuserprofile
    .post("/userprofileLMS/createBatch", {
      batchName: batchName,
      batchDescription: description,
      batchStartDate: moment(startDate).format('YYYY-MM-DD'),
      batchEndDate: moment(endDate).format('YYYY-MM-DD'),
      batchStatus: batchStatusCode,
      lmsTrainerId: trainerId,
      actualBatchEndReason:"n/a",
      creationUser: sessionStorage.getItem("userEmail")
    })
    .then((res) => {
      props.batchesList();
        console.log(res.data)
        setShow(false); 
        if(res.data){
          setPop(true)
        setTimeout(() => {
          setPop(false)
        }, 1500); 
        }
    }).catch((err)=>{
      console.log(err)
    })
    setSelectedOption("")
    setBatchName("")
    setDescription("")
    setSelectedTrainer("")
    setTrainerDetails("")
    setStartDate(moment().tz('Asia/Kolkata').toDate())
    setEndDate(moment.tz(null))
    setSelectedStatusOption("")
  }  

  



  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  //   if (date) {
  //     const fiveMonthsLater = new Date(date.getFullYear(), date.getMonth() + 5, date.getDate());
  //     setEndDate(fiveMonthsLater);
  //   } else {
  //     setEndDate(null);
  //   }
  // };


  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
        const fiveMonthsLater = moment(date).add(5, 'months').tz('Asia/Kolkata').toDate();
        setEndDate(fiveMonthsLater);
    } else {
      setEndDate(moment.tz(null))
    }
};


  useEffect(() => {
    const fiveMonthsLater = moment(startDate).add(5, 'months').tz('Asia/Kolkata').toDate();
    setEndDate(fiveMonthsLater);
  }, [startDate]);


  useEffect(() => {

    setSelectedTrainer(null)
    
  }, [selectedOption]);

 
    return (
        <>

{pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
      <div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
        <div><img src={require("../images/Tick.png")} alt="success" className="img-fluid"/></div>
        <p className='text-center w213 '>Sucessfully new batch has been created</p>
      </div>
    </div>}

              <Button className='batchbtn col-12 mb-4' onClick={handleShow}>
        + Add New Batch
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-100"
        dialogClassName={`modal-left-small h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New Batch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id='create-batch-form' className='h-100' >
          <div className='scrollForm'>
                  <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    {/* <select onChange={programChange}  name="role" id="role">
              <option  value="default" className='pe-2'>Select Role</option>
            {program && program.map((data) => {
               return (
     <option value={data.programName} className='pe-2'>{data.programName}</option>
 )})
               }
 </select> */}
<div className='d-flex flex-row'><Form.Label>Select Program</Form.Label><h1 className='red'>*</h1></div>
<Select
                
                        value={selectedOption}
                        onChange={programChange}
                        placeholder="Select Program"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.program_name}`
                        }
                        options={program}
                      />
          
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>
                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Batch Name</Form.Label>
                      <Form.Control
                        // className={formErros.firstName ? "borderred" : ""}
                        placeholder="Batch Name"
                        value={batchName}
                        autoComplete="off"
                        name='batchName'
                        disabled
                        
                        // onChange={handleChange}
                      />
                      <Form.Text className="text-muted">
        Note: Batch name is system generated based on program.
        </Form.Text>
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <Form.Label>Batch Description</Form.Label>
                 <Form.Control  as="textarea" value={description} onChange={e => setDescription(e.target.value)} maxLength={40} rows={3} placeholder="Batch Description (max 40 characters)" />
                 </Col>
                 </Row>
                
                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <div className='d-flex flex-row'><Form.Label>Select Trainer</Form.Label><h1 className='red'>*</h1></div>
                  <Select

                        value={selectedTrainer}
                        onChange={trainerChange}
                        placeholder="Select Trainer"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.trainerName}`
                        }
                        options={trainerDetails}
                      />
          
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>
                
                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start position-relative">
                    <div className='d-flex flex-row'><Form.Label>Select Start Date</Form.Label><h1 className='red'>*</h1></div>
                    {/* <DatePicker className='w-100 rounded heightDate' selected={startDate} onChange={(date) => setStartDate(date)}
                    minDate={new Date()}
                    placeholderText="MM/DD/YYYY"
                    selectsStart
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" /> */}
                    <DatePicker className='w-100 rounded heightDate'
  selected={moment(startDate).tz('Asia/Kolkata').toDate()}
  onChange={handleStartDateChange}
  placeholderText="MM/DD/YYYY"
  showYearDropdown
  dateFormat="MM/dd/yyyy"
/>
                    {/* <img src={require("../images/dateselect.png")} alt="Image not found" className="dateselect"/> */}
                 </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start  position-relative">
                    <div className='d-flex flex-row'><Form.Label>Select End Date</Form.Label><h1 className='red'>*</h1></div>
                    <DatePicker className='w-100 rounded heightDate'
 selected={moment(endDate).tz('Asia/Kolkata').toDate()}
 onChange={(date) => setEndDate(moment(date).tz('Asia/Kolkata').toDate())}
 minDate={moment(startDate).add(1, 'day').tz('Asia/Kolkata').toDate()}
 placeholderText="MM/DD/YYYY"
 showYearDropdown
 dateFormat="MM/dd/yyyy"
/>
                    {/* <img src={require("../images/dateselect.png")}  alt="Image not found" className="dateselect"/> */}
                 </Col>
                 </Row>

                 <Row className="pb-4">
                    <Col className="col-12 d-flex flex-column align-items-start">
                    <div className='d-flex flex-row'><Form.Label>Select Status</Form.Label><h1 className='red'>*</h1></div>
<Select
                
                        value={selectedStatusOption}
                        onChange={statusChange}
                        placeholder="Select Status"
                        className="multiselectdropdown"
                        classNamePrefix="react-select"
                        styles={colourStyles}
                        getOptionLabel={option =>
                          `${option.indexDescription}`
                        }
                        options={batchStatus}
                      />
          
                      {/* <p className="red font12">{formErros.firstName}</p> */}
                    </Col>
                 </Row>
                 </div>
                 <button onClick={handleSubmit}  disabled={disable} className={ disable ? "profile-buttons-update w-100 mt-3 mb-5 grey" : "profile-buttons-update w-100 mt-3 mb-5 UpdatedMainBGColor"}>Create</button>
         </Form>
        
        </Modal.Body>
      </Modal>
            
            
        </>
    );
}

export default LeftToRightModal
