import React from "react";

function NoRecFound(props){

    return(
        <>
        <div className="d-flex flex-column align-items-center">

<img
                    src={require("../images/norecfound.png")}
                    alt="No image found"
                    className="errorimgsize mb-3"
                  />    
                  <h4 style={{color:"#3d3d3d"}}>{props.error}</h4>
        </div>
        </>
    )
}

export default NoRecFound