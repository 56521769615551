import React from 'react'
import { useState } from "react";
import httpuserprofile from "../js/http-lms_userprofile"
import Dropdown from 'react-bootstrap/Dropdown';
import NoRecFound from "../components/NORecFound";
import { useEffect } from 'react';
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import LeftToRightModal from "../components/LeftToRightModal";
import CreateUserModal from './CreateUserModal';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import CreateUsers from '../pages/manageUsers/CreateUsers';
import Select from 'react-select'
import UpdateUser from "../pages/UpdateUser";
import DatePicker from "react-datepicker";


const BatchDetailsTable = ({data, setData}) => {
  const [SName, setSName] = useState()
  const [studentId, setStudentId] = useState()
  const [status, setStatus] = useState()
  const [deletePop, setDeletePop] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState()


  const [dataPerPage, setDataPerPage] = useState(20)

  const [totalPages, setTotalPages] = useState()

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  const [show, setShow] = useState(false);

  const [form, setForm] = useState('2')

  const [tableData, setTableData] = useState()



  const handleShow = (data) => { 
    setShow(true);
    console.log(data)
    setTableData(data)
  }



  // const handleClose = () => {
  //   setShow(false);

  // }


  useEffect(() => {
    setTotalPages(Math.round(data.length/dataPerPage))
  }, [data, dataPerPage])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset, data, dataPerPage])

  useEffect(() => {
    setCurrentItems(data.slice(itemOffset, endOffset))
    console.log(data.slice(itemOffset, endOffset), 'll')
  }, [endOffset, data, dataPerPage])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % data.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };    

  
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  
    useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });


  useEffect(() => {
    console.log(data.length, 'aaaaaaaaaaaa');
    console.log(data, 'jjkljkldfskjl');
  }, [])

  function handleDelete() {
    httpuserprofile.put('userprofileLMS/updateUserStatusFromCurrentBatch?studentId='+studentId+'&status='+status)
    .then((response) => {
      console.log("delete user response : ", response)
      setDeletePop(false)
      data[deleteIndex].activeStatus=status
    }).catch((e) => {
      console.log(e.response)
      setDeletePop(false)
    })
  }
      
  function deletePopUp(e,i) {
    if(e.target.innerText===data[i].activeStatus) {
      return null
    }
    setSName(data[i].name)
    setStudentId(data[i].profileId)
    setDeleteIndex(i)
    setStatus(e.target.innerText)
    setDeletePop((pValue) => !pValue);
  }

  const handleDataPerPageChange = (event) => {
    console.log(event.target.value)
    setDataPerPage(event.target.value);
  };

  return (
    <>

<UpdateUser setShow={setShow} tableData={tableData} show={show} />

    {/* {deletePop && <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
      <div className=" d-flex flex-column P-Update-Success-4 rounded-2 position-relative ">
        <img onClick={() => {setDeletePop(false)}} src={require('../images/cancel.png')} alt="Image not found" className="cancelimg2 position-absolute" />
        <h5 style={{fontWeight:"400"}} className="mb-5 mt-4 pt-5 mx-3 text-center">Are you sure you want to make {SName} as {data[deleteIndex].activeStatus==='ACTIVE'? 'INACTIVE' : 'ACTIVE' }?</h5>
        <div className="d-flex justify-content-around">
          <button onClick={handleDelete} className="profile-buttons ms-2">Yes Proceed</button>
          <button onClick={() => {setDeletePop(false)}} className="profile-buttons me-2">Cancel</button>
        </div>
      </div>
    </div>} */}











    { sortedData.length > 0 ? 
    <div className="scrollme">
      <div className="d-flex justify-content-between bgwhite px-4 bottomborder"> 

      <div className="d-flex">
       <select className="my-4 px-1 rounded borderUpated me-5" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      <DatePicker className='w-100 rounded heightDate mt-4' selectsEnd
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2 mt-4">-</h3>
      <DatePicker className='w-100 rounded heightDate mt-4' selectsEnd
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
                    
                    </div>
      <CreateUsers form={form} setForm={setForm} /></div>
      <table className="table bgwhite">
        <thead>
          <tr>
            <th scope="col">Student Name <img onClick={() => handleSort('name')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            <th scope="col">Mail Id <img onClick={() => handleSort('mailId')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            <th scope="col">Batch Name <img onClick={() => handleSort('batchName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            <th scope="col">Created Date <img onClick={() => handleSort('joinedDate')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            <th scope="col">Program Name <img onClick={() => handleSort('courseName')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            {/* <th scope="col">Program Status</th>
            <th scope="col">Placed</th> */}
            <th scope="col">Status <img onClick={() => handleSort('activeStatus')} src={require("../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((data, index) => {
            return (
              <tr key={index}>
                <td scope="row">{data.name}</td>
                <td>{data.mailId}</td>
                <td>{data.batchName}</td>
                <td>{moment(data.joinedDate).format('MM/DD/YYYY')}</td>
                <td>{data.courseName[0]}</td>
                {/* <td>{data.courseCompletion.length>0 ? data.courseCompletion[0] + '%' : '-'}</td>
                <td>{data.studentStatus}</td> */}
                <td className="d-flex justify-content-center"><h6 className={data.activeStatus=="ACTIVE" ? "status-active" : "status-inactive"}>{data.activeStatus}</h6></td>
                <td>
                <img src={require("../images/edit2.png")} onClick={()=>handleShow(data)} alt="Image not found" className="more"/>
                  {/* <Dropdown>
                    <Dropdown.Toggle className="dropdown-more" variant="success" id="dropdown-basic">
                      <img src={require("../images/more.png")} alt="Image not found" className="more"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {data.activeStatus == 'ACTIVE' && 
                        <Dropdown.Item eventKey="INACTIVE" value="INACTIVE" onClick={(e) => deletePopUp(e, index)} >INACTIVE</Dropdown.Item>
                      }
                      {data.activeStatus == 'INACTIVE' && 
                        <Dropdown.Item eventKey="ACTIVE"  value="ACTIVE" onClick={(e) => deletePopUp(e, index)} >ACTIVE</Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  </Dropdown> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
    </div> : <div className='col-12 text-center'>
    <NoRecFound error="No student records found." />
    </div>
}
    </>
  )
}

export default BatchDetailsTable
