import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../styles/AdminProfile.css";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import httpprograms from "../js/http-lms_programs"
import NoRecFound from "../components/NORecFound";
import CreateProgram from '../components/CreateProgramModal'
import showPwdImg from '../images/showpwd.svg';

const Programs = () => {

  const [data, setData] = useState([])
  const[refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(0)

  const[error, setError] = useState()


  const navigate = useNavigate();

function handleClick(pId) {
        sessionStorage.setItem("programId", pId)
        navigate("/videos");
      }
  function handleEditProgramClick(pId, pName){
    // sessionStorage.setItem("programId", pId)
    navigate("/programdetails/"+pName+'/'+pId);
    
  }

      useEffect(() => {
        programCards()
      }, [])
      useEffect(() => {
        if(refresh){
          programCards()
        }
      }, [refresh])


      const programCards =async ()=>{
      
          // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
          httpprograms.get("/programsLMS/getModulesTopicCount")
          .then((response) => {
            console.log("get modules count", response)
          setData(response.data)
          setLoading(1)
          setRefresh(false)
          }).catch((err)=>{
            setLoading(1)
            setError("Bad request")
          })

      }
      

  return (
    <>
      <Sidebar />

      <div className="dashboard-container mx-4">
        {/* <Navb /> */}
        <div className="col-11 mt160 d-flex justify-content-between">
        <h1 className="ps-md-5 mt-4 mb-4">Programs</h1>
        <div>
        <CreateProgram refresh={refresh} setRefresh={setRefresh}></CreateProgram></div>
        </div>
        <div className="px-md-5 d-md-flex flex-wrap">
        {loading===0 ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
            error ? <div className='col-12 text-center'> <NoRecFound error="No programs found." /> </div>:

        data.map((res) => {
          return(
            <div key={res.program_id} className="bgwhite shadow mb-3 program-cards p-3 me-3">
              <div className="col-12">
                <div className="col-12 d-flex justify-content-between">
                  <div className="col-9 d-flex flex-column justify-content-center align-items-start">
                    <h3 className="font18 black">{res.program_name}</h3>
                    <div className="d-flex">
                      <h6 className="pe-3 black80">Modules {res.modules}</h6>
                      <h6 className="black80">Assignment {res.assignmentCount}</h6>
                    </div>
                  </div>  
                  <div className="col-3 d-flex my-auto justify-content-end">
                    <div className="radiushalf graybackgroundbtn text-center p-1 pointer me-1" title="View Program Details" onClick={() => {handleClick(res.program_id)}}>
                      <img src={showPwdImg} width="25px" height="25px" alt="view" className="my-auto mx-auto"/>
                    </div>
                    <div className="radiushalf graybackgroundbtn text-center p-1 pointer" onClick={() => {handleEditProgramClick(res.program_id, res.program_name)}}>
                      <img src={require("../images/edit2.png")} width="15px" height="15px" title="Edit Program Details" className="my-auto mx-auto" alt="edit" />
                    </div>
                  </div>            
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </>
  );
};

export default Programs;
