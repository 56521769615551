import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgetPassword from "./pages/ForgetPassword";
import "./styles/App.css";
import SignIn from "./pages/SignIn";
import VerifyOtp from "./pages/VerifyOtp";
import Updatepass from "./pages/Updatepass";
import DashboardAdmin from "./pages/Sidebar";
import Sidebar from "./pages/Sidebar";
import Dashboard from "./pages/Dashboard";
import { Navbar } from "react-bootstrap";
import Navb from "./pages/Navb";
import CreateUser from "./pages/CreateUser";
import Programs from "./pages/Programs";
import EditPrograms from "./pages/EditPrograms";
import BatchDetails from "./pages/BatchDetails";
import BatchList from "./pages/BatchList";
import Assignments from "./pages/Assignments";
import ProgramDetails from "./pages/ProgramDetails";
import SignInBS from "./pages/SignInBS"
import Videos from "./pages/Videos";
import { useEffect, useState } from 'react';
import UpdateSelf from "./pages/UpdateSelf"
import UpdateUser from "./pages/UpdateUser";
import ManageProgandAssignments from "./pages/ManageProg&Assignments";

function App() {

  
  const[connection, SetConnection] = useState("")

  useEffect(() => {
    setInterval(() => {
      if (window.navigator.onLine) {
        SetConnection("Online")
      } else {
        SetConnection("Offline")
      }
    }, 1000)
  })

  return (
    <>
    {connection === "Online" && (
      <BrowserRouter>
      <Navb />
        <Routes>
          <Route path="/" element={<SignInBS />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/verifyotp" element={<VerifyOtp />} />
          <Route path="/updatepass" element={<Updatepass />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/batchdetails" element={<BatchDetails />} />
          <Route path="/batchlist" element={<BatchList />} />
          <Route path="/assignments" element={<Assignments />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/programdetails/:programName/:id" element={<EditPrograms/>}/>
          {/* <Route path="/course-details/:id" element={<Videos />} /> */}
          <Route path="/videos" element={<Videos />} />
          <Route path="/programdetails" element={<ProgramDetails />} />
          <Route path="/manageusers" element={<CreateUser />} />
          <Route path="/updateself" element={<UpdateSelf />} />
          <Route path="/updateuser" element={<UpdateUser />} />
          <Route path="/managprogandassignments" element={<ManageProgandAssignments />} />
        </Routes>
      </BrowserRouter>)}

      {connection === "Offline" && (
        <div className="d-flex justify-content-center align-items-center wrapperOffline">
        <div className="msgBoxOffline py-4 px-5 d-flex">
          <h3>You are offline.</h3>
          <img
                    src={require("../src/images/offline2.png")}
                    alt=""
                    className="ms-3 offline-icon"
                  />
        </div>
        </div>)}

    </>
  );
}

export default App;
