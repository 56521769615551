import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../styles/AdminProfile.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Sidebar from "./Sidebar";
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import BatchDetailsTable from "../components/BatchDetailsTable";
import CreateUsers from './manageUsers/CreateUsers'
import ListofUsers from './manageUsers/ListOfUsers'
import StudentsList from './manageUsers/StudentsList'
import TrainersList from "./manageUsers/TrainersList";
import httpprograms from "../js/http-lms_userprofile"
import Spinner from 'react-bootstrap/Spinner';

const CreateUser = () => {

  // UseState to set resulting image
  const [result, setResult] = useState(null);
  const [selectedTab, setSelectedTab] = useState()

  const[totalStudent, SetTotalStudent] = useState();

  const[trainersCount, setTrainersCount] = useState();

  const [loading, setLoading] = useState(0)


  const[collegeSubscriptionCount, setCollegeSubscriptionCount] = useState();

//Function buttonClick is used to change the state o button from unclicked(grey) to clicked(orange)

  const [button, setButton] = useState();
  //set the form
  const [form, setForm] = useState("");
  useEffect(() => {
    console.log(selectedTab);
  }, [selectedTab])

  useEffect(() => {
    if(localStorage.getItem('path')) {
      if(localStorage.getItem('path')==='/dashboard/student') {
        setCollapse(3)
        setButton(3);
        setSelectedTab('Students List')
        localStorage.removeItem('path')
      }
      else if (localStorage.getItem('path')==='/dashboard/trainer') {
        setCollapse(4)
        setButton(4);
        setSelectedTab('Trainers List')
        localStorage.removeItem('path')
      }
      else {
        setButton(2)
        setCollapse(2)
      }
    }
    else {
      setButton(2)
      setCollapse(2)
    }
  }, [])

  function buttonClick(e) {
    if (e.target.className === "btn1 profile-buttons mt-2 px-3") {
      setButton(1);
      setSelectedTab('Create User')
    } else if (e.target.className === "btnx profile-buttons mx-md-3 mt-2 px-3") {
      setButton(2);
      setSelectedTab('Admin Users')
    } else if (e.target.className === "btn3 profile-buttons mt-2 me-3 px-3") {
      setButton(3);
      setSelectedTab('Students List')
    }
    else if (e.target.className === "btn3 profile-buttons mt-2 px-3") {
      setButton(4);
      setSelectedTab('Trainers List')
    }
    handleClick(e);
  }

  const [collapse, setCollapse] = useState(1);

  function handleClick(e) {
   if (e.target.className === "btnx profile-buttons mx-md-3 mt-2 px-3") {
      setCollapse(2);
    } else if (e.target.className === "btn3 profile-buttons mt-2 me-3 px-3") {
      setCollapse(3);
    } else if (e.target.className === "btn3 profile-buttons mt-2 px-3") {
      setCollapse(4);
    }
  }




  useEffect(()=>{
    allCards()
  },[])


  function allCards(){
    httpprograms.get('/userprofileLMS/getDashboardCount')
    .then((response) => {
      if(response.data=="No records found"){
        setLoading(1)
        console.log("API Values DCards : : ", response.data)
        // setError(response.data)
      }
      else{
      setLoading(1)
      console.log("API Values DCards: : ", response.data)
      SetTotalStudent(response.data.studentsCount)
      setTrainersCount(response.data.trainersCount)
      setCollegeSubscriptionCount(response.data.collegeSubscriptionCount)
    }
    }).catch((e) => {
      console.log(e.response)
    }) 
  }

  return (
    <>
      <Sidebar />
      <div className="dashboard-container mx-4">
        {/* <Navb result={result}/> */}
        <div className="px-md-5 mt160">


        <div className="col-lg-12">
  <div className="row">


{loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 userCards shadow py-5 col-lg col-12 ms-lg-2"> <Spinner animation="border" variant="secondary" /></div> :
  <div className="d-flex flex-column ps-4 me-md-4 userCards shadow py-4 col-lg col-12 ms-lg-2">
        <h6>Total Subscriptions</h6>
        <h2 className="pt-2">{collegeSubscriptionCount}</h2>
      </div>
}


{loading === 0 ?  <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 userCards shadow my-lg-0 my-3 py-5 col-lg col-12"> <Spinner animation="border" variant="secondary" /></div> :
      <div className="d-flex flex-column ps-4 me-md-4 userCards shadow py-4 col-lg col-12 my-lg-0 my-3">
        <h6>Total Admins</h6>
        <h2 className="pt-2">0</h2>
      </div>
}

      {loading === 0 ? <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 userCards shadow mb-lg-0 mb-3 py-5 col-lg col-12"> <Spinner animation="border" variant="secondary" /></div> :

      <div className="d-flex flex-column ps-4 me-md-4 userCards shadow py-4 col-lg col-12 mb-lg-0 mb-3">
        <h6>Total Trainers</h6>
        <h2 className="pt-2">{trainersCount}</h2>
      </div>
      }


      {loading === 0 ?  <div className="d-flex justify-content-center align-items-center ps-4 me-md-4 userCards shadow py-5 col-lg col-12"> <Spinner animation="border" variant="secondary" /></div> :

      <div className="d-flex flex-column ps-4 me-md-4 userCards shadow py-4 col-lg col-12">
        <h6>Total Students</h6>
        <h2 className="pt-2">{totalStudent}</h2>
      </div>
}





    </div>
    </div>


          {/* <h1 className="mb-2 mt-4 font20 pointer">Manage Users  {' > '}  {selectedTab}</h1> */}
          <div className="d-flex justify-content-md-between justify-content-center mt-5 mb-4 w-100 tabDesign">
            <div className="d-flex flex-md-row flex-column w-100 tabDesigm">
              {/* <button
                onClick={buttonClick}
                className={
                  button === 1
                    ? "active-button profile-buttons mt-2 px-3"
                    : "btn1 profile-buttons mt-2 px-3"
                }
              >
                Create User
              </button> */}
              <button
                onClick={buttonClick}
                className={
                  button === 2
                    ? "active-button profile-buttons mx-md-3 mt-2 px-3"
                    : "btnx profile-buttons mx-md-3 mt-2 px-3"
                }
              >
                Other Users
              </button>
              <button
                onClick={buttonClick}
                className={
                  button === 3
                    ? "active-button profile-buttons mt-2 me-2 px-3"
                    : "btn3 profile-buttons mt-2 me-3 px-3"
                }
              >
                Students
              </button>
              <button
                onClick={buttonClick}
                className={
                  button === 4
                    ? "active-button profile-buttons mt-2 px-3"
                    : "btn3 profile-buttons mt-2 px-3"
                }
              >
                Trainers
              </button>
            </div>




            {/* <button
                onClick={buttonClick}
                className={
                  button === 3
                    ? "active-button profile-buttons mt-2 me-2 px-3"
                    : "btn3 profile-buttons mt-2 me-2 px-3"
                }
              >
                Students
              </button> */}



            {/* {(button === 2 || button === 3) && (
            <div className="d-flex">
              <h6 className="me-2 mt-3">Filters</h6>
              <img
                        src={require("../images/filter.png")}
                        alt="Image not found"
                        className="filter-icon mt-3"
                      />
            </div>)} */}
          </div>
        </div>
        
        {/* create user forms section */}
        {collapse === 1 && (
          <div className="px-2 px-md-1">
          <CreateUsers />
          </div>
        )}

        {/* List of users */}
        <div className="px-2 px-md-4">
          <div className=" scrollme mt-3">
            {/* list of users */}
            {collapse === 2 &&  <div className="px-md-4"><ListofUsers/></div>}
            {/* list of students */}
            {collapse === 3 &&  <div className="px-md-4"><StudentsList /></div>}
            {/* list of trainers */}
            {collapse === 4 &&  <div className="px-md-4"><TrainersList /></div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
