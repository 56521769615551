import React from "react";
import { useState, useEffect } from "react";
import {DefaultPlayer as Video} from "react-html5video"
import "react-html5video/dist/styles.css"
import thum from "../../images/vid2.png"
import eventBus from "../../js/Event-bus";

const TopicVideo = ({selectedTopicData, setSelectedTopicData}) => {
    const [videoURL, setVideoURL] = useState('')
    const [isSmallDev, setIsSmallDev] = useState(false)
    useEffect(() => {
        setVideoURL(selectedTopicData.s3PresignedUrlVideo)
        if(window.innerWidth < 900) {
            setIsSmallDev(true)
        }else{
            setIsSmallDev(false)
        }
    },[selectedTopicData])
    return (
        <div className="col-12 p-0 background-black radius10">
            <video src={videoURL} controls height={isSmallDev ? '' : '440px'} controlsList="nodownload" className="videotag radius10"></video>
        </div>
    )
}
export default TopicVideo;