import { useState } from "react";
import React, { useEffect, useRef  } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpassignments from '../../js/http-lms_assignments'
import NoRecFound from "../../components/NORecFound";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";



const AssignedAssignments = () => {

  const table = useRef(null);
    //data
    const [loading, setLoading] = useState(true)
    const [listofAssignedAssignments, setListofAssignedAssignments] = useState([])



    const [dataPerPage, setDataPerPage] = useState(20)

  const [totalPages, setTotalPages] = useState()

  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setTotalPages(Math.round(listofAssignedAssignments.length/dataPerPage))
  }, [listofAssignedAssignments])

  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage)
  }, [itemOffset, listofAssignedAssignments])

  useEffect(() => {
    setCurrentItems(listofAssignedAssignments.slice(itemOffset, endOffset))
    console.log(listofAssignedAssignments.slice(itemOffset, endOffset), 'll')
  }, [endOffset, listofAssignedAssignments])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % listofAssignedAssignments.length;
    setItemOffset(newOffset);
    if(event.selected===totalPages-1) {
      console.log('this is the final page');
    }
  };    

    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  
    useEffect(() => {
      setSortKey('name');
      setSortDirection('asc');
  }, [])

  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sortKey) {
      if (sortDirection === 'asc') {
        return a[sortKey] < b[sortKey] ? -1 : 1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    } else {
      return 0;
    }
  });


    const handleDataPerPageChange = (event) => {
      console.log(event.target.value);
      if (event.target.value === "all") {
        setDataPerPage(listofAssignedAssignments.length);
      } else {
        setDataPerPage(parseInt(event.target.value));
      }
    };


    //API call when page loader
    useEffect(() => {
      //fetching college list
      setLoading(true)
      httpassignments
        .get(`/api/v1/topicAssignmentLMS/lmsAssignedAssignmentsList?RoleId=${sessionStorage.getItem('roleId')}&UserId=${sessionStorage.getItem('roleId')}`)
        .then((res) => {
          console.log("list of Assigned Assignments", res)
          setListofAssignedAssignments(res.data)
          setLoading(false)
        }).catch((e) => {
          console.log(e)
          setLoading(false)
        })
    },[])


  //   const columns = [{ dataField: 'studentName', text: 'Student Name', sort: true },
  //   { dataField: 'assignmentMaster[0].assignmentName', text: "Assignment Name", sort: true },
  //   { dataField: 'assignmentMaster[0].topicName', text: 'Topic Name', sort: true },
  //   { dataField: 'batchName', text: 'Batch Name', sort: true },
  //   { dataField: 'trainerName', text: 'Trainer Name', sort: true },
  //   { dataField: 'assignmentStartDate', text: 'Assignment Start Date', sort: true, 
  // formatter: (cell, row) => {
  //   return (
  //     <td>
  //       {moment(row.assignmentStartDate).format('MM/DD/YYYY')}
  //     </td>
  //   )
  // } },
  //   { dataField: 'assignmentEndDate', text: 'Assignment End Date', sort: true },
  //   { dataField: 'assignmentMaster[0].s3PresignedAssignmentLink', text: 'Assigned Document', sort: true,  
  //   formatter: (cell, row) => {
  //     return (
  //       <td className="text-center">
  //         {Array.isArray(row.assignmentMaster)  ? <a href={row.assignmentMaster[0]?.s3PresignedAssignmentLink} target="_blank" className={row.assignmentMaster[0]?.s3PresignedAssignmentLink ? 'blue text-underline pointer underline mb-3 d-block' : 'd-block black60 mb-3'}>{row.assignmentMaster[0]?.s3PresignedAssignmentLink ? row.assignmentMaster[0]?.assignmentName + '.pdf' : ':'}</a> : '-'}
  //       </td>
  //     )}}];
    

    return (
    <>
    {loading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (sortedData.length > 0) ? 
        <div className="scrollme">
          <div className="d-flex bgwhite px-4 bottomborder">  <select className="my-4 px-1 rounded borderUpated me-5" value={dataPerPage} onChange={handleDataPerPageChange}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value="all">All</option>
      </select>
      <div className="d-flex my-4">
      <DatePicker className='w-100 rounded heightDate' selectsEnd
                    showYearDropdown
                    placeholderText="From Date"
                    dateFormat="MM/dd/yyyy" />
                    <h3 className="px-2">-</h3>
      <DatePicker className='w-100 rounded heightDate' selectsEnd
      placeholderText="To Date"
                    showYearDropdown
                    dateFormat="MM/dd/yyyy" />
      </div>
      </div>
            <table className="table bgwhite">
                <thead>
                    <tr>
                    <th scope="col">Student Name <img onClick={() => handleSort('studentName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assignment Name </th>
                        <th scope="col">Topic Name </th>
                        <th scope="col">Batch Name <img onClick={() => handleSort('batchName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Trainer Name <img onClick={() => handleSort('trainerName')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assignment Start Date <img onClick={() => handleSort('assignmentStartDate')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assignment End Date <img onClick={() => handleSort('assignmentEndDate')} src={require("../../images/updown.png")} alt="Image not found" className="ms-1 updown"/></th>
                        <th scope="col">Assigned Document</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((data, index) => {
                        return (
                        <tr key={index+'tr'}>
                          <td>{data.studentName}</td>
                            <td scope="row">{Array.isArray(data.assignmentMaster) ? (data.assignmentMaster[0]?.assignmentName ? data.assignmentMaster[0]?.assignmentName : '--') : '--'}</td>
                            <td>{Array.isArray(data.assignmentMaster) ? (data.assignmentMaster[0]?.topicName ? data.assignmentMaster[0]?.topicName : '--') : '--'}</td>
                            <td>{data.batchName}</td>
                            <td>{data.trainerName}</td>
                            <td>{moment(data.assignmentStartDate).format('MM/DD/YYYY')}</td>
                            <td>{moment(data.assignmentEndDate).format('MM/DD/YYYY')}</td>
                            <td>
                                {Array.isArray(data.assignmentMaster)  ? <a href={data.assignmentMaster[0]?.s3PresignedAssignmentLink} target="_blank" className={data.assignmentMaster[0]?.s3PresignedAssignmentLink ? 'blue text-underline pointer underline mb-3 d-block' : 'd-block black60 mb-3'}>{data.assignmentMaster[0]?.s3PresignedAssignmentLink ? data.assignmentMaster[0]?.assignmentName + '.pdf' : ':'}</a> : '-'}
                            </td>
                        </tr>
                        );
                    })}                      
                </tbody>
            </table>
            <div className="d-flex justify-content-end">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      </div>
        </div>
        : <div className='col-12 text-center'>
          <NoRecFound error="No assignments found" />
        </div>
      }</div>
    }
    </>
  )
}

export default AssignedAssignments;
